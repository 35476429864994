import { RemoveRedEyeOutlined } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import ListItem from "feature/panel/Itinerary/components/Navigation/ListItem";
import { navigations } from "feature/panel/Itinerary/components/Navigation/config";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { colors } from "config/theme/colors";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ResponsiveShowFrom } from "../../responsive";
import ItineraryNavigation from "../Navigation";
import BasicPopover from "../TopSecondMenu/components/common/basicPopover";
import LanguageSelector from "../TopSecondMenu/languageSelector";
import { Overlay, Wrap } from "./style";

const MobileTopSecondMenu = ({
  type = "trip",
  actions,
  saveButton,
  errors,
  onPreview,
  isActive,
  isDirty,
  isDefaultStayLanguage,
  hasAdditionalMenu,
  action,
  shouldShowActionButtons,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const currentPath = pathname.split("/").pop();

  const navigationItem = navigations[type].find(item => item.path === currentPath);

  const togglePopover = e => setAnchorEl(anchorEl ? null : e.currentTarget);
  const toggleMenu = () => {
    if (!isMenuOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
    document.body.style.overflow = "auto";
  }, [pathname]);

  return (
    <>
      <Wrap errors={errors}>
        {Object.keys(errors).length > 0 && (
          <div
            style={{
              backgroundColor: "rgb(253 218 218)",
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              borderRadius: 100,
              paddingRight: 10,
              fontWeight: 700,
              color: colors.error,
              lineHeight: "12px",
              position: "absolute",
              top: -14,
              border: `1px solid ${colors.error}`,
            }}
          >
            <ErrorOutlineIcon style={{ marginRight: 3, color: colors.error }} />
            Error
          </div>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          <ListItem as="div" {...navigationItem} onClick={toggleMenu} style={{ paddingRight: 0 }} showChevron />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          {/* <MobileButton onClick={togglePopover}>
            <MoreHorizIcon sx={{ color: "#828282" }} />
          </MobileButton> */}
          {shouldShowActionButtons && <OutlinedButton startIcon={<MoreHorizIcon />} iconOnlyMobile onClick={togglePopover} type="grey" />}
          {shouldShowActionButtons &&
            <OutlinedButton
              startIcon={<RemoveRedEyeOutlined />}
              iconOnlyMobile
              onClick={onPreview}
              disabled={isDirty}
              type="grey"
            />
          }
          {saveButton}
        </div>
        <BasicPopover
          anchorEl={anchorEl}
          onClose={togglePopover}
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 15,
            // height: 120,
            borderRadius: 30,
          }}
        >
            <span onClick={togglePopover}>
              {actions}
            </span>
        </BasicPopover>
      </Wrap>
      {hasAdditionalMenu && (
        <ResponsiveShowFrom size="md">
          <LanguageSelector {...rest} action={action} />
        </ResponsiveShowFrom>
      )}

      {isMenuOpen && (
        <ItineraryNavigation
          errors={errors}
          type={type}
          isActive={isActive}
          isDefaultStayLanguage={isDefaultStayLanguage}
          action={action}
        />
      )}
      {isMenuOpen && <Overlay />}
    </>
  );
};

export default MobileTopSecondMenu;
