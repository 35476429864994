import moment from "moment";

import config from "config/app";
import { formFilePayloadObject } from "utils/library";
import {
  documentPayloadConvert,
  formMessagingEmailsListPayload,
  formNavigationsListToPayload,
  formShowMessageValue,
} from "utils/dataConverters";
import { StoryboardEntryRequestDto } from "./StoryboardEntryRequestDto";

export const TripRequestDto = (payload, copyToOtherOperator = false) => {
  const {
    vamoos_id,
    field1,
    field2,
    field3,
    field4,
    type,
    departure_date,
    return_date,
    start_time,
    locations,
    logo,
    background,
    travelPeople,
    listOfFlights,
    notifications,
    pois,
    timezone,
    storyboard,
    client_reference,
    destinationDocuments,
    destinationDocumentsTitle,
    destinationDocumentsIcon,
    travelDocuments,
    travelDocumentsTitle,
    travelDocumentsIcon,
    brandingProfileId,
    show_journal,
    show_poi_list,
    is_active,
    meta,
    inspiration_vamoos_id,
    copiedFrom,
    language,
    version,
  } = payload;
  const { messaging_emails_list } = meta;

  const payloadToReturn = {
    vamoos_id: vamoos_id || undefined,
    field1,
    field2,
    field3,
    field4,
    type,
    language,
    departure_date: moment(departure_date).format(config.apiDateFormat),
    return_date: moment(return_date).format(config.apiDateFormat),
    timezone,
    logo: formFilePayloadObject(logo, copyToOtherOperator),
    background: formFilePayloadObject(background, copyToOtherOperator),
    start_time: moment(start_time).format(config.timeFormat),
    travellers: travelPeople.map(({ id, nested, email, ...rest }) => {
      const updatedEmail = email ? email.replace(/\n/g, '') : null;

      return {
        ...rest,
        email: updatedEmail,
      };
    }),
    flight_ids: listOfFlights.map(flight => {
        return {
          "id": flight.id,
          "restricted_to_traveller_internal_ids": flight.restricted_to_traveller_internal_ids ?? null
        };
      }),
    pois: (pois ?? []).map(poi => ({id: poi.id, is_on: poi.is_on})), // Fix for huge payloads in the Portal for a lot of GPX tracks.
    source: "portal",
    inspiration_vamoos_id: inspiration_vamoos_id || undefined,
    notifications: formNavigationsListToPayload(notifications).map(item => {
      if (copyToOtherOperator) {
        if (item.type === "inspiration") {
          delete item.inspiration_vamoos_id;
        }
        delete item.template_id;
      }

      return item;
    }),
    locations: locations.map((location, index) => {
      const { coordinates, id, file, icon, nested, country_iso, ...rest } = location;
      const newLocation = { ...rest, position: index };

      if (file) {
        const formattedFile = formFilePayloadObject(file, copyToOtherOperator);
        newLocation.file = formattedFile;
      }
      if (nested?.vamoos_id) {
        newLocation.vamoos_id = nested.vamoos_id;
      }

      if (newLocation.hasOwnProperty("isNewNestedLocation")) {
        delete newLocation.isNewNestedLocation;
      }
      if (newLocation.hasOwnProperty("isNewLocation")) {
        delete newLocation.isNewLocation;
      }

      return newLocation;
    }),
    client_reference,
    branding_profile_id: brandingProfileId || null,
    documents: {
      travel: travelDocuments.map(document => documentPayloadConvert(document, copyToOtherOperator)),
      destination: destinationDocuments.map(document => documentPayloadConvert(document, copyToOtherOperator)),
    },
    details: storyboard.map(entry => StoryboardEntryRequestDto(entry, meta.show_days, copyToOtherOperator)),
    is_active,
    meta: {
      ...meta,
      ...formMessagingEmailsListPayload(messaging_emails_list),
      show_messaging: formShowMessageValue(messaging_emails_list),
      show_journal: show_journal ? "1" : "0",
      show_poi_list: show_poi_list ? "1" : "0",
      show_days: meta.show_days,
      copied_from: copiedFrom,
    },
    version,
  };

  if (travelDocumentsTitle) payloadToReturn.meta.travel_documents_label = travelDocumentsTitle;
  if (travelDocumentsIcon) {
    payloadToReturn.meta.icon_travel_documents = travelDocumentsIcon;
  } else {
    delete payloadToReturn.meta.icon_travel_documents;
  }

  if (destinationDocumentsTitle) payloadToReturn.meta.destination_documents_label = destinationDocumentsTitle;
  if (destinationDocumentsIcon) {
    payloadToReturn.meta.icon_destination_documents = destinationDocumentsIcon;
  } else {
    delete payloadToReturn.meta.icon_destination_documents;
  }

  return payloadToReturn;
};
