import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import BaseModal from "components/_new/ModalBase";
import Tabs from "components/_new/Tabs";
import { HttpClient } from "services/application/httpClient/httpClient";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { setNotification } from "store/app/actions";
import Checkbox from "components/_new/Checkbox";

const UpdateModal = ({
  passcode,
  operatorCode,
  onClose,
  language,
  onSubmitCustom,
  customTitle,
  isLoading,
  totalMatches,
  selectedItems,
  setChecked,
  checked,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, reset, watch } = useForm();
  const [tab, setTab] = useState("template");

  const { data: operator } = useQuery({ queryKey: ["operator"] });

  const { data: { data: { items } } = { data: { items: [] } } } = useQuery({
    queryKey: ["forcedNotifications"],
    queryFn: () => HttpClient.get("/notification/list/forced?&order=desc"),
  });

  const templateNotificationWatch = watch("template");
  const templateNotification = templateNotificationWatch && items?.find(item => item.id === templateNotificationWatch);

  const { mutate } = useMutation(
    body => {
      return HttpClient.post(`/itinerary/${operatorCode}/${passcode}/force-update`, {
        notification_text:
          body.bespoke ||
          templateNotification.localisation[language]?.content ||
          templateNotification.localisation[operator.default_language]?.content ||
          templateNotification.content,
      });
    },
    {
      onSuccess: () => {
        dispatch(setNotification({ message: "Updated successfully", type: "success" }));
        onClose();
      },
    },
  );

  const onSubmit = values => {
    if (onSubmitCustom) onSubmitCustom(values.bespoke || templateNotification.content || values);
    else mutate(values);
  };

  useEffect(() => {
    reset();
  }, [tab]);

  return (
    <BaseModal
      title={customTitle ?? "Update"}
      confirmTitle="Send"
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onClose}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflow: "visible" }}
      isLoading={isLoading}
      mobileFullScreen
      leftAction={
        selectedItems?.allItemsSelected &&
        selectedItems?.vamoosIds?.length !== totalMatches && (
          <Checkbox
            checked={checked}
            handleChange={() => {
              setChecked(!checked);
            }}
            label={`Apply to all ${totalMatches} entries matching current criteria`}
            labelStyle={{ fontSize: 12, lineHeight: "normal", marginLeft: -5 }}
          />
        )
      }
    >
      <div style={{ overflow: "hidden" }}>
        <Tabs
          tabs={[
            { label: "Notification template", id: "template" },
            { label: "Bespoke notification", id: "bespoke" },
          ]}
          tab={tab}
          setTab={tab => setTab(tab)}
        />
      </div>
      {tab === "template" ? (
        <SelectInput
          control={control}
          autoFocus
          name="template"
          label="Select template"
          optionLabelVar="content"
          optionValueVar="id"
          options={items}
          isClearable={false}
          rules={{ required: tab === "template" }}
        />
      ) : (
        <Input
          autoFocus
          control={control}
          name="bespoke"
          label="Notification text"
          multiline
          minRows={2}
          maxRows={5}
          rules={{ required: tab === "bespoke" }}
        />
      )}
    </BaseModal>
  );
};

export default UpdateModal;
