import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import uuidv4 from "uuid";
import Item from "./Item";
import { updateRestrictedIds } from "./helpers";

const People = ({ form: { control, watch, setValue, trigger, formState: { errors }, } = {}, canEdit, isWiped }) => {
  const [enableAutofocus, setEnableAutofocus] = useState(false);

  const fieldName = "people.travellers";
  const restrictableSectionsMapper = [
    { key: "storyboard.details", hasMetaProperty: true },
    { key: "flights.flights", hasMetaProperty: false },
    { key: "documents.documents.travel", hasMetaProperty: true },
    { key: "documents.documents.destination", hasMetaProperty: true },
  ];

  const { fields: people, append, replace, remove, update, move } = useFieldArray({
    control,
    name: fieldName,
    keyName: "people_id",
  });
  const watchPeople = watch("people.travellers");

  const onAdd = () => {
    setEnableAutofocus(true);
    append({ id: people.length + 1, name: "", email: null, travellersData: [{ email: null, internal_id: uuidv4() }] });
  };

  const onDelete = (indexToDelete, idToDelete) => {
    const found = people.find(person => person.id === idToDelete);
    if (found) {
      const person = { ...watchPeople[indexToDelete] };
      const internalIdsToDelete = [...found.travellersData.map(data => data.internal_id)];
      updateRestrictedIds(restrictableSectionsMapper, watch, setValue, person, internalIdsToDelete, false, people.length - 1 === 0);
    }
    remove(indexToDelete);
  };

  const onAddMultiple = (value, index) => {
    const splitPeople = value.split("\n");

    const updatedPeople = [...people];

    splitPeople.slice(1).forEach((item) => {
      const name = item.split("\t")[0];
      const email = item.split("\t")[1];

      if (item) {
        const travellersData = email
          ? email.split(",").map(email => ({
            email: email.trim(),
            internal_id: uuidv4(),
          }))
          : [{email: null, internal_id: uuidv4()}];
        updatedPeople.push({ id: updatedPeople.length + 1, name, email, travellersData });
      }
    });

    const name = splitPeople[0].split("\t")[0];
    const email = splitPeople[0].split("\t")[1];

    const travellersData = email
      ? email.split(",").map(email => ({
        email: email.trim(),
        internal_id: uuidv4(),
      }))
      : [{email: null, internal_id: uuidv4()}];
    updatedPeople[index] = { ...updatedPeople[index], name, email, travellersData };

    replace(updatedPeople);
  };

  const emailUpdate = (email, index) => {
    const emails = email.split(",").map(email => email.trim());
    const changedPerson = { ...watchPeople[index] };
    const prevEmailCount = changedPerson.travellersData.length;

    // Update emails for existing items
    emails.slice(0, prevEmailCount).forEach((email, index) => {
      changedPerson.travellersData[index].email = email;
    });

    // Handle additions
    if (emails.length > prevEmailCount) {
      const newEmails = emails.slice(changedPerson.travellersData.length);

      const internalIdsToAdd = [];
      newEmails.forEach(email => {
        const id = uuidv4();
        internalIdsToAdd.push(id);
        changedPerson.travellersData.push({ email, internal_id: id });
      });
      updateRestrictedIds(restrictableSectionsMapper, watch, setValue, changedPerson, internalIdsToAdd, true, false);
    }

    // Handle removals
    if (emails.length < prevEmailCount) {
      const unusedIds = changedPerson.travellersData.slice(emails.length).map(data => data.internal_id);
      changedPerson.travellersData = changedPerson.travellersData.slice(0, emails.length);
      updateRestrictedIds(restrictableSectionsMapper, watch, setValue, changedPerson, unusedIds, false, false);
    }
    update(index, changedPerson);
  };

  const handleDragEng = (_, { source, destination }) => move(source.index, destination.index);
  const onKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      onAdd();
    }
  };

  const isNameUnique = (name, index) => {
    return people.every((field, i) => i === index || field.name !== name);
  };

  return (
    <Wrapper slimTopPadding>
      <TitleRow text="People" isSticky>
        <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={onAdd} />
      </TitleRow>

      <div style={{ height: 20 }} />

      {people?.length ? (
        <DnD
          list={people}
          disabled={!canEdit || isWiped}
          element={
            <Item
              control={control}
              fieldName={fieldName}
              onAddMultiple={onAddMultiple}
              onKeyPress={onKeyPress}
              onDelete={onDelete}
              disabled={!canEdit || isWiped}
              isNameUnique={isNameUnique}
              emailUpdate={emailUpdate}
              enableAutofocus={enableAutofocus}
              setEnableAutofocus={setEnableAutofocus}
              trigger={trigger}
              peopleErrors={errors.people}
            />
          }
          onDragEnd={handleDragEng}
          contentStyle={{ padding: 0 }}
        />
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding people`}</NoResultsMessage>
      )}
    </Wrapper>
  );
};

export default People;
