import AttachButton from "components/_new/Buttons/AttachButton";
import { IMAGES_MIME_TYPES, VIDEO_FILE_TYPES } from "constants/defaults";
import React from "react";
import { Controller } from "react-hook-form";

const AttachButtonRow = ({ label, control, name, buttonText = "Add image" }) => {
  return (
    <div style={{ marginTop: 25, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      {label}:{" "}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <AttachButton
            allowedFileTypes={name === "video" ? VIDEO_FILE_TYPES : IMAGES_MIME_TYPES}
            onConfirm={onChange}
            onDelete={() => onChange(null)}
            buttonWrapStyle={{ width: "auto" }}
            file={value}
            text={buttonText}
            mobileFullScreen
            hideDescription
            hideUrl
          />
        )}
      />
    </div>
  );
};

export default AttachButtonRow;
