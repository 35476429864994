import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";
import styled, { css } from "styled-components";

const commonCss = css`
  border-radius: 8px;
  background-color: rgba(232, 232, 233, 0.2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

export const Wrap = styled.div`
  display: flex;
  margin-bottom: 15px;
  position: relative;
`;

export const Lines = styled.div`
  ${commonCss};
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    min-height: 65px;
  }
`;

export const Content = styled.div`
  ${commonCss}
  padding: 15px 25px;
  width: 100%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    min-height: 65px;
  }
`;
