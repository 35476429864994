import React, { useEffect } from "react";
import moment from "moment/moment";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import { useQuery } from "@tanstack/react-query";
import { DISPLAYABLE_STARTING_POINT_OPTIONS, calculateStartingPointValue } from "utils/notifications";
import { colors } from "config/theme/colors";
import { Description, InputsGrid, Wrap } from "./style";

const showDaysOn = ["daysBefore", "daysAfter", "daysBeforeEnd", "daysAfterEnd"];

const InspirationForm = ({ form: { control, watch, setValue, clearErrors }, inputNames, isWiped, canEdit, wrapStyle, menuPlacement }) => {
  const { data: { items } = { items: [] } } = useQuery({ queryKey: ["/inspiration"], refetchOnMount: true });

  const { inspirationVamoosId, inspiration: inspirationName, notificationsName, periodName, numberofDaysName, timeName, contentName } =
    inputNames || {};

  const inspirationId = watch(inspirationVamoosId || "localInspiration.inspiration_vamoos_id");
  const inspiration = watch(inspirationName || "inspiration.inspiration");
  const inspirationNotification = watch(notificationsName || "notifications")?.notifications?.find(item => item.type === "inspiration");
  const period = watch(periodName || "localInspiration.period");
  const deliveryAtDays = watch(numberofDaysName || "localInspiration.delivery_at_days");
  const showDays = showDaysOn.includes(period);
  const showTime = period && period !== "disabled";

  const isInspirationChoosen = inspirationId && inspirationId !== "none";

  const clearInspiration = inspId => {
    setValue("localInspiration", {
      content: "",
      period: "disabled",
      delivery_at_days: 0,
      ...(inspId !== "none" && { inspiration_vamoos_id: inspId }),
    });

    const notificationsWithoutInspiration = watch().notifications?.notifications?.filter(item => item.type !== "inspiration");

    setValue("inspiration.inspiration", {});
    setValue("notifications.notifications", notificationsWithoutInspiration);
    clearErrors("localInspiration");
  };

  const onChangeInspirationVamoosId = value => {
    if (value === "none") clearInspiration("none");
  };

  const onChangePeriod = value => {
    if (value === "disabled") {
      clearInspiration(inspirationId);
    } else if (showDaysOn.includes(value) && !deliveryAtDays) {
      setValue(numberofDaysName || "localInspiration.delivery_at_days", 1);
    }
  };

  useEffect(() => {
    if (showDays && !inspiration) setValue(numberofDaysName || "localInspiration.delivery_at_days", 1);
  }, [showDays]);

  useEffect(() => {
    if (inspiration && Object.keys(inspiration).length && inspirationNotification) {
      setValue("localInspiration", {
        ...inspirationNotification,
        delivery_at_days: Math.abs(inspirationNotification?.delivery_at_days),
        period: calculateStartingPointValue(inspirationNotification?.delivery_at_relative_to, inspirationNotification?.delivery_at_days),
      });

      setValue(inspirationVamoosId || "localInspiration.inspiration_vamoos_id", inspiration.vamoos_id);
    }
  }, [inspiration, inspirationNotification]);

  return (
    <Wrap style={wrapStyle}>
      <div style={{ width: "100%" }}>
        <SelectInput
          control={control}
          options={[{ vamoos_id: "none", name: "None" }, ...items]}
          optionValueVar="vamoos_id"
          optionLabelVar="name"
          name={inspirationVamoosId || "localInspiration.inspiration_vamoos_id"}
          defaultValue="none"
          label="Choose inspiration"
          isClearable={false}
          disabled={!canEdit || isWiped}
          noSort
          handleChange={value => onChangeInspirationVamoosId(value)}
        />
        <Input
          control={control}
          name={contentName || "localInspiration.content"}
          label="Message content *"
          style={{ marginTop: 15 }}
          disabled={!isInspirationChoosen || !showTime || !canEdit || isWiped}
          defaultValue=""
          multiline
          rows={4}
        />
      </div>
      <div>
        <Description>
          To enable auto-opening, set the date and time you wish the inspiration to appear as the opening view. If left disabled it will be
          accessed only via the icon on the home screen:
        </Description>
        <InputsGrid showDays={showDays} showTime={showTime}>
          <SelectInput
            control={control}
            options={Object.values(DISPLAYABLE_STARTING_POINT_OPTIONS)}
            name={periodName || "localInspiration.period"}
            label="When to display"
            styleContainer={{ gridArea: "period" }}
            isClearable={false}
            defaultValue={Object.values(DISPLAYABLE_STARTING_POINT_OPTIONS)[0].value}
            disabled={!isInspirationChoosen || !canEdit || isWiped}
            handleChange={value => onChangePeriod(value)}
            menuPlacement={menuPlacement}
            noSort
          />
          {showDays && (
            <Input
              control={control}
              name={numberofDaysName || "localInspiration.delivery_at_days"}
              label="Number of days"
              type="number"
              defaultValue={1}
              style={{ gridArea: "days" }}
              disabled={!canEdit || isWiped}
              numberMinValue={0}
            />
          )}
          {showTime && (
            <>
              <div style={{ gridArea: "at" }}> at</div>
              <DateTimeInput
                control={control}
                type="time"
                name={timeName || "localInspiration.start_at"}
                label="Device local time"
                styleContainer={{ gridArea: "time" }}
                defaultValue={moment({ hours: 13, minutes: 0 })}
                format="HH:mm"
                disabled={!canEdit || isWiped}
              />
            </>
          )}
        </InputsGrid>
        {/* <Description style={{ fontSize: 12, marginTop: 25, color: colors.grey40 }}>
          Select a number of days, then choose whether it is before or after the start date. Pick &quot;0&quot; if you wish to select the
          start day itself
        </Description> */}
      </div>
    </Wrap>
  );
};

export default InspirationForm;
