import { useMutation, useQuery } from "@tanstack/react-query";
import AttachButton from "components/_new/Buttons/AttachButton";
import IconSelectOption from "components/_new/IconSelectOption";
import LogoSelect from "components/_new/LogoSelect";
import Switch from "components/_new/Switch";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { Message } from "components/ui/Messages";
import { PERMISSIONS } from "constants/permissions";
import { inputTypes } from "feature/panel/Itinerary/helpers";
import { ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { Wrapper } from "feature/panel/Itinerary/style";
import { LanguageLock } from "feature/panel/Stays/_shared/LanguageLock";
import MapWidget from "feature/panel/_shared/MapWidget";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { useListOfLanguagesObject } from "hooks/useListOfLanguages";
import { usePermissionsService } from "hooks/usePermissionsService";
import React, { cloneElement, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { css } from "styled-components";

import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { VAMOOS_CONNECT_STATUS_PENDING } from "constants/content";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { STAY_CONNECT_STATUS, getConnectStatus } from "feature/panel/Stays/_shared/helpers";
import { HttpClient } from "services/application/httpClient/httpClient";
import { PromptContext } from "components/ui/CustomPrompt/CustomPrompt";
import { MAXIMUM_SHORT_DESCRIPTION_LENGTH } from "../../../../../../constants/defaults";
import { DEFAULT_HOTEL_INFO_ICON_ID } from "../../../../Stays/_shared/initStaysState";
import Preview from "../../../components/pagesElements/General/Preview/index";
import { Grid, InputsGrid } from "../../../components/pagesElements/General/style";
import Features from "./Features";
import { inputs } from "./inputs";
import { VamoosConnectWrap } from "./style";

const General = ({
  form: {
    control,
    watch,
    resetField,
    setValue,
    reset,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  },
  canEdit,
  isWiped,
  isActive,
  action,
  isDirty,
  isDefaultStayLanguage,
}) => {
  const { showPrompt } = useContext(PromptContext);
  const { reference_code: reference_code_p, operator_code: operator_code_p } = useParams();

  // Requests data
  const { data: { operators } = { operators: [] }, isFetching: fetchingOperators } = useQuery({ queryKey: ["/user/me"] });
  const { data: operator } = useQuery({ queryKey: ["operator"] });
  const { data: { items: brands } = { brands: [] }, isFetching: fetchingBrands } = useQuery({
    queryKey: ["/operator/branding"],
    enabled: Boolean(operator),
  });
  // Variables

  const currentOperator = operator?.code;
  const allBrands = operator && brands ? [{ id: "default", name: "Default" }, ...brands] : [];
  const additional_languages = operator?.meta?.additional_languages || [];
  const default_language = operator?.default_language;
  const listOfAllLanguages = useListOfLanguagesObject();

  const languages =
    additional_languages?.length &&
    [default_language, ...additional_languages].map(item => ({
      label: listOfAllLanguages[item].name,
      value: item,
    }));

  const selectedFeatures = watch(!isDefaultStayLanguage ? "main.features.features" : "features.features");
  const currentLangBackground = watch("general.background");
  const mainLangBackground = watch("main.general.background");
  const vamoosId = watch("general.vamoos_id");
  const operatorCode = watch("general.operator_code");
  const isListed = watch("general.is_listed");
  const requestedListingStatus = watch("general.requested_listing_status");
  const address = watch("general.meta.address");
  const connectStatus = getConnectStatus(isListed, requestedListingStatus);
  const isWaitingForApproval = [STAY_CONNECT_STATUS.OFF_REQUESTED, STAY_CONNECT_STATUS.ON_REQUESTED].includes(connectStatus);
  const logo = watch("general.logo");

  const { mutate } = useMutation(
    () => {
      return HttpClient.post(`itinerary/${operatorCode}/DEFAULT/request_listing`, { status: true });
    },
    {
      onSuccess: () => setValue("general.requested_listing_status", true),
    },
  );
  const { data } = useQuery({
    queryKey: [`/operator/check/operator_code/${operatorCode}`],
    enabled: Boolean(isDefaultStayLanguage && operatorCode && operatorCode?.length > 2 && operator_code_p !== operatorCode),
    refetchOnMount: true,
    cacheTime: 0,
  });

  const permissionsService = usePermissionsService();
  const canOperatorsRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, vamoosId);

  const showBrandSelect = !(brands?.length === 1 && brands?.[0].is_default);

  const { data: { items: icons } = {} } = useQuery({ queryKey: ["icon?count=500"] });
  const iconsModified = icons
    ?.filter(item => item.section === "navigation")
    ?.map(item => ({
      ...item,
      name: <IconSelectOption src={item.url} />,
    }));
  // State
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);

  const getOperatorCodes = code => {
    return code ? operators.find(item => item.code === code).used_codes.map(item => ({ label: item, value: item })) : [];
  };

  const options = {
    allBrands,
    languages,
    userID: getOperatorCodes(currentOperator),
  };
  const shouldRenderInput = item => {
    return item.hasOwnProperty("shouldShow") ? item.shouldShow({ showBrandSelect, canOperatorsRead, additional_languages }) : true;
  };

  const openPreview = () => {
    document.body.style.overflow = "hidden";
    setIsMobilePreviewOpen(true);
  };

  const closePreview = () => {
    document.body.style.overflow = "auto";
    setIsMobilePreviewOpen(false);
  };

  const clearAddressErrors = () => {
    clearErrors("locations.locations[0].name");
    clearErrors("locations.locations[0].location");
    clearErrors("locations.locations[0].coordinates");
    clearErrors("general.meta.address");
  };

  const onCopyToField = ({ latitude, longitude, formatted_address }) => {
    if (!isDefaultStayLanguage) {
      return;
    }
    setValue("locations.locations[0].coordinates", `${latitude}, ${longitude}`, { shouldDirty: true });
    setValue("general.meta.address", formatted_address, { shouldDirty: true });
    clearAddressErrors();
  };

  const onCheck = item => {
    const id = item.id;
    if (selectedFeatures[id]) {
      const newValues = selectedFeatures;
      delete newValues[id];

      setValue("features.features", newValues, { shouldDirty: true });
    } else {
      setValue("features.features", { ...(selectedFeatures || {}), [id]: item }, { shouldDirty: true });
    }
  };

  const onTopFeaturesSave = features => {
    setValue("features.features", features, { shouldDirty: true });
  };

  const renderInput = item =>
    shouldRenderInput(item) && (
      <>
        {cloneElement(inputTypes[item.type || "text"], {
          ...item,
          name: item.mainLanguageOnly && !isDefaultStayLanguage ? `main.general.${item.name}` : `general.${item.name}`,
          control,
          placeholder: watch(`main.general.${item.name}`),
          options: options[item.optionsVar],
          disabled: !canEdit || isWiped || (!isDefaultStayLanguage && item.mainLanguageOnly),
          showLanguageLockIfDisabled: item.name === "operator_code",
        })}

        {item.mainLanguageOnly && !isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: -20 }} />}
      </>
    );

  useEffectDependenciesOnly(() => {
    const formValues = getValues();
    if (operator) {
      reset({
        ...(formValues
          ? {
              ...formValues,
              general: {
                ...(action === "create"
                  ? {
                      ...formValues.general,

                      language: default_language,
                      departure_date: new Date(),
                      return_date: new Date(),
                      // start_time: new Date(),
                      // check_in_time: new Date(),
                      user_id: operator?.used_codes[0],
                      branding_profile_id: formValues.branding_profile_id || "default",
                    }
                  : {
                      ...formValues.general,
                    }),
              },
            }
          : {}),
      });
    }
  }, [default_language, operator, brands]);

  useEffect(() => {
    if (data?.used) {
      setError("general.operator_code", { message: "This user id already exists" });
    } else {
      clearErrors("general.operator_code");
    }
  }, [data]);

  useEffect(() => {
    if (address) {
      clearAddressErrors();
    }
    if (isDefaultStayLanguage) {
      setValue("locations.locations[0].name", address);
      setValue("locations.locations[0].location", address);
    }
  }, [address]);

  if (fetchingOperators || fetchingBrands || !currentOperator) {
    return <LoadingScreen />;
  }
  return (
    <Wrapper slimTopPadding>
      {!isActive && (
        <Message
          type="error"
          text="This information will be permanently deleted after 5 days. Click the “Restore” button now to restore your data."
          margin={{ bottom: "25px" }}
        />
      )}
      <Grid>
        <div>
          <TitleRow text="General information" isSticky={false} />

          <VamoosConnectWrap>
            <Switch label="Vamoos connect" checked={isListed} onChange={mutate} disabled={isWaitingForApproval} />
            {isWaitingForApproval && VAMOOS_CONNECT_STATUS_PENDING(connectStatus)}
          </VamoosConnectWrap>
          <ResponsiveShowFrom
            size="md"
            customStyles={css`
              display: flex;
              align-items: center;
              gap: 15px;
              margin-top: 15px;
            `}
          >
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="general.background"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <AttachButton
                    text="Add image"
                    onConfirm={onChange}
                    onDelete={() => onChange(null)}
                    file={value}
                    disabled={!canEdit || isWiped}
                    onChipCustomClick={openPreview}
                    label="Background"
                    mobileFullScreen
                    hideDescription
                  />
                </>
              )}
            />
            {/* {background && <OutlinedButton text="Preview" startIcon={<RemoveRedEye />} type="grey" onClick={openPreview} />} */}
          </ResponsiveShowFrom>

          <InputsGrid>{inputs.map(item => renderInput(item))}</InputsGrid>
          <Switch
            label="Mandate email login"
            control={control}
            name="general.meta.require_personal_details"
            wrap
            styleWrap={{ margin: "25px 0", justifyContent: "space-between" }}
            disabled={!isDefaultStayLanguage}
          />
          {!isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: -20 }} />}

          <Switch
            label="Automatically include account administrators"
            control={control}
            name="general.meta.alert_email_admins"
            wrap
            styleWrap={{ margin: "25px 0", justifyContent: "space-between" }}
            disabled={!isDefaultStayLanguage}
          />
          {!isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: -20 }} />}
        </div>
        <Preview
          control={control}
          image={currentLangBackground || mainLangBackground}
          resetField={resetField}
          open={isMobilePreviewOpen}
          watch={watch}
          onClose={closePreview}
          error={errors?.general?.background}
          disabled={!canEdit || isWiped}
          shouldFade={!currentLangBackground && mainLangBackground}
        />
      </Grid>
      {/* <ItineraryTitle style={{ marginBottom: 35 }}>Address and coordinates</ItineraryTitle> */}
      <TitleRow text="Address and coordinates" isSticky={false} />

      <Grid style={{ gap: 35 }}>
        <div>
          <Input
            control={control}
            name={isDefaultStayLanguage ? "general.meta.address" : "main.general.meta.address"}
            label="Address"
            rules={{ required: true }}
            disabled={!isDefaultStayLanguage}
          />
          {!isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: 10 }} />}

          <Input
            control={control}
            name={isDefaultStayLanguage ? "locations.locations[0].coordinates" : "main.locations.locations[0].coordinates"}
            label="Coordinates"
            rules={{ required: true }}
            style={{ marginTop: 35 }}
            disabled={!isDefaultStayLanguage}
          />
          {!isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: 10 }} />}
        </div>
        <MapWidget
          mapContainerStyle={{ height: 300 }}
          options={{ disableDefaultUI: true }}
          searchPlaceholder="Search location"
          infoWindowsProps={{
            coordinates: {
              onButtonClick: onCopyToField,
            },
            rightClick: {
              disableAddButton: true,
            },
          }}
          customInfoWindowType="coordinates"
          searchByLocation
        />
      </Grid>
      <TitleRow text="Property info button" isSticky={false} />
      <Grid>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 100px", gap: 15 }}>
          <Input control={control} name="general.meta.hotel_info_label" label="Hotel info" />
          <SelectInput
            control={control}
            name="general.meta.hotel_info_icon_id"
            label="Icon"
            options={iconsModified}
            optionLabelVar="name"
            optionValueVar="id"
            isClearable={false}
            styleContainer={{ minWidth: 70 }}
            defaultValue={DEFAULT_HOTEL_INFO_ICON_ID}
          />
        </div>
      </Grid>
      <Grid>
        <div>
          <TitleRow text={<span>Overlay logo {!isDefaultStayLanguage && <LanguageLock />}</span>} isSticky={false} />

          <LogoSelect
            onConfirm={img => setValue("general.logo", img, { shouldDirty: true })}
            onDelete={() => setValue("general.logo", null, { shouldDirty: true })}
            img={logo}
            disabled={!canEdit || isWiped || !isDefaultStayLanguage}
            hideUrl
          />
        </div>
      </Grid>
      <TitleRow text="Vamoos listing information" isSticky={false} />
      <Grid>
        <div style={{ display: "grid", gap: 35 }}>
          <Input
            control={control}
            name="general.meta.short_description"
            label="Short description"
            multiline
            minRows={4}
            hint="Maximum 250 characters"
            limit={MAXIMUM_SHORT_DESCRIPTION_LENGTH}
          />
          <Input control={control} name="general.meta.long_description" label="Long description" multiline minRows={4} />
          <Input
            control={control}
            name="general.meta.number_of_rooms"
            label="Number of rooms"
            disabled={!isDefaultStayLanguage}
            type="number"
            numberMinValue={0}
          />
          {!isDefaultStayLanguage && <LanguageLock style={{ width: "100%", marginTop: -20 }} />}
        </div>
      </Grid>
      <Features
        control={control}
        features={selectedFeatures}
        onCheck={onCheck}
        handleSave={onTopFeaturesSave}
        disabled={!isDefaultStayLanguage}
      />
    </Wrapper>
  );
};

export default General;
