import React from "react";
import { Droppable } from "react-beautiful-dnd";

const Drop = ({ id, type, children }) => {
  return (
    <Droppable droppableId={id || "list-container"} type={type}>
      {provided => (
        <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Drop;
