import * as yup from "yup";
import { localInspiration } from "./validationSchema";

const defaultRequiredMessage = "This field is required";

const general = isDefaultStayLanguage =>
  yup.object({
    background: isDefaultStayLanguage ? yup.object().required("Backgroundis required") : yup.object().notRequired(),
    operator_code: yup.string().required("User ID is required"),
    field1: isDefaultStayLanguage ? yup.string().required("Property name is required") : yup.string().notRequired(),
    meta: yup.object({
      address: yup.string().required("Address is required"),
      email: yup.string().email("Wrong email format"),
      website: yup
        .string()
        .matches(
          /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Wrong URL format",
        ),
    }),
  });

const actions = yup.object({
  documents: yup.object({
    /* eslint-disable */
    travel: yup.array().test("is-required", "This field is required", function(value, { path, createError }) {
      const label = this.from[1].value.meta.travel_documents_label;
      if (!value?.[0]?.file && label) return createError({ message: "File is required", path: "actions.documents.travel[0].file" });
      return true;
    }),
    destination: yup.array().test("is-required", "This field is required", function(value, { path, createError }) {
      const label = this.from[1].value.meta.destination_documents_label;
      if (!value?.[0]?.file && label) return createError({ message: "File is required", path: "actions.documents.destination[0].file" });
      return true;
    }),
  }),
  meta: yup.object({
    travel_documents_label: yup.string().test("is-required", "This field is required", function(value) {
      const doc = this.from[1].value.documents.travel?.[0]?.file;
      return !(!value && doc);
    }),
    destination_documents_label: yup.string().test("is-required", "This field is required", function(value) {
      const doc = this.from[1].value.documents.destination?.[0]?.file;
      return !(!value && doc);
    }),
  }),
});

const storyboard = yup.object({
  details: yup.array().of(
    yup.object({
      image: yup.object().required("Image is required"),
    }),
  ),
});

const passcode_groups = yup.object({
  passcode_groups: yup.array().of(
    yup.object({
      name: yup.string().required("Category name is required"),
      passcodes: yup.array().of(
        yup.object({
          passcode: yup.string().required("Passcode is required"),
        }),
      ),
    }),
  ),
});

const messages = yup.object({
  meta: yup.object({
    messaging_emails_list: yup.array().of(
      yup.object({
        email: yup
          .string()
          .email("Enter valid email")
          .required("Email is required"),
      }),
    ),
  }),
});

export const locations = isDefaultStayLanguage =>
  yup.object({
    locations: yup.array().of(
      yup.object({
        name: isDefaultStayLanguage ? yup.string().required("Name can't be blank") : yup.string().notRequired(),
        location: isDefaultStayLanguage ? yup.string().required("Location can't be blank") : yup.string().notRequired(),
        // location: yup.string().required(defaultRequiredMessage),

        coordinates: isDefaultStayLanguage
          ? yup.string().test({
              name: "some-name",
              message: "Coordinates can't be blank",
              test: (value, { createError, path }) => {
                if (!value) {
                  return false; // Explicitly mark as invalid if coordinates are empty
                }

                const [latitude, longitude] = value.split(",").map(coord => parseFloat(coord.trim()));

                if (isNaN(latitude) || latitude < -90 || latitude > 90) {
                  return createError({ message: "Latitude must be between -90 and 90 degrees", path });
                }

                if (!longitude || isNaN(longitude) || longitude < -180 || longitude > 180) {
                  return createError({ message: "Longitude must be between -180 and 180 degrees", path });
                }

                return true;
              },
            })
          : yup.string().notRequired(),
      }),
    ),
  });

export const schema = isDefaultStayLanguage =>
  yup.object({
    general: general(isDefaultStayLanguage),
    actions,
    storyboard,
    passcode_groups,
    messages,
    locations: locations(isDefaultStayLanguage),
    localInspiration,
  });
