import React from "react";
import { TableCell } from "@mui/material";
import Checkbox from "components/_new/Checkbox";

import { Link } from "../../../../components/ui/Links/Links";
import { StyledTableRow } from "./helpers";

const CustomTableRow = ({ data = null, columns = [], isItemSelected = false, onCheck = () => {}, checkboxSelection = true, showRefactoredEditor = false }) => {
  if (!data) return null;

  const { is_active: isActive } = data;
  const redirectUrl = showRefactoredEditor ? `/panel/itinerary/${data.type}/edit/${data.operator_code}/${data.reference_code}/general` :
    (data.type === "stay" ? `/panel/${data.type}s/${data.operator_code}/edit` : `/panel/${data.type}s/${data.operator_code}/${data.reference_code}/edit`);

  return (
    <StyledTableRow item={data} archived={(!isActive).toString()}>
      {checkboxSelection && (
        <TableCell>
          <Checkbox checked={isItemSelected} onChange={onCheck} />
        </TableCell>
      )}
      {columns.map(item => (
        <TableCell key={item.id} align={item.align} style={{ padding: item.isAction && 0 }}>
          {item.isAction ? (
            item.renderCell(data)
          ) : (
            <Link
              to={redirectUrl}
              cv="grey100"
              linkFor="table"
              noDecoration
            >
              {item.renderCell ? item.renderCell(data) : data[item.id]}
            </Link>
          )}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

export default CustomTableRow;
