import { getStickyPanelContentStyles } from "components/_new/responsiveStyle";
import { INPUT_BORDER_COLOR } from "components/ui/Inputs/config";
import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { font } from "config/theme/fonts";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  margin-bottom: 55px;
`;

export const GridWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-wrap: wrap;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: ${font};
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 25px;

  z-index: 8;
  position: sticky;
  top: 88px;
  background-color: white;

  span {
    font-weight: 400;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0px;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(#FFFFFFFF, #FFFFFF00);
  }

  ${getStickyPanelContentStyles()}

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    font-size: 16px;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  width: 100%;
  //margin-left: 165px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: 100%;
    margin-left: 0;
    grid-template-columns: 1fr;
  }
`;

export const Image = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid ${INPUT_BORDER_COLOR};
  border-radius: 8px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${p => p.image});
  background-size: cover;
  background-position: center;
  svg {
    color: ${INPUT_BORDER_COLOR};
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    position: relative;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  // width: calc(100% - 165px);
  // margin-left: 165px;
  // margin-top: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ImageInputWrap = styled.div`
  position: relative;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 15px;
  }
`;

export const BottomLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${font};
  margin-bottom: 15px;
  width: 100%;
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 48px 48px 1fr;
  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 40px 40px 40px;
  }
`;

export const DnDItemWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(160px, auto);
  /* gap: 15px; */
  width: 100%;
  align-items: center;
  padding-left: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    padding: 15px 0 0 0;
  }
`;

export const DocumentsActions = styled.div`
  display: grid;
  grid-template-columns: minmax(80px, auto) 80px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-top: 15px;
    border-top: 1px solid ${colors.grey10};
    button {
      width: 100% !important;
      border-left: 0 !important;
    }
  }
`;

export const DocumentsInputs = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 15px;
  padding-right: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding-left: 15px;
  }
`;
