import React from "react";

const Item = ({ item }) => {
  const { meta: { day_number } = {}, headlines } = item;
  return (
    <div>
      <div>Day {day_number}</div>
    </div>
  );
};

export default Item;
