import React, { useRef, useState, useEffect } from "react";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Controller } from "react-hook-form";
import { matchSorter } from "match-sorter";

import { Wrap, getSelectStyles } from "./style";
import Label from "../components/Label";
import { ConditionalWrapper } from "../conditionalWrapper";
import InputErrorOrHint from "../components/Error";

const SelectInput = ({
  label,
  options,
  control,
  name,
  rules,
  onChange,
  isClearable = true,
  optionLabelVar = "label",
  optionValueVar = "value",
  components,
  type = "default",
  menuPlacement = "bottom",
  onCreateOption,
  modifyValue,
  styleContainer,
  labelStyle,
  defaultValue,
  isMulti,
  value,
  disableSelectOnBlur,
  onBlur,
  noSort,
  nextInputRef,
  disabled,
  getOptionLabel,
  handleChange,
  isSearchable = false,
  autoFocus,
  handleFocus,
  menuPosition = "absolute",
  ...rest
}) => {
  const ref = useRef();

  const [newValue, setNewValue] = useState(null);
  const [opt, setOpt] = useState(options);
  const [input, setInput] = useState();
  const [isFocused, setIsFocused] = useState(false);

  const getOptionValue = value => {
    return opt?.find(item => item[optionValueVar] === value);
  };

  const toggleFocus = () => {
    if (!isFocused && handleFocus) handleFocus();
    if (isFocused && onBlur) onBlur();
    setIsFocused(!isFocused);
  };

  const handleBlur = (e, onChange, value) => {
    if (onBlur) onBlur();
    setIsFocused(false);
    if (onBlur) onBlur(e);

    if (!disableSelectOnBlur) onChange(opt[0] || { label: input, value: input, __isNew__: true });
    if ((!value || opt[0]?.value === value.value) && !newValue && input) {
      onChange(newValue || opt[0]?.value || { label: input, value: input, __isNew__: true });
      setNewValue(null);
    } else {
      onChange(value);
      setNewValue(null);
    }
  };

  const commonProps = error => ({
    styles: getSelectStyles(error),
    options: opt,
    components,
    autoFocus,
    onFocus: toggleFocus,
    onBlur: toggleFocus,
  });
  useEffect(() => {
    if (options) {
      setOpt(noSort ? options : matchSorter(options, "", { keys: [optionValueVar || "value", optionLabelVar || "label"] }));
    }
  }, [options]);

  useEffect(() => {
    setInput(null);
  }, [value]);

  const renderInput = ({ error, onChange, value }) => (
    <Wrap style={styleContainer} disabled={disabled}>
      {label && (
        <Label
          isFocused={isFocused}
          style={labelStyle}
          value={Array.isArray(value) ? (value.length >= 1 ? value : null) : value}
          error={error}
        >
          {label + (rules?.required ? "*" : "")}
        </Label>
      )}
      {type === "creatable" ? (
        <>
          <CreatableSelect
            value={modifyValue ? modifyValue(value) : getOptionValue(value)}
            onChange={item => {
              onChange(item?.[optionValueVar]);
              setNewValue(item?.[optionValueVar]);
            }}
            onCreateOption={onCreateOption}
            getOptionLabel={value => (getOptionLabel ? getOptionLabel(value) : value[optionLabelVar])}
            getOptionValue={value => value[optionValueVar]}
            // onKeyDown={e => {
            //   if (e.key === "Tab" && nextInputRef) {
            //     nextInputRef.current.focus();
            //   }
            // }}
            onInputChange={inputValue => {
              setOpt(
                matchSorter(options, inputValue, {
                  keys: [
                    { key: optionValueVar || "value", threshold: matchSorter.rankings[input ? "EQUALS" : "CONTAINS"] },
                    { key: optionLabelVar || "label", threshold: matchSorter.rankings.CONTAINS },
                  ],
                }),
              );
              setInput(inputValue);
            }}
            placeholder=""
            isDisabled={disabled}
            {...commonProps(error)}
            onBlur={e => handleBlur(e, onChange, value)}
          />
        </>
      ) : (
        <Select
          options={options}
          components={components}
          value={getOptionValue(value)}
          menuPosition={menuPosition}
          onChange={item => {
            const value = isMulti ? item : item?.[optionValueVar];
            onChange(value);
            if (handleChange) handleChange(value, name);
          }}
          isClearable={!rules?.required && isClearable}
          getOptionLabel={value => (getOptionLabel ? getOptionLabel(value) : value[optionLabelVar])}
          getOptionValue={value => value[optionValueVar]}
          isMulti={isMulti}
          menuPlacement={menuPlacement}
          isDisabled={disabled}
          isSearchable={isSearchable}
          {...commonProps(error)}
          {...rest}
        />
      )}

      <InputErrorOrHint error={error} />
    </Wrap>
  );

  return (
    <ConditionalWrapper
      condition={control}
      wrapper={() => (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return renderInput({ error, onChange, value });
          }}
        />
      )}
    >
      {renderInput({ onChange, value })}
    </ConditionalWrapper>
  );
};

export default SelectInput;
