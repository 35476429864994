import React from "react";
import { DeleteAction } from "components/ui/Tables";
import { DeleteOutline, PeopleAltOutlined } from "@mui/icons-material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { TravellersAction, ViewAction } from "components/ui/Tables/TableRowActions";
import { convertToDateTimeFormat } from "utils/dataConverters";
import Badge from "components/_new/Badge";
import TableAction from "components/_new/Table/Action";
import EditIcon from "@mui/icons-material/Edit";

export default ({ toggleShowDetailsModal, onDelete, disabled, handleTravellersModal, restrictedFlightsDataByPerson }) => [
  {
    id: "carrier_flight_number",
    label: "Flight number",
    align: "left",
    renderCell: ({ carrier, carrier_flight_number }) => `${carrier?.fs_code} ${carrier_flight_number}`,
  },

  { id: "airline", label: "Airline", minWidth: 100, align: "left", renderCell: ({ carrier }) => carrier.name },
  { id: "departure", label: "Departure", minWidth: 100, align: "left", renderCell: ({ departure_airport }) => departure_airport.iata_code },
  {
    id: "scheduled_departure",
    label: "Scheduled departure",
    minWidth: 100,
    align: "left",
    renderCell: ({ departure_at_utc, departure_airport }) => convertToDateTimeFormat(departure_at_utc, departure_airport.timezone),
  },
  { id: "arrival", label: "Arrival", align: "left", renderCell: ({ arrival_airport }) => arrival_airport.fs_code },
  {
    id: "scheduled_arrival",
    label: "Scheduled arrival",
    align: "left",
    renderCell: ({ arrival_at_utc, arrival_airport }) => convertToDateTimeFormat(arrival_at_utc, arrival_airport.timezone),
  },

  {
    id: "delete",
    label: "",
    align: "right",
    isAction: true,
    renderCell: (data, index) =>
      !disabled && (
        <div style={{ display: "flex" }}>
          <TableAction
            element={<PeopleAltOutlined />}
            badgeContent={restrictedFlightsDataByPerson[data.id]?.length ?? null}
            onClick={e => {
              e.stopPropagation();
              handleTravellersModal({ ...data, index });
            }}
          />
          <TableAction
            element={<RemoveRedEyeOutlinedIcon />}
            onClick={e => {
              toggleShowDetailsModal(data);
              e.stopPropagation();
            }}
          />
          <TableAction
            element={<DeleteOutline />}
            onClick={e => {
              e.stopPropagation();
              onDelete({ ...data, index });
            }}
          />
        </div>
      ),
  },
];
