import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseModal from "components/_new/ModalBase";
import { useForm } from "react-hook-form";
import Input from "components/ui/Inputs/TextInput";
import Checkbox from "components/_new/Checkbox";
import Switch from "components/_new/Switch";
import { HttpClient } from "services/application/httpClient/httpClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Message } from "components/ui/Messages/Message";
import { useDispatch } from "react-redux";
import { setNotification } from "store/app/actions";
import { checkboxes, prepareDataToSubmit } from "./helpers";

const CreationModal = ({ onClose, field1, field3, data, isDirty, onSave, isEditing }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { reference_code, operator_code } = useParams();
  const { control, watch, handleSubmit, reset, setValue } = useForm({ defaultValues: { show_dates: true } });

  const [cacheFrontPagetNotes, setCacheFrontPageNotes] = useState(null);
  const [cacheLastPageNotes, setCacheLastPageNotes] = useState(null);

  const storyboard = watch("sections.storyboard");
  const front_page_notes = watch("sections.notes_front");
  const last_page_notes = watch("sections.notes_end");
  const id = watch("id");

  const { mutate, isLoading } = useMutation(
    body => {
      return HttpClient.post(`/creation/${operator_code}/${reference_code}`, { ...body, ...(id ? { id } : {}) });
    },
    {
      onSuccess: async () => {
        dispatch(setNotification({ type: "success", message: `Creation ${id ? "updated" : "created"} successfully` }));
        await queryClient.invalidateQueries({ queryKey: ["creations"] });
        onClose();
      },
    },
  );

  const onSubmit = values =>
    mutate(
      prepareDataToSubmit(
        {
          ...values,
          sections: {
            ...values.sections,
            cover: {
              imagesize: "reduced",
              headline: `${field1} - ${field3}`,
            },
          },
        },
        "creation",
      ),
    );
  useEffect(() => {
    if (data) reset({ ...data });
  }, [data]);

  useEffect(() => {
    const notes_front = data?.sections?.notes_front;
    if (front_page_notes && typeof front_page_notes !== "object" && (notes_front || cacheFrontPagetNotes)) {
      setValue("sections.notes_front.headline", (notes_front || cacheFrontPagetNotes).headline);
      setValue("sections.notes_front.content", (notes_front || cacheFrontPagetNotes).content);
    }
  }, [front_page_notes]);

  useEffect(() => {
    const notes_end = data?.sections?.notes_end;
    if (last_page_notes && typeof last_page_notes !== "object" && (notes_end || cacheLastPageNotes)) {
      setValue("sections.notes_end.headline", (notes_end || cacheLastPageNotes).headline);
      setValue("sections.notes_end.content", (notes_end || cacheLastPageNotes).content);
    }
  }, [last_page_notes]);

  useEffect(() => {
    if (storyboard) setValue("sections.storyboard.image_size", "adjusted");
  }, [storyboard]);

  return (
    <BaseModal
      title={isEditing ? "Edit creation" : "Generate new creation"}
      confirmTitle={isDirty ? "Save" : "Generate"}
      onCancel={onClose}
      onConfirm={isDirty ? onSave : handleSubmit(onSubmit)}
      contentStyle={{ overflowY: "auto" }}
      modalStyle={{ width: 800 }}
      confirmDisabled={isLoading}
      mobileFullScreen
    >
      {isDirty ? (
        <Message
          text="You currently have unsaved changes. In order to continue with this operation you need to save first."
          type="info"
          margin={{ top: "15px" }}
        />
      ) : (
        <>
          <Input
            autoFocus
            control={control}
            name="name"
            label="File name"
            style={{ marginTop: 15 }}
            rules={{ required: "File name is required" }}
          />

          <Switch label=" Show days in document" control={control} name="show_dates" styleWrap={{ margin: "15px 0" }} />

          <div>Include following sections in document:</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {checkboxes({ storyboard, front_page_notes, last_page_notes, control }).map(item => (
              <>
                <Checkbox
                  key={item.name}
                  control={control}
                  name={item.name}
                  label={item.label}
                  wrapStyle={{ marginTop: 15 }}
                  labelStyle={{ fontSize: 18, lineHeight: "normal" }}
                  handleChange={val => {
                    if (!val) {
                      if (item.name === "sections.notes_front") {
                        setCacheFrontPageNotes(front_page_notes);
                      }
                      if (item.name === "sections.notes_end") {
                        setCacheLastPageNotes(last_page_notes);
                      }
                    }
                  }}
                />
                {item.additional}
              </>
            ))}
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default CreationModal;
