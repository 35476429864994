import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { Wrapper } from "feature/panel/Itinerary/style";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { generateUniqueId } from "utils";

import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import Item from "./item";

const Gallery = ({
  form: {
    control,
    watch,
    setValue,
    formState: { errors },
  },
}) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "storyboard.details",
    keyName: "rf_id",
  });
  const watchFields = watch("storyboard.details");
  const hasFile = watchFields?.find(item => item.image);

  const onDragEnd = (list, { source, destination }) => {
    move(source.index, destination.index);
    // replace(list);
  };
  return (
    <Wrapper slimTopPadding>
      <TitleRow text="Images" isSticky>
        <OutlinedButton
          text="Add"
          startIcon={<AddIcon />}
          onClick={() =>
            append({
              id: generateUniqueId(),
              isNew: true,
              headline: "",
              content: "",
              location: null,
              documents: [],
              image: null,
            })
          }
        />
      </TitleRow>

      <div style={{ height: 20 }} />

      {fields.length ? (
        <DnD
          list={fields}
          onDragEnd={onDragEnd}
          element={<Item control={control} watch={watch} onDelete={remove} setValue={setValue} errors={errors} hasFile={hasFile} />}
          contentStyle={{ padding: 0 }}
        />
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding images`}</NoResultsMessage>
      )}
    </Wrapper>
  );
};

export default Gallery;
