import React, { forwardRef } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Content, Lines, Wrap } from "./style";

const DraggableWrap = forwardRef(({ children, dragHandleProps = {}, draggableProps = {}, wrapStyle, contentStyle, disabled }, ref) => {
  return (
    <Wrap ref={ref} style={wrapStyle} {...(!disabled && dragHandleProps)} {...(!disabled && draggableProps)}>
      {!disabled && (
        <Lines>
          <ReorderIcon />
        </Lines>
      )}

      <Content style={contentStyle}>{children}</Content>
    </Wrap>
  );
});

export default DraggableWrap;
