import { ERRORS } from "constants/content";
import { coordinatesValidator } from "utils/validators/sharedValidators";

export class PoiValidator {
  // eslint-disable-next-line class-methods-use-this
  validate(poiForm) {
    const errors = {};
    const { name, country, iconId } = poiForm;
  
    const coordinatesErrors = coordinatesValidator(poiForm);

    if (!name?.length) {
      errors.default = {
        name: ERRORS.isRequired("Name")
      }
    }
    if (!country?.length) {
      if(errors.default) {
        errors.default.country = ERRORS.isRequired("Country");
      } else {
        errors.default = {
          country: ERRORS.isRequired("Country")
        }
      }
    }
    if (!iconId) {
      if(errors.default) {
        errors.default.icon_id = ERRORS.isRequired("Icon");
      } else {
        errors.default = {
          icon_id: ERRORS.isRequired("Icon")
        }
      }
    }
  
    if(Object.keys(coordinatesErrors).length > 0) {
      if(errors.default) {
        errors.default = {
          ...errors.default,
          ...coordinatesErrors
        };
      } else {
        errors.default = coordinatesErrors
      }
    }
  
    return errors;
  }
}
