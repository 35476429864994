import { DeleteOutline } from "@mui/icons-material";
import LibraryModal from "components/_new/LibraryModal";
import TableAction from "components/_new/Table/Action";
import { ImagePlaceholder } from "components/ui/Images";
import Input from "components/ui/Inputs/TextInput";
import React, { useState } from "react";
import { getFilePreviewUrl } from "utils/library";
import { ErrorText } from "components/ui/Inputs/TextInput/style";
import { UrlRefreshService } from "services/application/UrlRefreshService";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { colors } from "config/theme/colors";
import DeleteButton from "components/_new/DnD/DeleteIcon";

import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { Controller } from "react-hook-form";
import { Buttons, Grid, Image, ImageWrap, PlaceholderWrap } from "./style";
import { renderAddFileMini } from "../../Trips/Locations/ListOfLocations/item";

const Item = ({ item, control, index, watch, onDelete, setValue, errors, hasFile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const watchImageName = `storyboard.details.${index}.image`;
  const image = watch(watchImageName);
  const url = image && getFilePreviewUrl(image);
  const hasError = errors?.storyboard?.details?.[index]?.image;

  const handleDeleteFile = () => setValue(watchImageName, null, { shouldDirty: true });

  const toggleExpanded = () => !hasError && setIsExpanded(!isExpanded);
  const toggleOpen = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   if (hasError) setIsExpanded(true);
  // }, [errors]);

  return (
    <div key={item.rf_id}>
      {/* {isOpen && (
        <Controller
          control={control}
          name={`storyboard.details.${index}.image`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <LibraryModal title="Add file" mobileFullScreen onCancel={toggleOpen} onConfirm={onChange} hideUrl />
          )}
        />
      )} */}

      <Grid>
        <ResponsiveShowFrom size="md">
          <DeleteButton onClick={() => onDelete(index)} />
        </ResponsiveShowFrom>
        <div style={{ display: "flex", alignItems: "center", gap: 20, padding: "0 15px" }}>
          <ImageWrap hasError={hasError}>
            {url ? (
              <Image src={url} alt="preview" />
            ) : (
              <PlaceholderWrap>
                <ImagePlaceholder hasError={hasError} />
              </PlaceholderWrap>
            )}
          </ImageWrap>{" "}
          <Input control={control} name={`storyboard.details.${index}.headline`} label="Title" forwardKey={item.id} autoFocus />
        </div>
        <Buttons>
          {renderAddFileMini({
            control,
            name: watchImageName,
            handleDeleteFile,
            watchItem: image,
            mediaTypes: IMAGES_MIME_TYPES,
            actionMobileStyle: { borderLeft: 0, borderTop: `1px solid ${colors.grey10}` },
            // buttonStyle: {  },
            hideUrl: true,
            modalTypes: ["library", "upload"],
            shouldButtonExpand: hasFile,
          })}
          <ResponsiveHideFrom size="md">
            <TableAction
              element={<DeleteOutline />}
              mobileStyle={{ width: "100%", borderTop: `1px solid ${colors.grey10}` }}
              onClick={() => onDelete(index)}
            />
          </ResponsiveHideFrom>
        </Buttons>
      </Grid>
      {hasError && <ErrorText style={{ margin: 0, padding: "0 0 15px 15px" }}>Image is required</ErrorText>}
      {/* {isExpanded && (
        <div style={{ borderTop: `1px solid ${colors.grey10}`, padding: 15 }}>
          <Controller
            control={control}
            name={`storyboard.details.${index}.image`}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LogoSelect img={value} onDelete={onChange} error={error} onConfirm={onChange} />
            )}
          />
        </div>
      )} */}
    </div>
  );
};

export default Item;
