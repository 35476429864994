import React, { cloneElement } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { navigations } from "./components/Navigation/config";

const ItineraryRoutes = ({ type, isLoading, isReseted, ...rest }) => {
  const routes = navigations[type];

  return (
    <Routes>
      <Route path="" element={<Navigate to={routes[0].path} />} />

      {routes.map(item => (
        <Route
          path={item.path}
          key={item.path}
          element={isLoading ? <LoadingScreen /> : cloneElement(item.element, { isLoading, isReseted, ...rest })}
          {...(item.routeProps || {})}
        />
      ))}
    </Routes>
  );
};

export default ItineraryRoutes;
