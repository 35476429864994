import { isValidEmail } from "helpers";
import * as yup from "yup";
import { isEmpty } from "lodash";

const defaultRequiredMessage = "This field is required";
const maxNumberOfDaysForNotifications = 400;

export const yupCoordinatesCheck = (value, { createError, path }) => {
  const [latitude, longitude] = value.split(",").map(coord => parseFloat(coord.trim()));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(latitude) || latitude < -90 || latitude > 90) {
    return createError({ message: "Latitude must be between -90 and 90 degrees", path });
  }
  // eslint-disable-next-line no-restricted-globals
  if (!longitude || isNaN(longitude) || longitude < -180 || longitude > 180) {
    return createError({ message: "Longitude must be between -180 and 180 degrees", path });
  }

  return true;
};

const general = yup.object({
  type: yup.string().oneOf(["trip", "stay"]),
  background: yup.object().required("Background image is required"),
  operator_code: yup.string().required("User ID is required"),
  reference_code: yup
    .string()
    .min(3, "Should be at least 3 characters")
    .required("Passcode is required"),
  field1: yup.string().required("Destination / Event title is required"),
  field3: yup.string().required("Name / Location is required"),
  language: yup.string().default("en"),
  departure_date: yup.date().required(defaultRequiredMessage),
  return_date: yup.date().required(defaultRequiredMessage),
  start_time: yup.date().required(defaultRequiredMessage),
});

const documents = yup.object({
  documents: yup.object({
    /* eslint-disable */
    travel: yup.array().of(
      yup.object({
        name: yup
          .string()
          .required("Title is required")
          .test("is-unique", "Names must be unique", function(value) {
            const currentItemId = this.from[0].value.id;
            const travelDocs = this.from[1].value.travel.filter(item => item.id !== currentItemId);
            return travelDocs.every(doc => doc.name.toLowerCase() !== value.toLowerCase());
          }),
        file: yup.object().required("Attachment is required"),
      }),
    ),
    destination: yup.array().of(
      yup.object({
        name: yup
          .string()
          .required("Title is required")
          .test("is-unique", "Names must be unique", function(value) {
            const currentItemId = this.from[0].value.id;
            const destinationDocs = this.from[1].value.destination.filter(item => item.id !== currentItemId);
            return destinationDocs.every(doc => doc.name.toLowerCase() !== value.toLowerCase());
          }),
        file: yup.object().required("Attachment is required"),
      }),
    ),
  }),
});

export const locations = yup.object({
  locations: yup.array().of(
    yup.object({
      name: yup.string().required("Name can't be blank"),
      location: yup.string().test("location-not-blank", "Location can't be blank", function(value) {
          const isOperatorCode = !!(value === "" && this.from[0].value?.nested?.operator_code);
          return isOperatorCode || !!value;
        }),
      // location: yup.string().required(defaultRequiredMessage),
      coordinates: yup.string().test({
        name: "some-name",
        message: "Coordinates can't be blank",
        test: (value, { createError, path }) => {
          if (!value) {
            return false; // Explicitly mark as invalid if coordinates are empty
          }

          const [latitude, longitude] = value.split(",").map(coord => parseFloat(coord.trim()));

          if (isNaN(latitude) || latitude < -90 || latitude > 90) {
            return createError({ message: "Latitude must be between -90 and 90 degrees", path });
          }

          if (!longitude || isNaN(longitude) || longitude < -180 || longitude > 180) {
            return createError({ message: "Longitude must be between -180 and 180 degrees", path });
          }

          return true;
        },
      }),
    }),
  ),
});

const storyboard = yup.object({
  details: yup.array().of(
    yup.object({
      documents: yup.array().of(
        yup.object({
          name: yup
            .string()
            .required("Title is required")
            .test("is-unique", "Names must be unique", function(value) {
              const currentItemId = this.from[0].value.id;
              const docs = this.from[1].value.documents.filter(item => item.id !== currentItemId);
              return docs.every(doc => doc.name.toLowerCase() !== value.toLowerCase());
            }),
          file: yup.object().required("Attachment is required"),
        }),
      ),
    }),
  ),
});

const people = yup.object({
  travellers: yup.array().of(
    yup.object({
      name: yup
        .string()
        .required("Name is required")
        .test("is-unique", "Names must be unique", function(value) {
          const currentItemId = this.from[0].value.id;
          const travellers = this.from[1].value.travellers.filter(item => item.id !== currentItemId);
          const isUnique = travellers.every(traveller => traveller.name.toLowerCase() !== value.toLowerCase());

          return isUnique;
        }),
      email: yup
        .string()
        .nullable()
        .test("email-user", "Error", (initValue, { createError, path, from }) => {
          if (initValue) {
            const value = initValue.split(',')
              .map(item => item.trim())
              .filter(item => item !== '')
              .join(',');

            const currentItemId = from[0].value.id;
            const travellers = from[1].value.travellers.filter(item => item.id !== currentItemId);
            const isEmailsUnique = !travellers.find(traveller =>
              traveller.email?.split(",").some(item =>
                value
                  ?.trim()
                  .split(",")
                  .includes(item.trim()),
              ),
            );

            if (value.split(",").find(item => !isValidEmail(item.trim()))) {
              return createError({ message: "Email should be valid", path });
            }

            if (!isEmailsUnique) {
              return createError({ message: "Emails should be unique", path });
            }

            const valueEmails = value
              ?.trim()
              .split(",")
              .map(email => email.trim());
            const uniqueValueEmails = new Set(valueEmails);
            if (uniqueValueEmails.size !== valueEmails.length) {
              return createError({ message: "Emails should be unique", path });
            }
          }

          return true;
        }),
    }),
  ),
});

const messages = yup.object({
  meta: yup.object({
    messaging_emails_list: yup.array().of(
      yup.object({
        email: yup
          .string()
          .email("Enter valid email")
          .required("Email is required"),
      }),
    ),
  }),
});

export const localInspiration = yup.object({
  content: yup.string().test("is-require", defaultRequiredMessage, function(value) {
    const contentNotRequired =
      isEmpty(this.from[0].value) || this.from[0].value.period === "disabled" || !this.from[0].value.hasOwnProperty("period");
    return contentNotRequired || !!value;
  }),
  delivery_at_days: yup.number().test("less-then", `Value must be less than ${maxNumberOfDaysForNotifications}`, function(value) {
    return !value || value <= maxNumberOfDaysForNotifications;
  }),
});

export const schema = yup.object({
  general,
  documents,
  locations,
  storyboard,
  people,
  messages,
  localInspiration,
});
