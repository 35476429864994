import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from "@mui/icons-material/Edit";
import { Menu, MenuItem } from "@mui/material";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { NoResultsMessage } from "components/ui/Messages";
import { colors } from "config/theme/colors";
import StickyContainer from "feature/panel/Itinerary/components/StickyContainer";
import { Wrapper } from "feature/panel/Itinerary/style";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import DirectoryModal from "../../../components/Modal/modal";
import AccessSettingInput from "../../../components/AccessInput/index";
import Item from "./item";
import { Breadcrumb, DirBlock } from "./style";

const Directory = ({ form: { control, watch } }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [selectedDir, setSelectedDir] = useState([]);
  const [isList, setIsList] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const { fields: directories, replace, update, append, remove, move } = useFieldArray({
    name: "directories.directory",
    control,
    keyName: "rf_id",
  });

  const findInChildren = (array, targetName) => {
    for (const obj of array) {
      // Check if the current object's name matches the target name
      if (obj.name === targetName) {
        return obj;
      }

      // If the current object has children, search in them recursively
      if (obj.children) {
        const result = findInChildren(obj.children, targetName);
        if (result) {
          return result;
        }
      }
    }
    // If the value is not found, return null
    return null;
  };

  const activeSelected = selectedDir?.find(item => item.is_active);

  const activeDir = directories?.find(item => item.name === activeSelected?.name) || findInChildren(directories, activeSelected?.name);

  const toggleMenu = ({ currentTarget } = {}, data) => setOpenMenu(openMenu ? null : { target: currentTarget, data });

  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  const findAndReplace = (dataArray, nameToFind, replacement) => {
    return dataArray.reduce((acc, obj) => {
      // Base case: if the current object matches the name to find
      if (obj.name === nameToFind) {
        // If replacement is null, skip adding this object to the accumulator
        if (replacement) {
          acc.push(replacement);
        }

        return acc;
      }

      // Recursive case: if the current object has children, recursively search for the name
      if (obj.children && obj.children.length > 0) {
        obj.children = findAndReplace(obj.children, nameToFind, replacement);
      }

      acc.push(obj);
      return acc;
    }, []);
  };

  const findDirAndUpdate = (arr, name, item) => {
    const findIndex = arr.findIndex(dir => dir.id === item.id);
    const newArr = [...arr];

    newArr[findIndex] = item;

    return newArr;
  };

  const onDelete = (item, index) => {
    if (selectedDir?.length) {
      const result = findAndReplace(directories, item.name);
      replace(result);
    } else {
      remove(index);
    }
  };

  const onDirUpdate = (item, isChild) => {
    let result = [];

    if (!activeDir) {
      update(item.index, item);
      return;
    }

    if (isChild) {
      const newChildren = findDirAndUpdate(isChild ? activeDir.children : selectedDir, item.name, item);
      const dirsCopy = [...selectedDir];
      const findIndex = selectedDir.findIndex(dir => dir.name === activeDir.name);
      dirsCopy[findIndex].children = newChildren;

      result = dirsCopy;
    } else {
      result = findDirAndUpdate(isChild ? activeDir.children : selectedDir, activeDir?.name, item);
    }
    setSelectedDir(result);
    replace(findAndReplace(directories, activeDir?.name, item));
    // replace(result);
  };

  const onDirSelect = dir => {
    if (!dir.children) return;
    const findIndex = selectedDir?.findIndex(item => item.name === dir.name);

    if (findIndex !== -1) {
      const newArr = selectedDir.slice(0, findIndex + 1);

      newArr[findIndex].is_active = true;
      setSelectedDir(newArr);
    } else {
      setSelectedDir([...(selectedDir?.map(item => ({ ...item, is_active: false })) || []), { ...dir, is_active: true }]);
    }
  };

  const onCustomCreate = item => {
    const findIndex = selectedDir?.findIndex(item => item.name === activeDir.name);
    const newActiveDir = activeDir;
    const selectedCopy = [...selectedDir];

    if (!newActiveDir.children) {
      newActiveDir.children = [];
    }

    newActiveDir.children.push(item);
    selectedCopy[findIndex] = newActiveDir;

    replace(findAndReplace(directories, activeDir.name, newActiveDir));
    setSelectedDir(selectedCopy);
  };

  const showList = activeDir ? activeDir?.children?.length > 0 : directories.length > 0;
  return (
    <Wrapper slimTopPadding>
      {isModalOpen && (
        <DirectoryModal
          append={append}
          onClose={() => {
            setIsCreate(false);
            setIsModalOpen(false);
            setIsList(false);
          }}
          title={`${!isCreate ? "Edit" : "Create new"}  directory ${isList ? "list" : ""} item`}
          confirmButton="Save"
          isList={isList}
          type="directory"
          item={!isCreate && activeDir}
          index={activeDir?.index}
          update={update}
          onCustomUpdate={onDirUpdate}
          activeDir={activeDir}
          onCustomCreate={onCustomCreate}
          directories={directories}
          isCreate={isCreate}
          replace={replace}
        />
      )}
      {!selectedDir.length && <AccessSettingInput control={control} name="directories.meta.show_directory" />}

      <StickyContainer style={{ top: 0, fontSize: 24 }} isSticky>
        <div style={{ display: "flex" }}>
          <Breadcrumb
            onClick={() => setSelectedDir([])}
            style={{ color: activeDir ? colors.grey30 : "#000" }}
            shouldHover={activeDir}
            is_active={!activeDir}
          >
            Directory
          </Breadcrumb>
          {selectedDir &&
            selectedDir?.map((item, idx) => (
              <Breadcrumb
                key={`${item.name}${idx}`}
                onClick={() => item.name !== activeDir?.name && onDirSelect(item)}
                is_active={item.name === activeDir?.name}
                shouldHover={item.name !== activeDir?.name}
              >
                <ChevronRightIcon style={{ marginTop: 3 }} /> {item.name}
              </Breadcrumb>
            ))}
        </div>
        <Menu id="addMenu" anchorEl={openMenu?.target} open={openMenu?.target?.id === "add-button"} onClose={toggleMenu}>
          <MenuItem
            onClick={() => {
              setIsCreate(true);
              setIsModalOpen(true);
              setIsList(true);
              setOpenMenu(false);
              toggleMenu();
            }}
          >
            Sub-directory
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(true);
              setIsCreate(true);
              setOpenMenu(false);
              toggleMenu();
            }}
          >
            Details
          </MenuItem>
        </Menu>
        <OutlinedButton text="Add" startIcon={<AddIcon />} id="add-button" onClick={toggleMenu} />
      </StickyContainer>

      <div style={{ height: 20 }} />

      {selectedDir.length > 0 && (
        <DirBlock>
          <div style={{ display: "flex", alignItems: "center", gap: 15, fontWeight: 700, fontSize: 22 }}>
            {activeDir?.name} <OutlinedButton startIcon={<EditIcon />} type="grey" onClick={() => setIsModalOpen(true)} />
          </div>
          {activeDir?.content && (
            <div style={{ marginTop: 25 }}>
              <span style={{ color: colors.grey70 }}>Description:</span> {activeDir?.content}
            </div>
          )}
        </DirBlock>
      )}

      {showList ? (
        <DnD
          list={activeDir ? activeDir?.children : directories}
          element={
            <Item
              directories={directories}
              replace={replace}
              update={update}
              remove={remove}
              onDirSelect={onDirSelect}
              onCustomUpdate={item => onDirUpdate(item, true)}
              activeDir={activeDir}
              onDelete={onDelete}
            />
          }
          contentStyle={{ padding: 0 }}
          onDragEnd={onDragEnd}
        />
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding directories`}</NoResultsMessage>
      )}
    </Wrapper>
  );
};

export default Directory;
