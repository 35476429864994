import React from "react";
import { Marker, Polyline } from "@react-google-maps/api";
import mapMarkerSvg from "../../../../assets/images/map-marker-v.svg";

const colors = [
  "#000000",
  "#003688",
  "#E32017",
  "#FFD300",
  "#00782A",
  "#F3A9BB",
  "#B36305",
  "#A0A5A9",
  "#9B0056",
  "#0098D4",
  "#95CDBA",
  "#00A4A7",
  "#EE7C0E",
  "#84B817",
  "#E21836",
  "#7156A5",
];

const options = {
  strokeOpacity: 0.8,
  strokeWeight: 3,
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const CustomMarker = ({ markerIcon, clusterer, onClick, item, count, noClustererRedraw }) => {
  const { longitude: lng, latitude: lat, icon: customIcon, meta, id } = item;
  const position = new window.google.maps.LatLng(lat, lng);
  const icon = customIcon?.url || markerIcon || mapMarkerSvg;
  const waypoints = meta?.waypoints?.map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }));
  return (
    <>
      <Marker
        position={position}
        icon={{ url: icon, scaledSize: new window.google.maps.Size(25, 25), labelOrigin: new window.google.maps.Point(12.5, 11) }}
        clusterer={clusterer}
        onClick={() => onClick({ item, position })}
        noClustererRedraw={noClustererRedraw}
        label={item.type === "location" ? { text: (item.position + 1).toString(), fontSize: "12px", color: "#FF855C" } : null}
      />
      {waypoints && <Polyline options={{ ...options, strokeColor: colors[colors.length - 1 - (id % colors.length)] }} path={waypoints} />}
    </>
  );
};

export default CustomMarker;
