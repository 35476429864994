import React, { useState } from "react";
import Tabs from "components/_new/Tabs";
import { Wrapper } from "feature/panel/Itinerary/style";
import DnD from "components/_new/DnD_new";
import { useFieldArray } from "react-hook-form";
import { NoResultsMessage } from "components/ui/Messages";
import DocumentsTitle from "feature/panel/Itinerary/components/DocumentsTitle";
import uuidv4 from "uuid";
import Item from "./item";
import TitleRow from "../../../components/TitleRow";

const tabs = {
  travel: {
    label: "Travel documents",
    id: "travel",
    iconVar: "icon_travel_documents",
    labelVar: "travel_documents_label",
    defaultIconId: 282,
  },
  destination: {
    label: "Destination documents",
    id: "destination",
    iconVar: "icon_destination_documents",
    labelVar: "destination_documents_label",
    defaultIconId: 282,
  },
};

const Documents = ({
  form: { control, watch, setValue, clearErrors, formState: { errors }, trigger } = {},
  isDefaultStayLanguage,
  canEdit,
  isWiped,
}) => {
  const [tab, setTab] = useState("travel");
  const currentTab = tabs[tab];

  const iconId = watch(`general.meta.${currentTab.iconVar}`) || currentTab.defaultIconId;
  const label = watch(`general.meta.${currentTab.labelVar}`) || currentTab.label;

  const inputName = "documents.documents";
  const inputNameTab = `${inputName}.${currentTab.id}`;
  const { fields: travel, append: appendTravel, remove: removeTravel, move: moveTravel } = useFieldArray({
    control,
    name: `${inputName}.travel`,
    keyName: "rf_id",
  });
  const { fields: destination, append: appendDestination, remove: removeDestination, move: moveDestination } = useFieldArray({
    control,
    name: `${inputName}.destination`,
    keyName: "rf_id",
  });

  const data = tab === "travel" ? watch(`${inputName}.travel`) : watch(`${inputName}.destination`);

  const hasFile = data?.find(item => item.file);

  const onSubmitModal = ({ label, icon }) => {
    setValue(`general.meta.${currentTab.labelVar}`, label, { shouldDirty: true });
    setValue(`general.meta.${currentTab.iconVar}`, icon, { shouldDirty: true });
  };

  const onAdd = file => {
    if (tab === "travel")
      appendTravel({
        id: uuidv4(),
        name: file ? file.file_name : "",
        file: file ?? null,
        meta: { restricted_to_traveller_internal_ids: null },
      });
    else
      appendDestination({
        id: uuidv4(),
        name: file ? file.file_name : "",
        file: file ?? null,
        meta: { restricted_to_traveller_internal_ids: null },
      });
  };

  const onMultiUpload = files => {
    for (const file of files) {
      onAdd(file);
    }
  };

  const handleDelete = index => {
    if (tab === "travel") removeTravel(index);
    else removeDestination(index);
  };

  const onDragEnd = (list, { source, destination }) => {
    if (tab === "travel") moveTravel(source.index, destination.index);
    else moveDestination(source.index, destination.index);
  };

  return (
    <Wrapper slimTopPadding>
      <Tabs applyExtraTopPadding tabs={tabs} tab={tab} setTab={setTab} />

      <TitleRow isSticky offsetTabs extraTabPadding containerStyles={{ display: "block" }}>
        <DocumentsTitle
          setValue={setValue}
          onSubmitModal={onSubmitModal}
          canEdit={canEdit}
          iconId={iconId}
          modalLabel={label}
          label={label}
          isDefaultStayLanguage={isDefaultStayLanguage}
          onAdd={onAdd}
        />
      </TitleRow>

      <div style={{ height: 20 }} />

      {!data?.length && <NoResultsMessage height="50px">Please click &quot;ADD&quot; to start adding documents</NoResultsMessage>}
      <DnD
        list={data}
        onDragEnd={onDragEnd}
        disabled={!canEdit || isWiped}
        contentStyle={{ padding: 0 }}
        element={
          <Item
            control={control}
            setValue={setValue}
            onDelete={handleDelete}
            dataType={currentTab.id}
            watch={watch}
            inputNameTab={inputNameTab}
            errors={errors}
            disabled={!canEdit || isWiped}
            onMultiUpload={onMultiUpload}
            tab={tab}
            type="trip"
            hasFile={hasFile}
            multiUpload
            trigger={trigger}
            clearErrors={clearErrors}
          />
        }
      />
    </Wrapper>
  );
};

export default Documents;
