import { getMediaPx } from "helpers";
import styled from "styled-components";

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${p => (p.noSearch ? 3 : 4)}, 1fr);
  gap: 15px;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;

export const Bottom = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    max-height: 35vh;
  }

  @media (max-width: ${p => getMediaPx(p, "xs")}px) {
    max-height: 30vh;
  }
`;
