import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";
import styled, { css } from "styled-components";

const fullScreenStyles = css`
  max-width: 100vw !important;
  width: 100vw !important;
  height: ${p => p.height100vh - 60}px;
  margin-top: 60px;
  max-height: none;
`;

const fullScreenStylesOverlay = css`
  align-items: flex-start;
`;

const noFullScreenStyles = css`
  min-width: auto;
  max-width: 90vw;
  width: 90vw;
`;

export const Wrap = styled.div`
  min-width: 800px;
  background-color: #fff;
  padding: 25px 25px 15px 25px;
  border-radius: 8px;
  z-index: 6;
  overflow: hidden;
  position: relative;
  max-height: 80vh;
  display: block;
  @media (max-width: 900px) {
    ${({ mobileFullScreen }) => (mobileFullScreen ? fullScreenStyles : noFullScreenStyles)};
    min-width: 320px;
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding: 15px;
  }
  ${p => p.customCss}
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    // display: grid;
    // grid-template-rows: minmax(30px, auto) 1fr 40px;
    height: ${p => p.height100vh - 60}px;
  }
  @media (max-width: 350px) {
    * {
      zoom: 0.93;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;
  transform: translate3d(0, 0, 0);
  opacity: 0.99;
  @media (max-width: 900px) {
    ${({ mobileFullScreen }) => mobileFullScreen && fullScreenStylesOverlay};
  }
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  /* margin-top: 15px; */
`;

export const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Content = styled.div`
  overflow: hidden;
  max-height: 65vh;
  -ms-overflow-style: none;
  /* scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  } */
  ${p => p.contentStyle}
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    max-height: none;
  }
`;

export const BottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: 0;
  }
`;
