import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import uuidv4 from "uuid";
import PropTypes from "prop-types";

import Tabs from "components/_new/Tabs";
import { Wrapper } from "feature/panel/Itinerary/style";
// eslint-disable-next-line import/no-named-as-default
import NotificationModal from "./NotificationModal";
import { compareNotifications, getDefaultNotificationProperties, noneItem, populateNullableNotifications } from "./helpers";
import { extractCoordinates } from "../../../../../../utils";
import NotificationTable from "./NotificationTable";
import { HttpClient } from "../../../../../../services/application/httpClient/httpClient";
import { getAllNotificationsFail, getAllNotificationsSuccess } from "../../../../../../store/notifications/actions";
import { transformNotificationTemplateData } from "../../../../../../domain/Trip";
import { LoadingScreen } from "../../../../../../components/ui/LoadingScreen/LoadingScreen";

const tabs = {
  timed: {
    label: "Timed notifications",
    id: "timed",
  },
  gps: {
    label: "GPS notifications",
    id: "gps",
  },
};

const Notifications = ({ form: { watch, control, setValue }, type = "trip" }) => {
  const [tab, setTab] = useState("timed");
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [notificationToModify, setNotificationToModify] = useState({});
  const [clickedRow, setClickedRow] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { timedNotificationsTemplates, gpsNotificationsTemplates } = useSelector(state => state.notifications);
  const {
    general: { departure_date, return_date, language, type: itineraryType } = {},
    locations: { locations } = { locations: [] },
  } = watch();

  const { fields: tripNotifications, update, prepend, remove } = useFieldArray({
    control,
    name: "notifications.notifications",
    keyName: "trip_notif",
  });

  const { isLoading: isNotificationTemplatesLoading } = useQuery({
    queryKey: [`notificationTemplates`],
    queryFn: () => HttpClient.get(`/notification/list`),
    refetchOnMount: true,
    select: res => transformNotificationTemplateData(res?.data, language),
    onSuccess: data => {
      dispatch(getAllNotificationsSuccess(data));
      const updatedNotifications = populateNullableNotifications(data, tripNotifications, language);
      setValue("notifications.notifications", updatedNotifications);
    },
    onError: e => getAllNotificationsFail(e),
  });

  const onClose = () => {
    setShowModal(false);
    setNotificationToModify({});
  };

  const updateNotifications = notification => {
    if (notification?.template_id === notification.id) {
      notification.id = uuidv4();
      notification.use_global = true;
      delete notification.isOverridden;
      delete notification.localisation;

      // compare notifications, when user only toggles is_active status in modal
      if (notification.isCopyMode) {
        const sharedNotificationsByType = tab === "timed" ? timedNotificationsTemplates : gpsNotificationsTemplates;
        const sharedNotification = sharedNotificationsByType?.find(
          sharedNotification => sharedNotification.id === notification.template_id,
        );

        notification.use_global = compareNotifications(notification, sharedNotification);
        delete notification.isCopyMode;
      }

      prepend(notification);
    } else {
      const index = tripNotifications.findIndex(tn => tn.id === notification.id);
      if (index === -1) {
        prepend(notification);
      } else {
        let notificationsEqual = true;
        let deleteTripNotification = false;
        if (notification.template_id) {
          const sharedNotificationsByType = tab === "timed" ? timedNotificationsTemplates : gpsNotificationsTemplates;
          const sharedNotification = sharedNotificationsByType?.find(
            sharedNotification => sharedNotification.id === notification.template_id,
          );

          if (sharedNotification) {
            const notificationsEqualResult = compareNotifications(notification, sharedNotification);

            notificationsEqual = notificationsEqualResult;
            if (notificationsEqual && sharedNotification.is_active === notification.is_active) {
              deleteTripNotification = true;
            }
          } else {
            notificationsEqual = false;
          }
        } else {
          notificationsEqual = false;
        }
        notification.use_global = notificationsEqual;

        if (deleteTripNotification) {
          remove(index);
        } else {
          update(index, notification);
        }
      }
    }
  };

  const handleCreateNotification = notification => {
    prepend(notification);
    onClose();
  };

  const handleEditNotification = notification => {
    if (notification.type === "gps" && notification?.coordinates) {
      const { latitude, longitude } = extractCoordinates(notification.coordinates);
      notification.latitude = latitude;
      notification.longitude = longitude;
      delete notification.coordinates;
    }

    updateNotifications(notification);
    onClose();
  };

  const onCreate = () => {
    setEditMode(false);
    setShowModal(!showModal);
    setNotificationToModify({
      id: uuidv4(),
      content: "",
      url: "",
      type: tab,
      template_id: null,
      use_global: false,
      ...getDefaultNotificationProperties(departure_date)[tab],
    });
  };

  const onRowClick = (rowId, isMobile = false) => {
    const animatedRow = document.querySelector(`#row-${rowId}${isMobile ? "-mob" : ""}`);
    if (animatedRow) {
      animatedRow?.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
    setClickedRow(rowId);
  };

  const notificationTablesRender = () => {
    const configurations = [{ isGlobal: false }, { isGlobal: true }];

    return (
      <>
        {configurations.map((config, index) => (
          <NotificationTable
            key={index}
            isGlobal={config.isGlobal}
            tripNotifications={tripNotifications}
            sharedNotifications={tab === "timed" ? timedNotificationsTemplates : gpsNotificationsTemplates}
            updateNotifications={updateNotifications}
            setShowModal={setShowModal}
            setEditMode={setEditMode}
            setNotificationToModify={setNotificationToModify}
            remove={remove}
            showModal={showModal}
            departure_date={departure_date}
            return_date={return_date}
            locations={locations}
            tab={tab}
            ref={ref}
            clickedRow={clickedRow}
            setClickedRow={setClickedRow}
            onRowClick={onRowClick}
            onCreate={onCreate}
            itineraryType={itineraryType}
            type={type}
          />
        ))}
      </>
    );
  };

  return (
    <Wrapper slimTopPadding>
      <Tabs applyExtraTopPadding tabs={tabs} tab={tab} setTab={setTab} />
      {isNotificationTemplatesLoading ? <LoadingScreen /> : notificationTablesRender()}
      {showModal && (
        <NotificationModal
          type={tab}
          onClose={onClose}
          modalTitle={`${editMode ? "Edit" : "New"} ${tab === "gps" ? tab.toUpperCase() : tab} notification`}
          confirmLabel={editMode ? "Update" : "Add"}
          onNotificationAdd={editMode ? handleEditNotification : handleCreateNotification}
          notification={notificationToModify}
          editMode={editMode}
          locations={[
            noneItem,
            ...locations.map(location => ({
              value: location.id,
              label: location.name,
              latitude: location.latitude,
              longitude: location.longitude,
            })),
          ]}
          displayMode="trip"
          setNotificationToModify={setNotificationToModify}
        />
      )}
    </Wrapper>
  );
};

Notifications.propTypes = {
  form: PropTypes.shape({
    watch: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default Notifications;
