import React from "react";
import Switch from "components/_new/Switch";
import Input from "components/ui/Inputs/TextInput";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import { Wrapper } from "feature/panel/Itinerary/style";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Table from "components/_new/Table";
import moment from "moment";
import config from "config/app";
import { DndRequestsTableAction } from "feature/panel/Stays/_shared/DoNotDisturb/DndRequestsTableAction";
import { HttpClient } from "services/application/httpClient/httpClient";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { NoResultsMessage } from "components/ui/Messages";
import { colors } from "config/theme/colors";
import MobileItem from "./mobileItem";

const columns = ({ onAccept, canEditItinerary }) => [
  {
    id: "profile.room_number",
    label: "Room",
    align: "left",
    renderCell: row => row.profile.room_number,
  },
  {
    id: "profile.booking_name",
    label: "Guest name",
    align: "left",
    renderCell: row => row.profile.booking_name,
  },

  {
    id: "date_sent",
    label: "Date sent",
    align: "left",
    renderCell: row => moment(row.requested_at).format(config.dateFormat),
  },
  {
    id: "time_sent",
    label: "Time sent",
    align: "left",
    renderCell: row => moment(row.requested_at).format(config.timeFormat),
  },
  {
    id: "dnd_end_time",
    label: "DND end time",
    align: "left",
    renderCell: row => moment(row.ends_at).format(config.timeFormat),
  },
  {
    id: "action",
    renderCell: row => <DndRequestsTableAction item={row} onAccept={onAccept} canEdit={canEditItinerary} />,
    isAction: true,
  },
];

const DND = ({ form: { control, watch } }) => {
  const { operator_code, reference_code } = useParams();
  const vamoosId = watch("general.vamoos_id");

  const permissionsService = usePermissionsService();

  const canEditItinerary = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const { data: { items = [] } = {}, refetch } = useQuery({
    queryKey: [`/itinerary/${operator_code}/${reference_code}/dnd?&count=25&page=1`],
  });
  const { mutate } = useMutation(
    id => {
      return HttpClient.post(`/itinerary/${operator_code}/${reference_code}/dnd/${id}`, { approved: true });
    },
    {
      onSuccess: () => refetch(),
    },
  );

  return (
    <Wrapper>
      <ItineraryTitle style={{ paddingLeft: 0 }}>
        Do not disturb <Switch control={control} name="general.meta.show_dnd" />
      </ItineraryTitle>
      <Input control={control} name="general.meta.dnd_contact_email" label="Contact email" style={{ marginBottom: 15 }} />
      <DateTimeInput
        control={control}
        name="general.meta.dnd_cut_off_time"
        type="time"
        label="Cut off time"
        format="HH:mm"
        defaultValue={moment({ hour: 11, minute: 0 })}
      />
      <div style={{ marginTop: 15 }}>
        {items.length > 0 ? (
          <Table
            rows={items}
            columns={columns({ onAccept: id => mutate(id), canEditItinerary })}
            renderRowMobile={<MobileItem canEditItinerary={canEditItinerary} onAccept={id => mutate(id)} />}
          />
        ) : (
          <NoResultsMessage style={{ height: 70, marginTop: 35, borderTop: `1px solid ${colors.grey10}` }}>
            There are currently no DND requests{" "}
          </NoResultsMessage>
        )}
      </div>
    </Wrapper>
  );
};

export default DND;
