import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { Tooltip, makeStyles } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { GroupOutlined } from "@material-ui/icons";
import Badge from "@mui/material/Badge";

import { BodyText } from "components/ui/Typography/Typography";
import { ActionButton } from "components/ui/Buttons";
import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { BackgroundPreview } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/BackgroundPreview";
import { EntryDetailsForm } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDetailsForm";
import { EntryDocuments } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDocuments";
import config from "config/app";
import { GLOBAL_CONTENT, INDIVIDUAL_SETTINGS, TABLE_ACTIONS_TITLES, TRIP_WIZARD_CONTENT } from "constants/content";

import { AddToLibraryIcon } from "components/ui/Icons/AddToLibraryIcon";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { TravellersRestrictionModal } from "components/_shared/TravellersRestrictionModal/TravellersRestrictionModal";
import { PanelTemplateContext } from "components/templates/Panel/PanelTemplateContext";
import { Message } from "components/ui/Messages";

import { getRestrictionInfo } from "../../../../../../../helpers";
import { EntryFormProvider } from "./EntryFormContext";
import { ImsertGrid } from "./ImsertGrid";

const CustomBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
    font-size: ${({ badgesize }) => badgesize};
    ${({ textcolor }) =>
      textcolor &&
      css`
        color: ${textcolor};
      `}
  }
`;

const StyledWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  padding: ${({ theme }) => theme.setSpacing(6)}px 0;
  height: auto;
`;

const EntryActionsContainer = styled.div`
  margin: 0 0 0 auto;
  display: flex;
`;

const EntryActionsItem = styled.div`
  margin: 0 0 0 24px;
`;

const useStyles = makeStyles(theme => ({
  imgGrid: {
    [theme.breakpoints.up("lg")]: {
      flexGrow: 0,
      flexShrink: 1,
    },
  },
  detailsGrid: {
    [theme.breakpoints.up("lg")]: {
      flexGrow: 1,
      flexShrink: 0,
    },
  },
}));

const EntryForm = ({ item, index, onAddToLibrary, isNew, scrollConfirm, disabled }) => {
  const { currentOperator } = useSelector(state => state.operator);
  const imsertIntegration = currentOperator?.integrations?.imsert;

  const [travellerRestrictionModal, setTravellerRestrictionModal] = useState({ open: false, body: {} });

  const { position, meta, image } = item;
  const formContanerRef = useRef(null);
  const { removeDay, show_days, updateDayData } = useContext(EntriesContext);
  const { scrollToElement } = useContext(PanelTemplateContext);
  const { departure_date, locations, errors, travelPeople, storyboard } = useManageTripContext();
  const { details } = errors;
  const classes = useStyles();

  const [showPreventModal, setShowPreventModal] = useState(false);
  const [warningModal, setWarningModal] = useState(null);

  const permissionsService = usePermissionsService();

  const canAddToLibrary = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.library);
  const enableImsert = !!imsertIntegration?.api_key;

  const date = moment(departure_date)
    .add(meta?.day_number - 1, "d")
    .format(config.storyboardDateFormat);

  const handleDeleteDay = () => removeDay(item);

  const handleScrollToNewElement = () => {
    if (isNew && formContanerRef.current?.offsetTop) {
      setTimeout(() => {
        scrollToElement(formContanerRef.current);
        scrollConfirm();
      }, 0);
    }
  };

  const handleAddToLibrary = itemToBeSaved => {
    if (itemToBeSaved.image && !itemToBeSaved.image.file_id) {
      setShowPreventModal(true);
    } else {
      onAddToLibrary(itemToBeSaved);
    }
  };

  useEffect(handleScrollToNewElement, [isNew]);

  const handleTravellersRestrictionToggle = storyboardItem => {
    if (errors?.travellers?.validationErrors) {
      setWarningModal("applyWarning");
      return;
    }

    const { hasRestrictions, mergeTravelPeople } = getRestrictionInfo(travelPeople, errors, item, storyboard);
    const showWarningModal = mergeTravelPeople?.length < 2 && !hasRestrictions;
    if (showWarningModal) {
      setWarningModal("enableWarning");
      return;
    }

    const { open } = travellerRestrictionModal;
    const body = open ? travellerRestrictionModal.body : storyboardItem;
    setTravellerRestrictionModal({ body, open: !open });
  };

  const renderTravellersRestrictionAction = () => {
    const { personalisedTravellerIds } = getRestrictionInfo(travelPeople, errors, item, storyboard);

    return currentOperator?.meta?.show_personalisation !== false ? (
      <Tooltip
        placement="bottom"
        title={
          item.restricted_to_traveller_internal_ids === null ? TABLE_ACTIONS_TITLES.addRestrictions : TABLE_ACTIONS_TITLES.editRestrictions
        }
      >
        <EntryActionsItem>
          <CustomBadge badgeContent={personalisedTravellerIds?.length ?? null} badgesize="12px" textcolor="white" showZero>
            <ActionButton size="small" iconButton onClick={() => handleTravellersRestrictionToggle(item)} style={{ padding: "0 12px" }}>
              <GroupOutlined />
            </ActionButton>
          </CustomBadge>
        </EntryActionsItem>
      </Tooltip>
    ) : null;
  };

  return (
    <div ref={formContanerRef}>
      <StyledWrapper>
        <Grid container spacing={10}>
          <Grid item xs={12} container alignItems="center">
            <BodyText>
              <span>
                <strong>{show_days ? `${TRIP_WIZARD_CONTENT.storyboard.day}` : "#"}</strong>
              </span>
              <span>
                <strong>{show_days ? `${meta?.day_number ? ` ${meta?.day_number}` : ""}` : position}</strong>
                {show_days && (
                  <>
                    <strong>:</strong>
                    <strong> </strong>
                    <span>{show_days && meta?.day_number && date}</span>
                  </>
                )}
              </span>
            </BodyText>
            <EntryActionsContainer>
              {renderTravellersRestrictionAction()}
              <TravellersRestrictionModal
                open={travellerRestrictionModal.open}
                data={travellerRestrictionModal.body}
                onClose={handleTravellersRestrictionToggle}
                onToggle={updatedData =>
                  setTravellerRestrictionModal(prevState => ({
                    open: prevState.open,
                    body: updatedData,
                  }))
                }
                name="storyboard"
              />
              <ConfirmationModal
                open={warningModal}
                title={INDIVIDUAL_SETTINGS.title}
                description={
                  <>
                    <Message
                      text={warningModal === "applyWarning" ? INDIVIDUAL_SETTINGS.applyWarningDesc : INDIVIDUAL_SETTINGS.enableWarningDesc}
                      type="info"
                    />
                  </>
                }
                confirmLabel="OK"
                onConfirm={() => setWarningModal(null)}
              />
              {canAddToLibrary && (
                <>
                  <Tooltip placement="bottom" title={TRIP_WIZARD_CONTENT.storyboard.addToLibrary}>
                    <EntryActionsItem>
                      <ActionButton size="small" onClick={() => handleAddToLibrary(item)}>
                        <AddToLibraryIcon />
                      </ActionButton>
                    </EntryActionsItem>
                  </Tooltip>
                  {showPreventModal && (
                    <ConfirmationModal
                      open={showPreventModal}
                      title={TRIP_WIZARD_CONTENT.storyboard.youHaveToSaveTrip}
                      cancelLabel={null}
                      confirmLabel={GLOBAL_CONTENT.back}
                      onConfirm={() => setShowPreventModal(false)}
                    />
                  )}
                </>
              )}
              {!disabled && (
                <EntryActionsItem>
                  <ActionButton size="small" onClick={handleDeleteDay}>
                    <DeleteOutlineIcon />
                    {GLOBAL_CONTENT.delete}
                  </ActionButton>
                </EntryActionsItem>
              )}
            </EntryActionsContainer>
          </Grid>
          <EntryFormProvider
            initialValue={{
              headline: item?.headline ?? "",
              content: item.content ?? "",
              location: item.location?.latitude && item.location?.longitude ? item.location : {},
              id: item.id,
            }}
          >
            {enableImsert ? (
              <Grid item lg md={4} sm={6} xs={12} className={classes.imgGrid}>
                <ImsertGrid
                  image={image}
                  onUpdateImage={newImage => {
                    let imageData = newImage;
                    if (typeof newImage === "string") {
                      // Selected a recommendation from imsert
                      imageData = {
                        file_name: new URL(newImage).pathname.split("/").pop(),
                        file_url: newImage,
                        preview_url: newImage,
                        is_library_file: false,
                        type: "image/jpeg",
                      };
                    }

                    const newItem = { ...item, image: imageData };
                    updateDayData(newItem);
                  }}
                />
              </Grid>
            ) : (
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <BackgroundPreview image={image} />
              </Grid>
            )}

            <Grid item lg sm={12} className={classes.detailsGrid}>
              <EntryDetailsForm disabled={disabled} item={item} index={index} locations={locations} />
              <EntryDocuments disabled={disabled} item={item} errors={details && details[index] ? details[index] : {}} />
            </Grid>
          </EntryFormProvider>
        </Grid>
      </StyledWrapper>
    </div>
  );
};

EntryForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    restricted_to_traveller_internal_ids: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf(null)]),
    position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    image: PropTypes.shape(),
    headline: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    content: PropTypes.string.isRequired,
    meta: PropTypes.shape(),
  }).isRequired,
  isNew: PropTypes.bool.isRequired,
  scrollConfirm: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onAddToLibrary: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { EntryForm, StyledWrapper };
