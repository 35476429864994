import styled from "styled-components";

export const Wrap = styled.div`
  min-width: 800px;
  padding: 20px 0 0 0;
  @media (max-width: 900px) {
    min-width: auto;
  }
`;

export const NavLine = styled.div`
  /* width: calc(100% + 50px); */
  height: 36px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin-left: -25px;
  /* margin-top: 20px; */
`;

export const FolderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px 0 5px 0;
  overflow: auto;
  max-width: 100%;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    // padding: 15px 5px 5px 0;
  }
`;

export const FilesWrap = styled.div`
  /* max-height: 400px; */
  overflow: auto;
  overflow-x: hidden;
  /* @media (max-width: 600px) {
    max-height: none;
    max-height: 400px;
  } */
`;
