import React, { useState } from "react";
import { useSelector } from "react-redux";
import angleIcon from "../../../../../assets/icons/angle-right.svg";
import Checkbox from "../../../../../components/_new/Checkbox";
import { Link } from "../../../../../components/ui/Links/Links";
import {
  Header,
  HeaderContainer,
  Item, KeyText,
  Placeholder,
  SecondaryText,
  StyledContainer,
  Title, ValueText
} from "./style";

const MobileItem = ({ item, columns, selectedItems, checked, setChecked, checkboxSelection }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { is_active: isActive } = item;

  const toggleCheck = id => {
    if (checked.find(item => item === id)) setChecked(checked.filter(item => item !== id));
    else setChecked([...checked, id]);
  };

  const { currentOperator } = useSelector(state => state.operator);
  const showRefactor = (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ?? process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true";
  const redirectUrl = showRefactor ? `/panel/itinerary/${item.type}/edit/${item.operator_code}/${item.reference_code}/general` :
    (item.type === "stay" ? `/panel/${item.type}s/${item.operator_code}/edit` : `/panel/${item.type}s/${item.operator_code}/${item.reference_code}/edit`);


  return (
    <StyledContainer archived={(!isActive).toString()}>
      <div style={{ display: "flex", marginLeft: "-10px", gap: "5px" }}>
        {checkboxSelection ? (
          <Checkbox
            key={item?.key}
            checked={selectedItems.allItemsSelected || selectedItems?.vamoosIds.includes(item.vamoos_id)}
            handleChange={e => toggleCheck(item.vamoos_id, e)}
            label=""
            wrapStyle={{ margin: 0, padding: 0 }}
          />
        ) : (
          <Placeholder />
        )}
        <HeaderContainer>
          <div style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
            <Link
              to={redirectUrl}
              cv="grey100"
              style={{minWidth: 0, flexGrow: 1}}
              linkFor="table"
              noDecoration
            >
              <Header archived={(!isActive).toString()}>
                <Title> {item.field1}</Title>
                <SecondaryText>{`${item.operator_code} - ${item.reference_code}`}</SecondaryText>
              </Header>
            </Link>
            <div onClick={() => setIsOpened(!isOpened)}>
              <img
                src={angleIcon}
                style={{ marginRight: 15, marginLeft: 15, transform: `rotate(${isOpened ? 90 : 0}deg)` }}
                alt="angle-icon"
                onClick={() => setIsOpened(!isOpened)}
              />
            </div>
          </div>
        </HeaderContainer>
      </div>
      {isOpened && (
        <Link
          to={redirectUrl}
          style={{minWidth: 0, flexGrow: 1}}
          cv="grey100"
          linkFor="table"
          noDecoration
        >
          <div style={{ marginTop: 15 }}>
            {columns.map(column => {
              const value = item[column.id];
              if(!value && value !== 0) return null;
              return column?.isAction ? null : (
                <Item key={column.id} archived={(!isActive).toString()}>
                  <KeyText>{`${column.label}:`}</KeyText>
                  <ValueText>{(column.renderCell) ? column.renderCell(item) : value}</ValueText>
                </Item>
              );
            })}
          </div>
        </Link>
      )}
    </StyledContainer>
  );
};

export default MobileItem;