import momentTimezone from "moment-timezone";
import uuidv4 from "uuid";
import { isEmpty, rest } from "lodash";

import config from "config/app";

import { ERRORS, NOTIFICATION_TYPES } from "constants/content";
import { emailValidate, isValidCoordinatePart, isValidDate } from "utils/validation";
import { formFilePayloadObject } from "utils/library";
import { isString } from "contracts/types";

import { PoiRepository } from "repositories/PoiRepository";
import { validateLocationItem } from "./validators/sharedValidators";

const HTTP_PROTOCOL_PATTERN = /:\/\//;

export const getFileNameFromTheLink = link =>
  link && typeof link === "string"
    ? link
        .split("/")
        .filter(linkPart => !!linkPart)
        .pop()
    : "";

export const convertToFileObject = (object, includeAlias = false, restrictionsEnabled = false, refactoredDocs = false) => {
  const { id, name, file, alias_for_id, remote_url, meta } = object || {};
  const { https_url, s3_url, short_name, id: file_id, meta: fileMeta, versions, mime_type, variants } = file || {};
  const { restricted_to_traveller_ids } = meta || {};

  const library_node_id = file?.library_node_id;
  const thumb_https_url = variants?.thumb?.https_url;

  if (id) {
    return {
      id,
      file_id,
      library_node_id: library_node_id || id,
      name: name || "",
      file_name: short_name || getFileNameFromTheLink(s3_url || remote_url) || name,
      https_url: https_url || remote_url || "",
      thumb_https_url,
      web_url: remote_url,
      s3_url: s3_url || "",
      file_meta: fileMeta,
      alias_for_id: includeAlias ? alias_for_id : undefined,
      mime_type,
      isConverted: true,
      ...(restrictionsEnabled && !refactoredDocs && { restricted_to_traveller_internal_ids: restricted_to_traveller_ids ?? null }),
    };
  }
  return null;
};

const handleInvalidDatePassed = time => !isValidDate(time) && ERRORS.invalidDate;

const convertDateToTimezone = (time, timezone = momentTimezone.tz.guess()) => {
  return handleInvalidDatePassed(time) || momentTimezone(time).tz(timezone);
};

export const convertToTimeFormat = (time, timezone) =>
  handleInvalidDatePassed(time) || convertDateToTimezone(time, timezone).format(`${config.timeFormat} z`);

export const convertToDateTimeFormat = (time, timezone, showTimeZone = false) =>
  handleInvalidDatePassed(time) ||
  convertDateToTimezone(time, timezone).format(`${config.dateFormat} ${config.timeFormat}${showTimeZone ? " z" : ""}`);

export const prepareDataToCopyingTrip = (sections, dataToTransfer, initialState) => {
  if (Array.isArray(sections)) {
    const {
      field1,
      field2,
      field3,
      field4,
      background,
      client_reference,
      timezone,
      start_time,
      return_date,
      departure_date,
      show_journal,
      show_poi_list,
      pois,
      meta,
      logo,
      language,
      notifications,
    } = dataToTransfer;

    const isCopyIncludesTravelPeople = sections.includes("travelPeople");
    const copiedData = sections.reduce((acc, el) => {
      if (el === "notifications") {
        return { ...acc, notifications: dataToTransfer.notifications?.map(item => ({ ...item, tag: null })) };
      }
      if (el === "storyboard" || el === "storyboardDocuments") {
        const copyStoryboard = dataToTransfer.storyboard.map(day => ({
          ...day,
          documents: el === "storyboard" ? [] : day.documents,
          restricted_to_traveller_internal_ids: isCopyIncludesTravelPeople ? day.restricted_to_traveller_internal_ids : null,
        }));
        return { ...acc, storyboard: copyStoryboard };
      }

      if (el === "inspiration") {
        const { inspiration_content, inspiration_number_of_days, inspiration_start_day } = dataToTransfer;
        return { ...acc, [el]: dataToTransfer[el], inspiration_content, inspiration_number_of_days, inspiration_start_day };
      }
      if (el === "flights" || el === "travelDocuments" || el === "destinationDocuments") {
        const key = el === "flights" ? "listOfFlights" : el === "travelDocuments" ? "travelDocuments" : "destinationDocuments";
        const copy = dataToTransfer[key].map(item => ({
          ...item,
          restricted_to_traveller_internal_ids: isCopyIncludesTravelPeople ? item.restricted_to_traveller_internal_ids : null,
        }));
        return { ...acc, [key]: copy };
      }
      return { ...acc, [el]: dataToTransfer[el] };
    }, {});

    return {
      ...initialState,
      ...copiedData,
      language,
      field1,
      field2,
      field3,
      field4,
      background,
      client_reference,
      timezone,
      start_time,
      return_date,
      departure_date,
      show_journal,
      show_poi_list,
      pois,
      logo,
      meta: {
        ...initialState.meta,
        ...meta,
      },
    };
  }
  return initialState;
};

export const documentPayloadConvert = (document, copyToOtherOperator = false, isPublic = false) =>
  formFilePayloadObject({ ...document, file_name: document.name }, copyToOtherOperator, isPublic);

export const formDirectoriesListToDisplay = directories => {
  return directories.map(dir => ({
    ...dir,
    background: dir.background && dir.background.name ? convertToFileObject(dir.background, true) : undefined,
    video: dir.video && dir.video.name ? convertToFileObject(dir.video, true) : undefined,
    actions: dir.actions.map((action, index) => ({
      ...convertToFileObject(action, true),
      icon_id: action.icon_id,
      orderNumber: index + 1,
    })),
    children: formDirectoriesListToDisplay(dir.children),
  }));
};

export const transformUrl = (url, isSecured) => (HTTP_PROTOCOL_PATTERN.test(url) ? url : `http${isSecured ? "s" : ""}://${url}`);

export const formNotificationTemplatePayload = ({ url, ...rest }) => {
  return { ...rest, url: !url ? null : transformUrl(url) };
};

const filterInspirationNotificationWithoutInspirationId = ({ type, inspiration_vamoos_id, delivery_at_relative_to }) =>
  type !== NOTIFICATION_TYPES.inspiration || (inspiration_vamoos_id && delivery_at_relative_to);

export const formNavigationsListToPayload = (notifications, copyToOtherOperator = false) => {
  const arr = notifications
    .filter(filterInspirationNotificationWithoutInspirationId)
    .map(
      ({
        id,
        operator_id,
        created_at,
        updated_at,
        itinerary_id,
        inspiration_id,
        coordinates,
        location_internal_id,
        location,
        location_id,
        url,
        latitude,
        longitude,
        delivery_at,
        start_at,
        trip_notif,
        ...rest
      }) => {
        if (rest.use_global) {
          const fieldsToOverride = rest.type === "timed"
            ? ["content", "url", "delivery_at_days", "delivery_at_relative_to", "start_at"]
            : ["content", "url", "start_at", "end_at", "latitude", "longitude"];

          const item = { ...rest };
          fieldsToOverride.forEach(key => item[key] = null);
          return item;
        }

        const latitudeOrLongitudeExist = latitude || longitude;
        const locationId = (() => {
          if (latitudeOrLongitudeExist) return undefined;
          return location_id || location ? location.id : undefined;
        })();

        const item = {
          ...rest,
          start_at,
          url: url || null,
          latitude: latitude || undefined,
          longitude: longitude || undefined,
          location_internal_id: latitudeOrLongitudeExist || !location_internal_id ? undefined : location_internal_id,
          location_id: locationId,
        };

        if (copyToOtherOperator) {
          delete item.template_id;
          delete item.inspiration_vamoos_id;
        }

        return item;
      },
    );
  return arr;
};

export const fillEmptyNotificationsWithTemplateData = (customNotifications, notificationTemplates, language) =>
  customNotifications.map(notification => {
    if (notification.content && notification.url) {
      return notification;
    }
    const currentTemplate = notificationTemplates.find(({ id }) => notification.template_id === id);
    if (!currentTemplate) {
      return notification;
    }
    return {
      ...notification,
      content: notification.content || currentTemplate.content,
      url: notification.url ? transformUrl(notification.url) : currentTemplate.url,
    };
  });

export const setLocationsFilter = locations => {
  if (!Array.isArray(locations) || !locations.length) return "";

  return locations
    .filter(location => location.latitude <= 90 && location.latitude > -90 && location.longitude <= 180 && location.longitude > -180)
    .reduce((acc, location) => {
      if (isValidCoordinatePart(location.longitude) && isValidCoordinatePart(location.latitude)) {
        return `${acc}&location=${encodeURIComponent(`${location.latitude},${location.longitude}`)}`;
      }
      return acc;
    }, "");
};

export const getListOfPoisObjects = async (alreadyAssignedPois, locations) => {
  const poiRepository = new PoiRepository();

  // if poi already existed it has a structure of { id, is_on }
  // if poi doesn't appear on the list it means it has been added after last update of vamoos.
  // if the user doesn't touch newly created poi it has a default status of being active assigned
  const validLocations = locations.filter(location => isEmpty(validateLocationItem(location)));
  const allAvailablePois = await poiRepository.getPoisByLocations(validLocations);

  return alreadyAssignedPois.map(({ isOn: is_on, isDefaultOn: is_default_on, ...rest }) => ({ ...rest, is_on, is_default_on }));
  // return allAvailablePois.map(poi => {
  //   const matchingPoi = alreadyAssignedPois.find(({ id }) => poi.id === id);
  //   return {
  //     id: poi.id,
  //     is_on: matchingPoi ? matchingPoi.is_on : poi.is_default_on,
  //   };
  // });
};

export const preUpdateTripPayload = async (state, notificationTemplates) => {
  // fill untouched notifications and verify pois against locations before forming a payload
  const notifications = fillEmptyNotificationsWithTemplateData(state.notifications, notificationTemplates, state.language);

  const pois = await getListOfPoisObjects(state.pois, state.locations);

  return { ...state, notifications, pois };
};

export const createNewMessagingEmail = (overrides = {}) => ({
  id: uuidv4(),
  email: "",
  before_after: true,
  during: true,
  ...overrides,
});

export const formMessagingEmailsListPayload = messagingEmailsList => {
  if (!Array.isArray(messagingEmailsList))
    return {
      messaging_email_during: "",
      messaging_email_before_after: "",
    };

  const validEmailCheck = ({ email }) => emailValidate(email);
  const filterActiveDuring = ({ during }) => during;
  const filterActiveBeforeAndAfter = ({ before_after }) => before_after;
  const validEmailsList = messagingEmailsList.filter(validEmailCheck);

  return {
    messaging_email_during: validEmailsList
      .filter(filterActiveDuring)
      .map(({ email }) => email)
      .join(","),
    messaging_email_before_after: validEmailsList
      .filter(filterActiveBeforeAndAfter)
      .map(({ email }) => email)
      .join(","),
  };
};

export const combineMessagingEmailsList = (messaging_email_during, messaging_email_before_after) => {
  if (!isString(messaging_email_during) || !isString(messaging_email_before_after)) return [];

  const listOfDuringEmailsFromString = messaging_email_during.split(",").filter(email => email);
  const listOfBeforeAndAfterEmailsFromString = messaging_email_before_after.split(",").filter(email => email);
  const messaging_emails_list = listOfDuringEmailsFromString.map(email => createNewMessagingEmail({ email, before_after: false }));

  listOfBeforeAndAfterEmailsFromString.forEach(email => {
    const sameEmailFomDuringList = messaging_emails_list.find(emailObject => emailObject.email === email);
    if (sameEmailFomDuringList) sameEmailFomDuringList.before_after = true;
    else messaging_emails_list.push(createNewMessagingEmail({ email, during: false }));
  });
  return messaging_emails_list;
};

export const formShowMessageValue = messagingEmailsList => {
  if (!Array.isArray(messagingEmailsList)) return "off";
  if (messagingEmailsList.some(({ before_after }) => before_after)) return "always";
  if (messagingEmailsList.some(({ during }) => during)) return "during";
  return "off";
};
