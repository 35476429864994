import React from "react";
import styled from "styled-components";
import ReorderIcon from "@material-ui/icons/Reorder";

const StyledReorderIcon = styled(ReorderIcon)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

export const ReorderButtonBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.setSpacing(10)}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;
  height: auto;
  cursor: pointer;

  ${({ isDragged }) => (isDragged ? "opacity: 0.5;" : null)}
`;

export const ReorderButton = ({ noBg, listeners }) => {
  return (
    <ReorderButtonBase className="sortable-handle" noBg={noBg} {...listeners}>
      <StyledReorderIcon />
    </ReorderButtonBase>
  )
}