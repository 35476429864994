import { useQueries, useQueryClient } from "@tanstack/react-query";
import BaseModal from "components/_new/ModalBase";
import Switch from "components/_new/Switch";
import SelectInput from "components/ui/Inputs/Select";
import { Message } from "components/ui/Messages";
import { colors } from "config/theme/colors";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { PANEL_SETTINGS_COMPANY } from "constants/routes";
import { LANGUAGE_SELECTOR } from "constants/content";
import { SettingsLink } from "./style";

const LanguageModal = ({
  languages,
  routing,
  onClose,
  setSelectedLanguage,
  data,
  itineraryLanguages,
  setItineraryLanguages,
  defaultItineraryLanguages,
  refetchAll,
}) => {
  const queryClient = useQueryClient();
  const { operator_code } = useParams();
  const {
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm();
  const defaultLanguageWatch = watch("default_language");
  const defaultLanguage = routing?.fallback.split("/").slice(-1)[0];

  const onChangeLanguage = (lang, checked) =>
    setItineraryLanguages({ ...(itineraryLanguages || {}), [lang]: { ...(itineraryLanguages?.[lang] || {}), active: checked } });

  const onSubmit = async () => {
    const changedLanguages = [];
    const url = routing?.fallback
      .split("/")
      .slice(0, -1)
      .join("/");

    Object.entries(itineraryLanguages)?.forEach(([key, value]) => {
      if (defaultItineraryLanguages[key]?.active !== value.active) changedLanguages.push({ [key]: value });
    });

    await HttpClient.post(`/itinerary/${operator_code}/DEFAULT/fallback_language`, { language: defaultLanguageWatch });

    for (const item of changedLanguages) {
      const key = Object.keys(item);
      const value = item[key];

      if (value.active)
        // eslint-disable-next-line no-await-in-loop
        await HttpClient.post(`${url}/${key}`, {
          type: "stay",
          source: "portal",
          meta: {
            show_directory: data.meta.show_directory,
            show_vouchers: data.meta.show_vouchers,
            show_daily_activities: data.meta.show_daily_activities,
          },
          field1: null,
          vamoos_id: data.vamoos_id,
          is_active: true,
          new_only: false,
          background: null,
          documents: {
            travel: [],
            destination: [],
          },
          notifications: [],
          directories: [],
          departure_date: data.departure_date,
          return_date: data.return_date,
        });
      // eslint-disable-next-line no-await-in-loop
      else await HttpClient.delete(`${url}/${key}`);
    }

    refetchAll();
    await queryClient.invalidateQueries({ queryKey: ["trip"] });
    if (isDirty) setSelectedLanguage(defaultLanguageWatch);
    onClose();
  };

  useEffect(() => {
    reset({ default_language: defaultLanguage });
  }, [defaultLanguage]);

  return (
    <BaseModal
      title="Languages"
      topLineStyle={{ marginBottom: 25 }}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflow: "visible" }}
      confirmTitle="Save"
      onConfirm={onSubmit}
      onCancel={onClose}
    >
      <SelectInput
        autoFocus
        label="Default language"
        name="default_language"
        options={languages?.filter(item => itineraryLanguages?.[item.code]?.active)}
        optionLabelVar="name"
        optionValueVar="code"
        control={control}
        isClearable={false}
      />

      <div style={{ margin: "25px 0 15px 0", borderTop: `1px solid ${colors.grey10}`, paddingTop: 20 }}>Additional languages</div>
      <div style={{ display: "flex", alignItems: "center", gap: 15, marginBottom: 15 }}>
        {languages
          ?.filter(item => !item.isDefault)
          ?.map(item => (
            <div
              style={{
                border: `1px solid ${itineraryLanguages?.[item.code]?.active ? colors.brand : colors.grey10}`,
                borderRadius: 50,
                padding: "0 15px",
              }}
            >
              <Switch
                label={item.name}
                checked={itineraryLanguages?.[item.code]?.active}
                onChange={checked => onChangeLanguage(item.code, checked)}
              />
            </div>
          ))}
      </div>
      <Message
        type="info"
        text={
          <>
            {LANGUAGE_SELECTOR.toAddAdditionalLanguages}{" "}
            <SettingsLink to={PANEL_SETTINGS_COMPANY}> {LANGUAGE_SELECTOR.languageSettings}</SettingsLink>
          </>
        }
      />
    </BaseModal>
  );
};

export default LanguageModal;
