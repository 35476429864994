import { EditOutlined, ModeEditOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Icon from "components/_new/Icon";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import { LanguageLock } from "feature/panel/Stays/_shared/LanguageLock";
import React, { useState } from "react";
import DocumentsModal from "../../pages/Trips/Documents/modal";
import { Flex } from "./style";

const DocumentsTitle = ({
  control,
  iconId = 286,
  label,
  canEdit,
  hideAddButton,
  onAdd,
  onSubmitModal,
  modalLabel,
  hideTitleInput,
  styleContainer = {},
  isDefaultStayLanguage,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = data => {
    onSubmitModal(data);
    setIsOpen(false);
  };

  const handleModal = () => setIsOpen(!isOpen);

  return (
    <>
      {isOpen && (
        <DocumentsModal
          control={control}
          hideTitleInput={hideTitleInput}
          onCancel={handleModal}
          icon={iconId}
          label={modalLabel}
          onSubmit={onSubmit}
        />
      )}
      <Flex additionalTopPadding style={styleContainer}>
        <div style={{ display: "grid", alignItems: "center", gridTemplateColumns: "25px fit-content(400px) 1fr", gap: 25 }}>
          <Icon iconId={iconId || 286} style={{ filter: "brightness(0)" }} />

          <ItineraryTitle style={{ marginBottom: 0, marginRight: 0, wordBreak: 'break-word', whiteSpace: 'normal'}}>{label}</ItineraryTitle>

          <div style={{ display: "flex", alignItems: "center" }}>
            {canEdit && <OutlinedButton startIcon={<EditOutlined />} type="grey" onClick={handleModal} disabled={!isDefaultStayLanguage} />}
            {!isDefaultStayLanguage && <LanguageLock style={{ marginLeft: 15 }} />}
          </div>
        </div>
        {!hideAddButton && canEdit && <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={() => onAdd(null)} style={{ marginLeft: 15 }} />}
      </Flex>
    </>
  );
};

export default DocumentsTitle;
