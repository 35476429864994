import React, { useLayoutEffect, useState } from "react";

// eslint-disable-next-line
import { APILoader, PlacePicker } from "@googlemaps/extended-component-library/react";

import usePlacesAutocomplete from "use-places-autocomplete";

import InputAdornment from "@material-ui/core/InputAdornment";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { getMediaPx } from "helpers";
import PropTypes from "prop-types";
import styled from "styled-components";

import Switch from "components/_new/Switch";

import { LOCATION_LABELS } from "constants/content";
import { ActionsPanel, ActionsWrapper } from "feature/panel/_shared/MapWidget_old/styledComponents";

const StyledSearchedWrapper = styled.div`
  width: 100%;
  max-width: 40%;
  margin: 0px 24px;
  flex-grow: 1;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "40%")};

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }

  &.Mui-focused {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin: 0;
    max-width: none;
  }
`;

const CloseSearchButton = styled(InputAdornment)`
  && {
    cursor: pointer;
  }
`;

const MapWidgetSearchBar = ({
  onSearch,
  searchLabel,
  onOptionClick,
  inputRef,
  switchControls,
  onSwitch,
  searchByLocation,
  enabledData,
  onClear,
  styleContainer,
}) => {
  const {
    suggestions: { data: searchResults },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      locationBias: "IP_BIAS",
    },
  });
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event, value) => {
    setSearchQuery(value);
    if (searchByLocation) setValue(value);
    else onSearch(value);
  };

  const handleOptionClick = (event, value) => {
    setValue(value, false);
    onOptionClick(value);
  };

  const clearSearchInput = () => {
    handleSearch({ target: { value: "" } });
    setSearchQuery("");
    onClear();
  };

  const adornment = (
    <CloseSearchButton position="end" onClick={clearSearchInput}>
      {searchQuery ? <CloseOutlinedIcon /> : <SearchIcon />}
    </CloseSearchButton>
  );

  const checkControl = name => switchControls.includes(name);

  const fullWidth = switchControls.length === 0;

  const handleChange = (name, checked) => {
    onSwitch(name, checked);
  };

  useLayoutEffect(() => {
    // An unfortunate workaround due to using the Shadow DOM
    const pickerElement = document.querySelector("#place-picker");
    const style = document.createElement("style");
    style.innerHTML = `
      .container input { font-size: 16px; font-family: 'Montserrat'; border-radius: 8px; border-color: rgba(0, 0, 0, 0.23) }
      .container input:focus {
        border-color: #FF7C46;
        outline: none;
        border-width: 2px;
      }
      .container .overlay .search-button .icon {
        font-size: 24px;
        color: rgb(164, 167, 170);
      }
      .container .overlay .clear-button .icon {
        color: rgb(164, 167, 170);
        font-size: 24px;
      }
    `;

    pickerElement.shadowRoot.appendChild(style);
  }, []);

  const isChecked = name => enabledData?.includes(name);

  return (
    <ActionsPanel style={styleContainer}>
      <StyledSearchedWrapper fullWidth={fullWidth}>
        <APILoader apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY} />
        <PlacePicker
          id="place-picker"
          style={{ width: "100%" }}
          onPlaceChange={e => {
            if (!e.target.value) {
              onClear();
            } else {
              handleOptionClick(e, e.target.value);
            }
          }}
          onRequestError={e => console.log("Error searching", e)}
          placeholder="Search location"
        />
      </StyledSearchedWrapper>
      <ActionsWrapper fullWidth={fullWidth}>
        {checkControl("stays") && (
          <Switch
            name="stays"
            checked={isChecked("stays")}
            label={LOCATION_LABELS.showStays}
            labelPlacement="start"
            onChange={value => handleChange("stays", value)}
          />
        )}
        {checkControl("pois") && (
          <Switch
            name="pois"
            checked={isChecked("pois")}
            label={LOCATION_LABELS.showPoiOnMap}
            labelPlacement="start"
            onChange={value => handleChange("pois", value)}
          />
        )}
      </ActionsWrapper>
    </ActionsPanel>
  );
};

MapWidgetSearchBar.defaultProps = {
  switchControls: [],
  onClear: () => {},
  onOptionClick: () => {},
  searchByLocation: false,
  inputRef: null,
  onSearch: null,
};

MapWidgetSearchBar.propTypes = {
  onSearch: PropTypes.func,
  onSwitch: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  switchControls: PropTypes.arrayOf(PropTypes.string),
  searchLabel: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  onOptionClick: PropTypes.func,
  searchByLocation: PropTypes.bool,
};

export { MapWidgetSearchBar, StyledSearchedWrapper };
