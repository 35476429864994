import React, { cloneElement, useEffect, useState } from "react";
import { GLOBAL_CONTENT } from "constants/content";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { useMutation } from "@tanstack/react-query";
import moment from "moment/moment";
import { FileUploadService } from "services/FileUploadService";
import { HttpClient } from "services/application/httpClient/httpClient";
import { s3preparedUrl } from "feature/panel/Itinerary/helpers";
import { css } from "styled-components";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import BaseModal from "../ModalBase";
import { Wrap } from "./style";
import Tabs from "../Tabs";
import { modals } from "./modalTypes";
import { transformUrl } from "../../../utils/dataConverters";
import { onFlightAdd } from "../../../feature/panel/Trips/_shared/Flights/FlightsSearchForm/helpers";

async function imageUrlToFile(imageUrl, prefix) {
  const url = new URL(imageUrl);
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  const fileName = `${prefix ? prefix + "_" : ""}${url.pathname.substring(1)}_${new Date().getTime()}`;
  const file = new File([blob], `${fileName}`, { type: blob.type, name: fileName });
  return file;
}

const LibraryModal = ({
  title,
  types = ["library", "upload"],
  allowedFileTypes = IMAGES_MIME_TYPES,
  multiUpload,
  mobileFullScreen,
  onCancel,
  onConfirm,
  maxResolution,
  maxUploadSizeMB,
  minResolution,
  onlyResolution,
  onStaySelect,
  hideUrl,
  flightProps,
}) => {
  const fileUploadService = new FileUploadService(allowedFileTypes, maxResolution, maxUploadSizeMB, minResolution, onlyResolution);

  const [tab, setTab] = useState(types?.[0] || "library");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [uploadAssets, setUploadAssets] = useState([]);
  const [permitUpload, setPermitUpload] = useState(false);
  const [webPage, setWebPage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFlightLegs, setSelectedFlightLegs] = useState([]);
  const [selectedFlightData, setSelectedFlightData] = useState(null);

  const getFlightIdentifier = flight => `${flight.carrier}_${flight.flight_number}_${flight.departure_airport}_${flight.arrival_airport}`;
  const handleSelectedFlight = (isSelected, flight) => {
    const flightIdentifier = getFlightIdentifier(flight);

    setSelectedFlightLegs(prevSelectedFlights => {
      if (isSelected) {
        return prevSelectedFlights.some(selected => getFlightIdentifier(selected) === flightIdentifier)
          ? prevSelectedFlights
          : [...prevSelectedFlights, flight];
      } else {
        return prevSelectedFlights.filter(selected => getFlightIdentifier(selected) !== flightIdentifier);
      }
    });
  };

  const onFlightsSelectHandler = () => {
    if (selectedFlightLegs.length > 0) {
      setSelectedFlightData({
        selectedFlights: selectedFlightLegs,
        airlineCode: flightProps.watch("airline"),
        flightNumber: flightProps.watch("flight_number"),
        flightDate: moment(flightProps.watch("flight_date")),
      });
    } else {
      setSelectedFlightData(null);
    }
  };

  const { mutateAsync: fileUpload, isLoading } = useMutation(
    vars => HttpClient.post(`/file/upload_url`, { filename: vars.file.path ?? vars.file.name, content_type: vars.file.type }),
    {
      onSuccess: async (data, vars) => {
        const file = vars.file;
        const file_name = file?.name.replace(/\.[^.]+$/, "");

        await HttpClient.put(data?.data.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        const uploadedFile = {
          ...data?.data,
          file_name,
          s3url: s3preparedUrl(data?.data.s3url),
          preview_url: URL.createObjectURL(vars.file),
          file_meta: vars.file_meta,
          type: vars.file.type,
        };

        setUploadedFiles(prev => {
          return [...prev, uploadedFile];
        });
      },
    },
  );

  const handleUploadAssets = assets => {
    setSelectedAssets([]);
    setUploadAssets(assets);
  };

  const onChange = value => setWebPage(value);

  const handleSelectAsset = node => {
    let assets = selectedAssets;
    const { id, name, file, remote_url, active } = node;

    if (!active) {
      const newAsset = { id, name };
      if (file) {
        newAsset.https_url = file.https_url;
        newAsset.file_id = file.id;
        newAsset.s3_url = file.s3_url;
        newAsset.active = true;
        if (file && file.mime_type) newAsset.type = file.mime_type;
      } else newAsset.remote_url = remote_url;

      if (multiUpload || (!multiUpload && selectedAssets.length === 0)) {
        assets = selectedAssets.concat(newAsset);
      }

      if (!multiUpload && selectedAssets.length === 1) {
        assets = [newAsset];
      }
      setSelectedAssets(assets);
    } else {
      const withRemovedAsset = selectedAssets.filter(eachAsset => eachAsset.id !== id);
      setSelectedAssets(withRemovedAsset);
    }
  };

  const handleConfirm = async () => {
    if (uploadAssets?.filesUpload?.length) {
      let filesUpload = [...uploadAssets.filesUpload];
      let fileMeta = {};

      if (uploadAssets.isExternalUrl) {
        // Unsplash as of now (only one image supported)
        const { url, meta } = filesUpload[0];
        const file = await imageUrlToFile(url, "external");

        if (meta !== undefined) {
          fileMeta = meta;
        }

        filesUpload = [{ file }];
        setUploadAssets({ ...uploadAssets, filesUpload });
      }

      if (filesUpload.length === 1) {
        await fileUpload({ file_meta: fileMeta, file: filesUpload[0].file });
      } else {
        const promises = [];
        for (const fileItem of uploadAssets.filesUpload) {
          promises.push(fileUpload({ file_meta: {}, file: fileItem.file }));
        }
        await Promise.all(promises);
      }
    }
    if (selectedAssets?.length) {
      const files = selectedAssets.map(({ id, file_id, name, https_url, remote_url, type, s3_url }) => {
        const [filename] = name.split(".");

        return {
          id,
          file_id,
          library_node_id: id,
          name: filename,
          file_name: name,
          https_url: https_url || remote_url,
          is_library_file: true,
          type,
          s3_url,
        };
      });

      const updatedFiles = formatDisplayableLibraryPopupValues(files);
      onConfirm(updatedFiles.length === 1 ? updatedFiles[0] : updatedFiles);
      onCancel();
    }

    if (webPage) {
      const file_name = webPage
        .split("/")
        .filter(part => !!part)
        .pop();

      if (webPage.includes("http://") || webPage.includes("https://")) {
        onConfirm({ web_url: webPage, file_name });
      } else {
        const transformedWebUrl = transformUrl(webPage, true);
        onConfirm({ web_url: transformedWebUrl, file_name });
      }
      onCancel();
    }

    if (flightProps?.showManualForm) {
      const flightData = flightProps?.watch();
      const flight = await onFlightAdd({
        carrier_code: { value: flightData.airline },
        date: flightData.flight_date,
        arrival_at: flightData.arrival_at,
        departure_time: flightData.departure_at,
        arrival_airport_code: flightData.arrival_airport_code,
        arrival_terminal: flightData.arrival_terminal,
        carrier_flight_number: flightData.flight_number,
        departure_airport_code: flightData.departure_airport_code,
        departure_terminal: flightData.departure_terminal,
      });
      const { date: flightDate, fs: airlineCode, number: flightNumber, selectedFlights } = flight;
      await flightProps.handleFlights({ airlineCode, flightNumber, flightDate, selectedFlights, noDateFormat: true });
      await setSelectedFlightLegs([]);
      await onCancel();
    }

    if (selectedFlightData) {
      await flightProps.handleFlights(selectedFlightData);
      await setSelectedFlightLegs([]);
      await onCancel();
    }

    if (flightProps?.setShowManualForm) flightProps.setShowManualForm(false);
  };

  const handleTabChange = () => {
    setSelectedAssets([]);
    setUploadAssets([]);
    setWebPage("");
  };

  useEffect(() => {
    if (uploadedFiles.length === 0) return;
    if (uploadAssets?.filesUpload?.length === uploadedFiles.length) {
      onConfirm(uploadedFiles.length === 1 ? uploadedFiles[0] : uploadedFiles);
      onCancel();
    }
  }, [uploadedFiles, uploadAssets]);

  useEffect(onFlightsSelectHandler, [selectedFlightLegs]);

  return (
    <BaseModal
      title={title}
      confirmTitle={GLOBAL_CONTENT.select}
      mobileFullScreen={mobileFullScreen}
      customCss={modals[tab].customCss}
      contentStyle={modals[tab].contentStyle}
      confirmDisabled={
        !selectedAssets.length &&
        !webPage &&
        !uploadAssets?.filesUpload?.length &&
        !selectedFlightLegs.length &&
        !flightProps?.showManualForm
      }
      onCancel={onCancel}
      onConfirm={handleConfirm}
      isLoading={isLoading || flightProps?.isLoading}
      modalStyle={{ overflow: "visible", display: 'flex', flexDirection: 'column' }}
      // contentStyle={{ display: "contents", overflow: "visible !important" }}
      mob
    >
      <div style={{ overflow: "hidden" }}>
        <Tabs
          styleContainer={{margin: 0, padding: 0, marginTop: 10}}
          variant="fullWidth"
          tab={tab}
          setTab={value => {
            setTab(value);
            flightProps?.setShowManualForm(false);
          }}
          tabs={types.map(item => ({ id: item, label: modals[item].label }))}
          customHandleChange={handleTabChange}
        />
      </div>
      {cloneElement(modals[tab].component, {
        allowedFileTypes,
        handleMiniatureClick: handleSelectAsset,
        onStaySelect,
        selectedAssets,
        setUploadAssets,
        fileUploadService,
        setPermitUpload,
        onChange,
        value: webPage,
        hideUrl,
        ...flightProps,
        onFlightSelect: handleSelectedFlight,
      })}
    </BaseModal>
  );
};

export default LibraryModal;
