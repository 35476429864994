import React, { cloneElement } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Wrap } from "./style";

const ListItem = ({ label, icon, path, as, showChevron, onClick, errors, style }) => {
  const updatedPath = path === "inspirations" ? "localInspiration" : path;
  const hasError = Boolean(errors?.[updatedPath]);

  const handleClick = () => onClick && onClick();

  return (
    <Wrap to={path} as={as} onClick={handleClick} error={hasError} style={style}>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        {icon} <span>{label}</span>
      </div>
      {showChevron && <ChevronRightIcon />} {hasError && <ErrorOutlineIcon />}
    </Wrap>
  );
};

export default ListItem;
