import React from "react";
import Checkbox from "components/_new/Checkbox";
import { ItemGrid, ItemWrap } from "./style";

const Item = ({ name, features, selected, disabled, onCheck }) => {
  return (
    <ItemWrap>
      <div>{name}</div>
      <ItemGrid>
        {features.map(item => (
          <Checkbox
            key={item.id}
            checked={selected?.[item.id]}
            disabled={disabled}
            handleChange={() => onCheck({ ...item, group_name: name })}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item.icon_https_url} alt="icon" /> {item.name}
              </div>
            }
          />
        ))}
      </ItemGrid>
    </ItemWrap>
  );
};

export default Item;
