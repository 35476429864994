import React from "react";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MobileItemTitle, MobileSecondaryTitle } from "feature/panel/Itinerary/helpers";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Switch from "components/_new/Switch";
import config from "config/app";
import { getNotificationDate, getNotificationDateTimeFormatted, getNotificationEmptyFields } from "../helpers";
import SpecificSettingsPlaceholder from "../../Locations_new/Pois/specificSettingsPlaceholder";
import { MobileItemContent, MobileItemContainer } from "./style";

const MobileItem = ({ item, departure_date, return_date, type, handleSwitchAction, handleEditAction, handleDeleteAction, clickedRow, onRowClick, itineraryType }) => {
  const { content, template_id, url, start_at, end_at, is_active, id, isOverridden = false, use_global } = item;
  const isTimed = type === "timed";
  const emptyFields = getNotificationEmptyFields(item);
  const hasEmptyFields = emptyFields && emptyFields?.length > 0;

  return (
    <MobileItemContainer isOverridden={isOverridden} clickedRow={clickedRow} templateId={template_id} id={`row-${template_id}${isOverridden ? "-template" : "-mob"}`}>
      <MobileItemContent isOverridden={isOverridden} onClick={() => isOverridden && onRowClick(id, true)}>
        <div style={{ maxWidth: isOverridden ? "100%" : "50%", overflowX: 'auto', textOverflow: 'ellipsis' }}>
              <MobileItemTitle style={{ marginTop: 10, marginBottom: 10 }}>{content}</MobileItemTitle>
          {isOverridden ? (
            <>
              <SpecificSettingsPlaceholder style={{ marginLeft: 0, marginTop: 5, width: "fit-content" }} />
            </>
          ) : (
            <>
              <MobileSecondaryTitle style={{ marginBottom: 10 }}>{template_id && use_global === false ? "(modified)" : ""}</MobileSecondaryTitle>
              <MobileSecondaryTitle style={{ marginBottom: 10 }}>{template_id ? `Template ${template_id}` : "Trip"}</MobileSecondaryTitle>
              <MobileSecondaryTitle style={{ marginBottom: 10 }}>{url}</MobileSecondaryTitle>
              {isTimed && <MobileSecondaryTitle>{getNotificationDate({ ...item, departure_date, return_date, itineraryType })}</MobileSecondaryTitle>}
              {start_at && (
                <MobileSecondaryTitle style={{ marginTop: 10 }}>
                  {isTimed ? "Device local time:" : "Active from:"}{" "}
                  {getNotificationDateTimeFormatted(start_at, isTimed ? config.timeFormat : config.momentLocaleInputDateFormat)}
                </MobileSecondaryTitle>
              )}
              {end_at && !isTimed && (
                <MobileSecondaryTitle style={{ marginTop: 10 }}>
                  Active to: {getNotificationDateTimeFormatted(end_at, config.momentLocaleInputDateFormat)}
                </MobileSecondaryTitle>
              )}

            </>
            )}
        </div>
        <div style={{ display: isOverridden ? "none" : "flex", gap: 5, alignItems: "center" }}>
          <OutlinedButton onClick={() => handleEditAction(item, id === template_id)} startIcon={<EditOutlinedIcon />} type="grey" iconOnlyMobile />
          <OutlinedButton
            onClick={() => handleDeleteAction(item)}
            startIcon={<DeleteOutlinedIcon />}
            type="grey"
            iconOnlyMobile
            disabled={id === template_id}
          />
          <Switch
            onChange={() => {
              if (hasEmptyFields) {
                handleEditAction(item, false);
              } else {
                handleSwitchAction(item);
              }
            }}
            checked={is_active}
            disabled={id === template_id && hasEmptyFields}
          />
        </div>
      </MobileItemContent>
    </MobileItemContainer>
  );
};

export default MobileItem;
