import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RestoreOutlined, SaveOutlined } from "@mui/icons-material";
import { PanelTopBar } from "components/templates/Panel/PanelTopBar";
import { PromptContext, usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import MobileTopSecondMenu from "feature/panel/Itinerary/components/Mobile";
import TopSecondMenu from "feature/panel/Itinerary/components/TopSecondMenu";
import { DefaultActionButtons } from "feature/panel/Itinerary/components/TopSecondMenu/components/defaults/ActionButtons";
import DefaultTopSecondMenu from "feature/panel/Itinerary/components/TopSecondMenu/menus/default";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";

import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import ItineraryPreviewModal from "components/_new/ItineraryPreviewModal";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import {
  PANEL_TRIPS_CREATE_CONTENT_PATH,
  PANEL_TRIPS_CREATE_PATH,
  PANEL_TRIPS_EDIT_CONTENT_PATH,
  PANEL_TRIPS_EDIT_PATH,
} from "constants/routes";
import { PageNotFound } from "feature/views/PageNotFound/PageNotFound";
import { usePermissionsService } from "hooks/usePermissionsService";
import useResourceLock, { generateLockId } from "hooks/useResourceLock";
import { setNotification } from "store/app/actions";

import ItineraryNavigation from "./components/Navigation";

import { defaultValues, getData, modifyData, prepareDataForSubmit } from "./helpers";
import { ResponsiveHideFrom } from "./responsive";
import ItineraryRoutes from "./routes";
import { Grid, TitleMobile } from "./style";
import { schema as validationSchema } from "./validationSchema";
import { schema as validationSchemaStays } from "./validationSchemaStays";
import { useStayLanguages } from "../Stays/StaysWizard/StaysWizard";
import CopyModal from "./copyModal";
import EraseModal from "./eraseModal";
import UpdateModal from "./updateModal";

export const EditorContext = React.createContext();

let currentImsertWorker;

const ItineraryRoot = () => {
  const permissionsService = usePermissionsService();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enabled, showPrompt } = useContext(PromptContext);

  // EditorContext state
  const [baseModalOpen, setBaseModalOpen] = useState(false);

  const [copyOpen, setCopyOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [eraseOpen, setEraseOpen] = useState(false);
  const [lockMeta, setLockMeta] = useState(null);
  const [lockOwner, setLockOwner] = useState(null);
  const [isReseted, setIsReseted] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [itineraryData, setItineraryData] = useState(null);
  const [originalTrip, setOriginalTrip] = useState(null);
  const [originValues, setOriginValues] = useState(null);
  const stayLanguages = useStayLanguages();
  const { type = "trip", reference_code, operator_code } = useParams();

  const action = operator_code ? "edit" : "create";
  const tripInfo = reference_code ? `- ${operator_code} - ${reference_code}` : "";
  const capitaliseFirstLetter = word => {
    return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
  };
  const hasAdditionalMenu = type === "stay" && stayLanguages.length > 1;

  const { data: operatorData = {}, isLoading: isOperatorLoading } = useQuery({
    queryKey: ["/operator"],
  });

  const { data: user } = useQuery({
    queryKey: ["/user/me"],
  });

  // const { isLoading: isOperatorLoading } = useQuery({ queryKey: ["operator"] });
  const { isLoading: isIconsLoading } = useQuery({ queryKey: ["/icon?count=500"] });

  const { data, refetch, isLoading: getTripLoading, status, error } = useQuery({
    queryKey: [`trip`],
    queryFn: () => HttpClient.get(`/itinerary/${operator_code}/${reference_code}?_=${Date.now()}`),
    refetchOnMount: true,
    cacheTime: 0,
    enabled: action === "edit",
    select: res => modifyData(res?.data),
    onSuccess: res => {
      queryClient.invalidateQueries({ queryKey: ["notificationTemplates"] });
      setOriginalTrip(res);
      setItineraryData(res);
    },
  });
  const isGetDifLangEnabled = Boolean(action === "edit" && type === "stay" && selectedLanguage);
  const isNotFound = error?.response?.data?.error?.toLowerCase() === "not found";

  const { data: stayDifferentLang, isLoading: getItineraryLoadingDifLanguage, error: tripError } = useQuery({
    queryKey: [`stayLang`, selectedLanguage],
    queryFn: ({ queryKey }) => HttpClient.get(`/itinerary/${operator_code}/${reference_code}/language/${queryKey[1]}?_=${Date.now()}`),
    refetchOnMount: true,
    cacheTime: 0,
    enabled: isGetDifLangEnabled,
    select: res => modifyData(res?.data),
    onSuccess: res => {
      setItineraryData(res);
    },
  });
  const { isLoading: isBrandsLoading } = useQuery({ queryKey: ["/operator/branding"] });
  const mainLanguage = data?.routing?.fallback?.split("/").slice(-1)[0];

  const isDefaultStayLanguage = type === "trip" || action === "create" || mainLanguage === selectedLanguage;
  const isActive = action === "create" || getTripLoading || isBrandsLoading || data?.is_active;
  const form = useForm({
    resolver: yupResolver(type === "trip" ? validationSchema : validationSchemaStays(isDefaultStayLanguage)),
    defaultValues,
    shouldUnregister: false,
  });

  const dirtyFields = form.formState.dirtyFields;
  const watchReference = form.watch("general.reference_code");
  const vamoosId = form.watch("general.vamoos_id");
  const passcode = form.watch("general.reference_code");
  const operatorCode = form.watch("general.operator_code");
  const language = form.watch("general.language");

  const { mutate: saveTrip, isLoading } = useMutation(
    body =>
      HttpClient.post(`/itinerary/${operatorCode}/${watchReference}?_=${Date.now()}`, {
        ...body,
        type,
      }),
    {
      onSuccess: res => {
        queryClient.invalidateQueries({ queryKey: ["trip"] });
        dispatch(setNotification({ type: "success", message: "Trip saved successfully" }));
        setEraseOpen(false);

        if (currentImsertWorker) {
          currentImsertWorker.terminate();
        }

        // @ts-ignore
        currentImsertWorker = new Worker(new URL("../../../utils/imsert.worker.js", import.meta.url));
        currentImsertWorker.postMessage({
          apiKey: operatorData?.integrations?.imsert?.api_key,
          originalTrip: originalTrip
            ? {
                id: originalTrip.id,
                storyboard: originalTrip.details,
                locations: originalTrip.locations,
              }
            : null,
          newTrip: {
            storyboard: originValues?.storyboard?.details?.map(s => {
              const tempDivElement = document.createElement("div");
              tempDivElement.innerHTML = s.content;
              const plainContentText = tempDivElement.textContent || tempDivElement.innerText || "";

              return {
                ...s,
                plainText: plainContentText,
              };
            }),
            locations: originValues?.locations?.locations,
          },
        });
        if (dirtyFields.general?.reference_code) navigate(`/panel/itinerary/trip/edit/${operatorCode}/${passcode}/general`);
        if (!operator_code) navigate(`/panel/itinerary/trip/edit/${operatorCode}/${passcode}/general`);
      },
      onError: () => dispatch(setNotification({ type: "error", message: "Error while saving a trip" })),
    },
  );
  const { mutate: saveStay, isLoading: isSavingStay } = useMutation(
    body =>
      HttpClient.post(`/itinerary/${operatorCode}/DEFAULT/language/${selectedLanguage || "en"}`, {
        ...body,
        type,
      }),
    {
      onSuccess: res => {
        queryClient.invalidateQueries({ queryKey: ["stayLang"] });
        dispatch(setNotification({ type: "success", message: "Stay saved successfully" }));
        setEraseOpen(false);
        if (!operator_code) navigate(`/panel/itinerary/stay/edit/${operatorCode}/DEFAULT/general`);
        if (res.data.operator_code !== operator_code) navigate(`/panel/itinerary/stay/edit/${res.data.operator_code}/DEFAULT/general`);
      },
      onError: () => dispatch(setNotification({ type: "error", message: "Error while saving a stay" })),
    },
  );

  const reorderObject = (referenceObj, objToReorder) => {
    const reorderedObj = {};
    for (const key in referenceObj) {
      if (objToReorder.hasOwnProperty(key)) {
        reorderedObj[key] = objToReorder[key];
      }
    }
    return reorderedObj;
  };

  const onSubmit = values => {
    const rerderedValues = reorderObject(defaultValues, values);
    if (type === "trip")
      saveTrip({
        ...prepareDataForSubmit(rerderedValues),
        ...(!isActive ? { is_active: true } : {}),
      });
    else {
      delete values.documents;
      values.general.meta.travel_documents_label = values.actions.meta.travel_documents_label || undefined;
      values.general.meta.destination_documents_label = values.actions.meta.destination_documents_label || undefined;

      saveStay({
        ...prepareDataForSubmit({
          ...values,
          general: {
            ...values.general,
            type,
            is_active: isDefaultStayLanguage ? true : stayDifferentLang?.hasOwnProperty("is_active") ? stayDifferentLang.is_active : false,
            field1: values.general.field1 || null,
          },
        }),
        ...(!isActive ? { is_active: true } : {}),
      });
    }
    setOriginValues({ ...values });
  };
  // const onSubmit = values => console.log({ ...prepareDataForSubmit(values), ...(!isActive ? { is_active: true } : {}) });
  const onDelete = values => saveTrip({ ...prepareDataForSubmit(values), is_active: false });
  const toggleCopyModal = () => setCopyOpen(!copyOpen);
  const toggleUpdateModal = () => setUpdateOpen(!updateOpen);
  const toggleEraseModal = () => setEraseOpen(!eraseOpen);
  const togglePreview = () => setIsPreviewOpen(!isPreviewOpen);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoosId);
  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);
  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

  const defaultActionButtons = (
    <DefaultActionButtons
      canEdit={canEdit}
      triggerCopyVamoos={toggleCopyModal}
      toggleUpdateModal={toggleUpdateModal}
      isEditMode={action === "edit"}
      isActive={isActive}
      isDirty={isDirty}
      onPreview={togglePreview}
    />
  );

  const buttonTitle = isDirty && isActive ? "Save" : !isActive ? "Restore" : "";

  const saveButton = (
    <OutlinedButton
      text={buttonTitle}
      onClick={!Object.keys(form.formState.errors).length ? form.handleSubmit(onSubmit) : null}
      disabled={isActive && (!canEdit || !isDirty)}
      // style={{ marginLeft: 15 }}
      isLoading={isLoading || isSavingStay}
      iconOnlyMobile
      startIcon={isActive ? <SaveOutlined /> : <RestoreOutlined />}
      type="solid"
    />
  );

  const { requestLock, takeoverLock, isConnected } = useResourceLock({
    lockId: generateLockId("trip", data?.vamoos_id),
    onLockStatusChanged: async payload => {
      setLockMeta(payload.meta_data);
      setLockOwner(payload.own_lock);
      if (payload.meta_data.isTakeover) {
        await refetch();
      }
    },
  });

  const handleTakeover = () =>
    takeoverLock({
      owner_name: user.username,
      owner_id: user.id,
      isTakeover: true,
    });
  const onCancel = () => {
    if (lockMeta) {
      navigate("/panel/trips");
    }
  };

  const onLanguageChange = lang => {
    form.reset(defaultValues);

    if (isDirty)
      showPrompt({
        to: "",
        callback: async () => {
          setSelectedLanguage(lang);
          // await onChange(languageToDisplay.find(lang => lang.code === code));
        },
      });
    else {
      form.reset(defaultValues);
      setSelectedLanguage(lang);
    }
  };

  useEffect(() => {
    if (itineraryData?.vamoos_id && user && isConnected) {
      requestLock({
        owner_name: user.username,
        owner_id: user.id,
      });
    }
  }, [itineraryData?.vamoos_id, user, isConnected]);

  useEffect(() => {
    if ((!selectedLanguage && mainLanguage) || action === "create") setSelectedLanguage(mainLanguage || "en");
  }, [itineraryData]);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isDirty, [
    PANEL_TRIPS_CREATE_PATH,
    PANEL_TRIPS_CREATE_CONTENT_PATH,
    PANEL_TRIPS_EDIT_PATH,
    PANEL_TRIPS_EDIT_CONTENT_PATH,
    "/panel/itinerary/",
  ]);
  useEffect(() => {
    if (itineraryData) {
      const currentValues = form.getValues();

      const nonDefaultStayLanguageData = getData(itineraryData || {});
      if (type === "stay") {
        if (!isDefaultStayLanguage) {
          if (itineraryData.language === selectedLanguage) {
            form.reset({
              ...defaultValues,
              ...nonDefaultStayLanguageData,
              general: {
                ...nonDefaultStayLanguageData.general,
                background: nonDefaultStayLanguageData.general.background || null,
                meta: {
                  ...nonDefaultStayLanguageData.general.meta,
                  address: getData(originalTrip).general.meta.address,
                },
              },
              locations: stayDifferentLang.is_active ? getData(originalTrip).locations : {},
              main: getData(originalTrip),
            });

            // form.reset(defaultValues);
          }
        } else {
          form.reset(getData(itineraryData));
        }
      } else {
        form.reset(getData(itineraryData));
      }

      setIsReseted(true);
    }
  }, [itineraryData, isDefaultStayLanguage]);

  useEffect(() => {
    if (type === "stay" && tripError?.response?.data?.error.includes("Variant not found")) {
      const data = getData(originalTrip);

      form.reset({
        ...defaultValues,
        general: {
          ...defaultValues?.general,
          operator_code: data?.general?.operator_code,
          meta: { address: data.general.meta.address },
          vamoos_id: data.general.vamoos_id,
          language: selectedLanguage,
          type: "stay",
        },
        main: data,
      });
    }
  }, [tripError]);
  useEffect(() => {
    return () => {
      form.reset(defaultValues);
    };
  }, []);

  if (isNotFound) return <PageNotFound />;
  return (
    <>
      {isPreviewOpen && <ItineraryPreviewModal userId={operatorCode} passcode={passcode} onClose={togglePreview} />}

      {updateOpen && <UpdateModal passcode={passcode} operatorCode={operatorCode} onClose={toggleUpdateModal} language={language} />}
      {copyOpen && (
        <CopyModal
          form={form}
          onCancel={toggleCopyModal}
          onSave={form.handleSubmit(onSubmit)}
          isSaving={isLoading}
          type={type}
          handleRefreshItinerary={refetch}
          getTripLoading={getTripLoading}
          isDirty={isDirty}
        />
      )}
      {eraseOpen && <EraseModal onCancel={toggleEraseModal} onConfirm={form.handleSubmit(onDelete)} type={type} />}
      <ConfirmationModal
        onConfirm={handleTakeover}
        confirmLabel="Force take over"
        cancelLabel="Go back"
        onCancel={onCancel}
        title={`Trip is currently being edited by ${lockMeta?.owner_name}`}
        open={!lockOwner && lockMeta?.owner_id}
      />
      <PanelTopBar />
      <ResponsiveHideFrom size="md">
        <TopSecondMenu
          hasAdditionalMenu={hasAdditionalMenu}
          languages={stayLanguages}
          mainLanguage={mainLanguage}
          selectedLanguage={selectedLanguage || mainLanguage}
          isStayVariantLoading={getItineraryLoadingDifLanguage}
          onLanguageChange={onLanguageChange}
          isSavingStay={isSavingStay}
          data={data}
          action={action}
          vamoosId={vamoosId}
          isDefaultStayLanguage={isDefaultStayLanguage}
          menu={
            <DefaultTopSecondMenu
              title={`${reference_code ? "Edit" : "Create"} ${type} ${tripInfo}`}
              saveButton={saveButton}
              actions={defaultActionButtons}
              form={form}
              isLoading={isLoading}
              onBack={() => navigate(`/panel/${type}`)}
              disabled={!canEdit}
              isActive={isActive}
              isDirty={isDirty}
              type={type}
            />
          }
        />
      </ResponsiveHideFrom>
      <TitleMobile style={{ backgroundColor: "#fff", zIndex: 10 }}>
        {`${reference_code ? "Edit" : "Create"} ${capitaliseFirstLetter(type)} ${tripInfo}`}
      </TitleMobile>
      <MobileTopSecondMenu
        isActive={isActive}
        onSave={form.handleSubmit(onSubmit)}
        triggerCopyVamoos={toggleCopyModal}
        toggleUpdateModal={toggleUpdateModal}
        actions={defaultActionButtons}
        saveButton={saveButton}
        errors={form.formState.errors}
        onPreview={togglePreview}
        isDirty={form.formState.isDirty}
        languages={stayLanguages}
        mainLanguage={mainLanguage}
        selectedLanguage={selectedLanguage || mainLanguage}
        onLanguageChange={setSelectedLanguage}
        isDefaultStayLanguage={isDefaultStayLanguage}
        hasAdditionalMenu={hasAdditionalMenu}
        data={data}
        type={type}
        action={action}
        shouldShowActionButtons={action === "edit" && canRead && canEdit}
      />
      <Grid hasAdditionalMenu={hasAdditionalMenu} type={type} hasLanguages={stayLanguages?.length > 1}>
        <ResponsiveHideFrom size="md">
          <ItineraryNavigation
            isActive={isActive}
            type={type}
            action={action}
            errors={form.formState.errors}
            toggleEraseModal={toggleEraseModal}
            hasAdditionalMenu={hasAdditionalMenu}
            isDefaultStayLanguage={isDefaultStayLanguage}
          />
        </ResponsiveHideFrom>
        <EditorContext.Provider value={{ baseModalOpen, setBaseModalOpen }}>
          <ItineraryRoutes
            type={type}
            action={action}
            form={form}
            canEdit={canEdit}
            isActive={isActive}
            canCreate={canCreate}
            onSubmit={onSubmit}
            isLoading={
              (type === "stay" && isGetDifLangEnabled && getItineraryLoadingDifLanguage) ||
              (getTripLoading && action === "edit") ||
              isBrandsLoading ||
              isOperatorLoading ||
              isIconsLoading
            }
            isReseted={isReseted}
            selectedLanguage={selectedLanguage}
            mainLanguage={mainLanguage}
            isDefaultStayLanguage={action === "create" || isDefaultStayLanguage}
            hasAdditionalMenu={hasAdditionalMenu}
            isDirty={form.formState.isDirty}
          />
        </EditorContext.Provider>
      </Grid>
    </>
  );
};

export default ItineraryRoot;
