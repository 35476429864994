import React from "react";
import BaseModal from "components/_new/ModalBase";
import { getFlightsMapUrl } from "utils/url";
import { convertToDateTimeFormat } from "utils/dataConverters";
import { Content, Item, Wrap } from "./style";

const departureInfo = [
  {
    key: 0,
    label: "Scheduled departure time",
    varName: "scheduled_departure_time",
    render: ({ departure_at_utc, departure_airport }) => convertToDateTimeFormat(departure_at_utc, departure_airport.timezone),
  },
  {
    key: 1,
    label: "Departure airport",
    varName: "departure_airport.name",
  },
  {
    key: 2,
    label: "Departure city",
    varName: "departure_airport.city",
  },
  {
    key: 3,
    label: "Departure gate",
    varName: "departure_gate",
  },
  {
    key: 4,
    label: "Departure terminal",
    varName: "departure_terminal",
  },
];

const arrivalInfo = [
  {
    key: 0,
    label: "Scheduled arrival time",
    varName: "scheduled_arrival_time",
    render: ({ arrival_at_utc, arrival_airport }) => convertToDateTimeFormat(arrival_at_utc, arrival_airport.timezone),
  },
  {
    key: 1,
    label: "Arrival airport",
    varName: "arrival_airport.name",
  },
  {
    key: 2,
    label: "Arrival city",
    varName: "arrival_airport.city",
  },
  {
    key: 3,
    label: "Arrival gate",
    varName: "arrival_gate",
  },
  {
    key: 4,
    label: "Arrival terminal",
    varName: "arrival_terminal",
  },
];

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, current) => {
    return acc ? acc[current] : undefined;
  }, obj);
};

const ViewFlightModal = ({ onClose, data }) => {
  const url = getFlightsMapUrl(data.departure_airport_fs_code, data.arrival_airport_fs_code);
  const title = `${data.carrier_fs_code} ${data.carrier_flight_number} ${ data.carrier?.name || ""}`;

  return (
    <BaseModal title={title} modalStyle={{ maxWidth: "none" }} onCancel={onClose} mobileFullScreen hideButtons showCloseIcon>
      <Wrap>
        <img src={url} alt="route" />

        <Content>
          <div id="left">
            {departureInfo.map(item => (
              <Item key={item.key}>
                {item.label}: <span>{item.render ? item.render(data) : getNestedValue(data, item.varName) || "-"} </span>
              </Item>
            ))}
          </div>
          <div id="right">
            {arrivalInfo.map(item => (
              <Item key={item.key}>
                {item.label}: <span>{item.render ? item.render(data) : getNestedValue(data, item.varName) || "-"} </span>
              </Item>
            ))}
          </div>
        </Content>
      </Wrap>
    </BaseModal>
  );
};

export default ViewFlightModal;
