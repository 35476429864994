import React from "react";
import { useParams } from "react-router-dom";
import Input from "components/ui/Inputs/TextInput";
import { Controller, useForm } from "react-hook-form";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Switch from "components/_new/Switch";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { isValidEmail } from "helpers";
import { Grid } from "./style";
import { dispatch } from "../../../../../../store/store";
import { setNotification } from "../../../../../../store/app/actions";
import { TRIP_WIZARD_CONTENT } from "../../../../../../constants/content";

const InviteBlock = ({ disabled }) => {
  const queryClient = useQueryClient();
  const { operator_code, reference_code } = useParams();
  const { control, watch, handleSubmit, reset, formState: { errors }, } = useForm();

  const { mutate, isLoading } = useMutation(body => HttpClient.put(`/itinerary/${operator_code}/${reference_code}/users`, body), {
    onSuccess: () => {
      reset({ email: "" });
      queryClient.invalidateQueries({ queryKey: ["shareAccessUsers"] });
      dispatch(setNotification({ type: "success", message: TRIP_WIZARD_CONTENT.permissions.notifications.invitedUser }));
    },
    onError: ({
                response: {
                  data: { error },
                },
              }) => dispatch(setNotification({ type: "error", message: error ?? TRIP_WIZARD_CONTENT.permissions.notifications.inviteUserError })),
  });
  const onSubmit = ({ email, write }) => {
    const payload = {
      email,
      permission: write ? "write" : "read",
    };

    mutate(payload);
  };

  return (
    <Grid>
      <Input
        autoFocus
        control={control}
        name="email"
        label="Email"
        disabled={disabled}
        rules={{
          required: true,
          validate: value => {
            if (!isValidEmail(value)) return "Email is not valid";

            return true;
          },
        }}
      />
      {!disabled && (
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: errors ? "19px" : "0" }}>
          <Controller
            control={control}
            name="read"
            render={({ field: { onChange } }) => <Switch checked onChange={onChange} label="Read" disabled />}
          />
          <Controller
            control={control}
            name="write"
            render={({ field: { onChange, value } }) => <Switch checked={value} onChange={onChange} label="Write" disabled={disabled} />}
          />
          <OutlinedButton text="Invite" startIcon={<SendOutlinedIcon />} onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={!watch("email")} />
        </div>
      )}
    </Grid>
  );
};

export default InviteBlock;
