import AddIcon from "@material-ui/icons/Add";
import { Link } from "components/ui/Links/Link";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { PrimaryButton } from "components/ui/Buttons";
import { CREATE_CONTENT_LABELS } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const StyledWrapper = styled.div`
  height: ${({ theme }) => theme.setSpacing(16)}px;
  width: auto;
  display: flex;
  align-items: center;
`;

const Actions = () => {
  const { searchMode } = useSelector(state => state.trips);
  const permissionsService = usePermissionsService();

  const { currentOperator } = useSelector(state => state.operator);

  const showRefactor = (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ?? process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true";
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);

  return (
    <StyledWrapper>
      {!searchMode && canCreate (
        <Link to={showRefactor ? "/panel/trip/create/general" : "/panel/trips/create"}>
          <PrimaryButton>
            <AddIcon />
            <span>{CREATE_CONTENT_LABELS.trip}</span>
          </PrimaryButton>
        </Link>
      )}
    </StyledWrapper>
  );
};

export { Actions, StyledWrapper };
