import React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { colors } from "config/theme/colors";
import { Wrap } from "./style";

const FloatingAction = ({ onClick }) => {
  return (
    <Wrap>
      <Fab color="error" aria-label="add" onClick={onClick}>
        <AddIcon />
      </Fab>
    </Wrap>
  );
};

export default FloatingAction;
