import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import BaseModal from "../../../components/_new/ModalBase";
import Checkbox from "../../../components/_new/Checkbox";

import { setNotification } from "../../../store/app/actions";
import DnD from "../../../components/_new/DnD_new";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  position: relative;
`;

const StatsWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.setSpacing(6)}px;
  top: 27px;
  display: flex;
  align-items: center;
`;

const Item = ({ item, handleChange }) => {
  return <Checkbox key={item.key} checked={item.show} handleChange={() => handleChange(item.key)} label={item.label} />;
};

Item.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    show: PropTypes.bool,
    sortable: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

const ColumnSelectorModal = ({ onCancel, onConfirm, columns, device, localStorageKey }) => {
  const [selectedColumns, setSelectedColumns] = useState(columns.all);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialColumns = [...columns.all];
    initialColumns.forEach(item => {
      const activeColumns = device ? columns[device] : columns.all;
      item.show = !!activeColumns.find(col => col.key === item.key);
    });
    setSelectedColumns(initialColumns);
  }, [columns, device]);

  const onCheck = itemKey => {
    setSelectedColumns(
      selectedColumns.map(item => {
        if (item.key === itemKey) {
          return { ...item, show: !item.show };
        }
        return item;
      }),
    );
  };

  const onDragEnd = list => {
    setSelectedColumns(list);
  };

  return (
    <BaseModal
      title="Edit columns"
      onCancel={onCancel}
      onConfirm={() => {
        if (selectedColumns?.filter(col => col.show).length) {
          onConfirm(selectedColumns, onCancel, localStorageKey);
        } else {
          dispatch(setNotification({ type: "error", message: "Select at least on column" }));
        }
      }}
      contentStyle={css`
        overflow: scroll;
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
          height: 7px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 0px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      `}
      showScrollBar
    >
      <StatsWrapper>{`${selectedColumns?.filter(col => col.show).length}/ ${selectedColumns?.length}`}</StatsWrapper>
      <Container>
        <DnD list={selectedColumns} element={<Item handleChange={onCheck} />} idVar="key" onDragEnd={onDragEnd} />
      </Container>
    </BaseModal>
  );
};

ColumnSelectorModal.propTypes = {
  columns: PropTypes.shape({
    all: PropTypes.arrayOf(PropTypes.shape()),
    defaultDevice: PropTypes.string,
    desktop: PropTypes.arrayOf(PropTypes.shape()),
    mobile: PropTypes.arrayOf(PropTypes.shape()),
    tablet: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired,
  localStorageKey: PropTypes.string.isRequired,
};

export default ColumnSelectorModal;
