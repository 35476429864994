import { DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Switch from "components/_new/Switch";
import TableAction from "components/_new/Table/Action";
import React from "react";

export default ({ onDelete, isLoading }) => [
  {
    id: "email",
    label: "Email",
    align: "left",
    renderCell: data => data.user.email,
  },
  {
    id: "actions",
    label: "Read",
    align: "left",
    isAction: true,
    minWidth: 300,
    renderCell: data => (
      <div style={{ display: "flex" }}>
        <Switch checked={data?.vamoos_permissions === "r" || data.vamoos_permissions === "cru"} disabled />
      </div>
    ),
  },
  {
    id: "actions",
    align: "left",
    label: "Write",
    isAction: true,
    minWidth: 300,

    renderCell: data => (
      <div style={{ display: "flex" }}>
        <Switch checked={data?.vamoos_permissions === "w" || data.vamoos_permissions === "cru"} disabled />
      </div>
    ),
  },
  {
    id: "actions",
    align: "left",
    isAction: true,
    renderCell: data => (
      <TableAction element={isLoading ? <CircularProgress size={16} /> : <DeleteOutline />} onClick={() => !isLoading && onDelete(data)} />
    ),
  },
];
