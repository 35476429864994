import { DeleteOutline } from "@mui/icons-material";
import { colors } from "config/theme/colors";
import React from "react";
import { use100vh } from "react-div-100vh";

import ListItem from "./ListItem";
import { navigations } from "./config";
import { Wrap } from "./style";

const ItineraryNavigation = ({
  type = "trip",
  isActive = true,
  toggleEraseModal,
  errors,
  hasAdditionalMenu,
  isDefaultStayLanguage,
  action,
}) => {
  const height100vh = use100vh();

  const shouldShowOnNewItinerary = item => (item.existingItineraryOnly && action === "create" ? false : true);

  return (
    <Wrap hasAdditionalMenu={hasAdditionalMenu} height100vh={height100vh} type={type}>
      <div>
        {navigations[type].map(item => {
          return (
            shouldShowOnNewItinerary(item) &&
            (!item.defaultLangOnly || action === "create" || (item.defaultLangOnly && isDefaultStayLanguage)) && (
              <ListItem key={item.key} path={item.path} errors={errors} {...item} />
            )
          );
        })}
        {action === "edit" && isActive ? (
          <ListItem
            icon={<DeleteOutline />}
            label={`Delete ${type}`}
            as="div"
            style={{ cursor: "pointer", borderTop: `1px solid ${colors.grey10}` }}
            onClick={toggleEraseModal}
          />
        ) : null}
      </div>
    </Wrap>
  );
};

export default ItineraryNavigation;
