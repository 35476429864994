import React, { cloneElement } from "react";
import { Draggable } from "react-beautiful-dnd";
import DndItemWrap from "./dndItem";

const Drag = ({ itemKey, draggableId, wrapStyle, contentStyle, disabled, index, children }) => {
  return (
    <Draggable key={itemKey} draggableId={draggableId} index={index}>
      {provided => (
        <DndItemWrap
          ref={provided.innerRef}
          dragHandleProps={provided.dragHandleProps}
          draggableProps={provided.draggableProps}
          wrapStyle={wrapStyle}
          contentStyle={contentStyle}
          disabled={disabled}
        >
          {children}
        </DndItemWrap>
      )}
    </Draggable>
  );
};

export default Drag;
