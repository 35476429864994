import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { Message, NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import AccessSettingInput from "../../../components/AccessInput/index";
import DirectoryModal from "../../../components/Modal/modal";
import Item from "./item";

const Vouchers = ({ form: { control, watch } }) => {
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fields, replace, update, append, remove, move } = useFieldArray({
    name: "directories.voucher",
    control,
    keyName: "rf_id",
  });
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  return (
    <Wrapper slimTopPadding>
      <AccessSettingInput control={control} name="general.meta.show_vouchers" />

      {isModalOpen && (
        <DirectoryModal
          append={append}
          onClose={() => {
            setIsModalOpen(false);
          }}
          title="Create new voucher"
          confirmButton="Save"
          type="voucher"
          isCreate
        />
      )}

      <TitleRow text="Vouchers" isSticky>
        <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={toggleModal} />
      </TitleRow>

      <div style={{ height: 20 }} />

      {fields.length ? (
        <DnD
          list={fields}
          element={
            <Item
              directories={fields}
              replace={replace}
              update={update}
              remove={remove}
              isItemModalOpen={isItemModalOpen}
              setIsItemModalOpen={setIsItemModalOpen}
              control={control}
              watch={watch}
            />
          }
          contentStyle={{ padding: 0 }}
          onDragEnd={onDragEnd}
          disabled={isItemModalOpen}
        />
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding vouchers`}</NoResultsMessage>
      )}
      {fields.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <Message text="To choose a different icon, go to Shared and select Menus and labels." type="info" />
        </div>
      )}
    </Wrapper>
  );
};

export default Vouchers;
