import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";

import { ContextBar } from "components/templates/_shared/ContextBar/ContextBar";
import { Navigation } from "components/templates/_shared/Navigation/Navigation";

import { PanelTopBar } from "components/templates/Panel/PanelTopBar";
import {
  StyledWrapper,
  MainContentWrapper,
  LoadingScreenWrapper,
  StyledSpinner,
  MessageWrapper,
} from "components/templates/_shared/styledComponents";

import { BodyText2 } from "components/ui/Typography/Typography";
import { Message } from "components/ui/Messages";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";

import { PANEL_USER_SETTINGS } from "constants/routes";
import { SUSPENDED_ACCOUNT_MESSAGE, USER_WITHOUT_OPERATOR_MESSAGE } from "constants/content";
import { PanelTemplateContext } from "./PanelTemplateContext";
import { ConditionalWrapper } from "../../ui/Inputs/conditionalWrapper";
import { deviceType } from "../../../utils/deviceDetect";

const AppContainer = styled(Grid)`
  height: 100vh;
  position: absolute;
`;

const TopBarContainer = styled(Grid)`
  position: sticky;
  top: 0;
  z-index: 3;
  flex-basis: 0 !important;
`;

const ContentContainer = styled(Grid)`
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 1;
`;

/**
 *
 * @param {Object}    props                       Props of PanelTemplate
 * @param {Object}    props.contextBar            Flag for context bar visibility
 * @param {Object=}    props.localLanguagesErrors  Local language errors
 * @param {any}       props.navigation            JSX object for sidebar navigation
 * @param {boolean=}   props.languageSelector      Flag for language selector on ribbon
 * @param {Array=}     props.localLanguages        Array of languages related to local resource (e.g. stay)
 * @param {Function=}  props.onLanguagesChange     Listener for local languages save logic
 * @param {Function=}  props.onLanguageSwitch     Listener for local languages switch
 * @param {boolean=}   props.editableLanguages      Flag for language selector modal
 * @param {boolean}   props.noMargin
 * @param {boolean}   props.whiteBg
 * @param {boolean}   props.hasPermission         Flag which indicates if user can see children
 * @param {boolean}   props.canSwitchLanguage         Flag which indicates if lang selector can swicth lang
 * @returns
 */
const PanelTemplate = ({
  children,
  contextBar,
  navigation,
  languageSelector,
  localLanguages,
  localLanguagesErrors,
  onLanguagesChange,
  onLanguageSwitch,
  editableLanguages,
  noMargin,
  whiteBg,
  hasPermission,
  canSwitchLanguage,
  hasStickyPanel,
  styleWrapper,
  customContextBar,
}) => {
  const operatorChangeProcess = useSelector(state => state.app.operatorChangeProcess);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const operators = useSelector(state => state.auth.operators);
  const { pathname } = useLocation();
  const isMobile = deviceType() === "mobile";

  const contextAreaRef = useRef();

  const isOperatorActive = operators?.find(({ code }) => code === currentOperatorCode)?.isActive;

  const isAnyOperatorAssigned = operators.length > 0;
  const isPageContentVisible = isOperatorActive || (!isOperatorActive && pathname === PANEL_USER_SETTINGS);

  const inactiveOperatorMessage = (
    <MessageWrapper>
      <Message type="warning" text={SUSPENDED_ACCOUNT_MESSAGE} />
    </MessageWrapper>
  );

  const operatorMissingMessage = (
    <MessageWrapper>
      <Message type="warning" icon={<InfoOutlined />} text={USER_WITHOUT_OPERATOR_MESSAGE} />
    </MessageWrapper>
  );

  const displayMessage = isAnyOperatorAssigned ? inactiveOperatorMessage : operatorMissingMessage;

  const context = {
    scrollToElement: el => {
      contextAreaRef.current.scrollTo({ top: el.offsetTop - contextAreaRef.current.offsetHeight + el.offsetHeight, behavior: "smooth" });
    },
  };

  return (
    <PanelTemplateContext.Provider value={context}>
      <StyledWrapper whiteBg={!!navigation || whiteBg} isMobile={isMobile}>
        {operatorChangeProcess && (
          <LoadingScreenWrapper>
            <StyledSpinner size={60} />
            <BodyText2 cv="grey50">Loading operator data..</BodyText2>
          </LoadingScreenWrapper>
        )}
        <ConditionalWrapper
          condition={!isMobile}
          wrapper={children => (
            <AppContainer direction="column" wrap="nowrap" container spacing={0}>
              {children}
            </AppContainer>
          )}
        >
          <TopBarContainer item xs={12}>
            <PanelTopBar />
            {isPageContentVisible && contextBar && typeof contextBar === "object" && !customContextBar && (
              <ContextBar
                leftSlot={contextBar.left}
                middleSlot={contextBar.middle}
                rightSlot={contextBar.right}
                languageSelector={languageSelector}
                localLanguages={localLanguages}
                localLanguagesErrors={localLanguagesErrors}
                onLanguagesChange={onLanguagesChange}
                onLanguageSwitch={onLanguageSwitch}
                editableLanguages={editableLanguages}
                canSwitchLanguage={canSwitchLanguage}
              />
            )}
            {customContextBar && customContextBar()}
          </TopBarContainer>
          <ContentContainer container item xs={12} wrap="nowrap">
            {hasPermission ? (
              <>
                {isOperatorActive && navigation && <Navigation content={navigation} />}
                {isPageContentVisible && (
                  <MainContentWrapper id="content-container" ref={contextAreaRef} noMargin={noMargin} hasStickyPanel={hasStickyPanel} style={styleWrapper}>
                    {children}
                  </MainContentWrapper>
                )}
                {!isPageContentVisible && displayMessage}
              </>
            ) : (
              <AccessDenied />
            )}
          </ContentContainer>
        </ConditionalWrapper>
      </StyledWrapper>
    </PanelTemplateContext.Provider>
  );
};

PanelTemplate.defaultProps = {
  contextBar: null,
  children: null,
  navigation: null,
  noMargin: false,
  whiteBg: false,
  hasPermission: true,
};

PanelTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  contextBar: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  navigation: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  noMargin: PropTypes.bool,
  whiteBg: PropTypes.bool,
  hasPermission: PropTypes.bool,
};

export { PanelTemplate };
