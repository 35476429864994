import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { GLOBAL_CONTENT } from "constants/content";
import Input from "../Inputs/TextInput";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "300px")};
  justify-content: center;
  align-items: center;

  .MuiInputBase-root {
    background: white;
  }

  label {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }

  :hover,
  &.Mui-focused {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }
`;

export const CloseSearchButton = styled(InputAdornment)`
  && {
    cursor: pointer;
  }
`;

const SearchField = ({ fullWidth, onSearchChange, label, ...rest }) => {
  const [value, setValue] = useState("");
  const handleValueChange = (newValue) => {    
    setValue(newValue);
    onSearchChange(newValue);
  };
  const handleReset = () => handleValueChange({ target: { value: "" } });

  const inputSearchIcon = value ? <CloseOutlinedIcon /> : <SearchIcon />;
  const closeButton = (
    <CloseSearchButton position="end" onClick={handleReset}>
      {inputSearchIcon}
    </CloseSearchButton>
  );

  return (
    <StyledWrapper fullWidth={fullWidth}>
      <Input onChange={handleValueChange} value={value} label={label} />
    </StyledWrapper>
  );
};

SearchField.defaultProps = {
  fullWidth: false,
  label: GLOBAL_CONTENT.search,
};

SearchField.propTypes = {
  fullWidth: PropTypes.bool,
  onSearchChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export { SearchField, StyledWrapper };
