import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { UserMenu } from "components/templates/Panel/UserMenu";
import { MainNavigation } from "components/templates/Panel/MainNavigation";

import { Logotype } from "components/templates/_shared/Logotype/Logotype";
import { TopBar } from "components/templates/_shared/TopBar/TopBar";
import { ToolbarSection, MainNavigationArea, ActionsArea } from "components/templates/_shared/styledComponents";
import { OperatorSelector } from "components/templates/_shared/OperatorSelector/OperatorSelector";
import { PANEL_ROOT_PATH } from "constants/routes";
import styled from "styled-components";
import Hamburger from "hamburger-react";
import { colors } from "config/theme/colors";
import MobileMenu from "./MobileMenu";
import { responsiveConfig } from "../../../config/responsive";

const HamburgerWrap = styled.div`
  margin-left: 5px;
  display: none;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    display: block;
  }
`;

const PanelTopBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  const { operators, currentOperatorCode } = useSelector(state => state.auth);
  const isOperatorActive = operators?.find(({ code }) => code === currentOperatorCode)?.isActive;

  const handleToggleUserMenu = () => {
    setOpenUserMenu(!openUserMenu);
  };

  const handleCloseUserMenu = event => {
    const { current } = userMenuRef;
    if (current && current.contains(event.target)) {
      return;
    }
    setOpenUserMenu(false);
  };

  const toggleMenu = () => {
    if (!isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setIsOpen(!isOpen);
  };

  return (
    <>
      <TopBar>
        <Logotype redirectUrl={PANEL_ROOT_PATH} />

        <MainNavigationArea>{isOperatorActive && <MainNavigation />}</MainNavigationArea>

        <ActionsArea>
          <OperatorSelector />
          <ToolbarSection marginRight="3">
            <IconButton
              ref={userMenuRef}
              edge="end"
              aria-label="account of current user"
              aria-controls=""
              aria-haspopup="true"
              color="inherit"
              onClick={handleToggleUserMenu}
            >
              <AccountCircle />
            </IconButton>
            <UserMenu open={openUserMenu} anchor={userMenuRef} onClose={handleCloseUserMenu} />
            <HamburgerWrap>
              <Hamburger toggled={isOpen} toggle={toggleMenu} color={colors.brand} size={28} hideOutline />
            </HamburgerWrap>
          </ToolbarSection>
        </ActionsArea>
      </TopBar>
      <MobileMenu open={isOpen} isOperatorActive={isOperatorActive} />
    </>
  );
};

export { PanelTopBar, ToolbarSection, MainNavigationArea };
