import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled, { css } from "styled-components";

const commonCss = css`
  border: 1px solid ${colors.grey10};
`;

export const Lines = styled.div`
  ${commonCss};
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
  &:last-child {
    border-bottom: 1px solid ${colors.grey10};
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    min-height: 65px;
    width: 40px;
  }
`;

export const Content = styled.div`
  ${commonCss}
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  border-left: 0;
  border-bottom: 0;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    min-height: 65px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  position: relative;
  min-height: 65px;
  &:last-child {
    ${Lines}, ${Content} {
      border-bottom: 1px solid ${colors.grey10};
    }
  }
`;
