import React from "react";

import { ListItem } from "./ListItem";

export const SortableListItem = ({ item, renderItem, index, ...props }) => {
  return (
    <ListItem {...props}>
      {renderItem(item, index)}
    </ListItem>
  )
}