import React from "react";
import AttachButton from "components/_new/Buttons/AttachButton";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { Controller } from "react-hook-form";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { ImageOutlined } from "@mui/icons-material";
import { MobileOnly } from "feature/panel/Itinerary/responsive";
import CloseIcon from "@mui/icons-material/Close";
import { MessageWrapper } from "components/templates/_shared/styledComponents";
import { Message } from "components/ui/Messages";
import { ERRORS } from "constants/content";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { Image, Info, Wrap } from "./style";

const Preview = ({ control, image, resetField, open, onClose, watch, error, disabled, shouldFade }) => {
  const url = image?.preview_url || image?.https_url || image?.file?.https_url || image?.url;
  const field1 = watch("general.field1");
  const field3 = watch("general.field3");
  const homeDesc = watch("general.meta.home_screen_description");

  const handleDelete = () => resetField("general.background");
  return (
    <Wrap open={open}>
      <MobileOnly>
        <CloseIcon onClick={onClose} style={{ position: "absolute", top: 80, right: 15, color: "#fff", zIndex: 5 }} />
      </MobileOnly>

      <Image style={{ opacity: shouldFade ? 0.6 : 1 }} bg={url}>
        <Info style={{ color: image ? "#fff" : "#8d9095" }}>
          {homeDesc ? (
            homeDesc
          ) : (
            <>
              <div>{field1}</div>
              <div>{field3}</div>
            </>
          )}
        </Info>
      </Image>
      {error && (
        <MessageWrapper style={{ margin: 0, marginBottom: 25, height: "auto" }}>
          <Message text={ERRORS.isRequired("Background")} type="error" />
        </MessageWrapper>
      )}

      <Controller
        control={control}
        name="general.background"
        render={({ field: { onChange, value } }) => (
          <AttachButton
            allowedFileTypes={IMAGES_MIME_TYPES}
            onConfirm={onChange}
            onDelete={() => onChange(null)}
            file={value}
            buttonWrapStyle={{ textAlign: "center" }}
            text="Add image"
            disabled={disabled}
            mobileFullScreen
            hideUrl
          />
        )}
      />
    </Wrap>
  );
};

export default Preview;
