import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrap = styled.div`
  width: 100vw;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
  border-bottom: 1px solid ${({ hasAdditionalMenu }) => (hasAdditionalMenu ? "#eaeaea" : "transparent")};
  position: sticky;
  top: 64px;
  border-bottom: 1px solid ${colors.grey10};
  background-color: #fff;
  @media (max-width: ${p => getMediaPx(p, "xs")}px) {
    height: 55px;
    * {
      zoom: 0.93;
    }
  }
`;

export const SettingsLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.colors.brand};
    font-weight: bold;
  }
`;
