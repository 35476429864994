import React from "react";
import styled, { css } from "styled-components";
import { EditOutlined, FileCopyOutlined, HistoryOutlined } from '@mui/icons-material';

import { colors } from "config/theme/colors";
import moment from "moment";
import { StyledMuiTableRow } from "../../../../components/ui/Tables/_shared/styledComponents";
import { TextLimiter } from "../../../../components/ui/TextLimiter";
import {
  DEFAULT_CELL_MAX_CHARACTERS,
  MAXIMUM_USER_ID_LENGTH,
  MIN_CELL_MAX_CHARACTERS,
  MID_CELL_MAX_CHARACTERS,
} from "../../../../constants/defaults";
import config from "../../../../config/app";
import { Chip } from "../../../../components/ui/Chips";
import { TABLE_ACTIONS_TITLES } from "../../../../constants/content";
import TableAction from "../../../../components/_new/Table/Action";
import { PERMISSIONS } from "../../../../constants/permissions";
import { toggleTripActiveStatusStart } from "../../../../store/trips/actions";

const headEditAction = actionFn => {
  return (
    <div style={{ display:"flex", alignItems: "center", justifyContent: "center" }}>
      <TableAction
        onClick={() => actionFn()}
        element={<EditOutlined />}
        wrapStyle={{ padding: 0, borderLeft: "none", height: 72, backgroundColor: "white" }}
      />
    </div>
  )
};

export const generateColumns = (storedTripsColumns, device, permissionsService, dispatch, { duplicateTrip, openColumnSelector }) => {
  const canCreateVamoos = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const handleTripRestore = async ({ reference_code, operator_code }) => {
    dispatch(toggleTripActiveStatusStart({ reference_code, operator_code, dispatcher: dispatch }));
  };

  const isDesktop = device === "desktop";
  const displayColumns = storedTripsColumns[device] ?? storedTripsColumns.all;

  const columns = [
    {
      id: "field1",
      label: "Destination / Event title",
      minWidth: 230,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ field1 }) => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={field1} /> : null,
    },
    {
      id: "field3",
      label: "Name / Location",
      minWidth: 180,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ field3 }) => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={field3} /> : null,
    },
    {
      id: "operator_code",
      label: "User ID",
      minWidth: 100,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ operator_code }) =>
        <TextLimiter maxChar={MAXIMUM_USER_ID_LENGTH} text={operator_code} /> : null,
    },
    {
      id: "reference_code",
      label: "Passcode",
      minWidth: 110,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ reference_code }) =>
        <TextLimiter maxChar={MID_CELL_MAX_CHARACTERS} text={reference_code} /> : null,
    },
    {
      id: "client_reference",
      label: "Client REF",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ client_reference }) =>
        <TextLimiter maxChar={MID_CELL_MAX_CHARACTERS} text={client_reference} /> : null,
    },
    {
      id: "departure_date",
      label: "Start date",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: ({ departure_date }) => {
        const momentData = moment(departure_date, config.apiDateFormat);
        return (momentData.isValid() ? momentData.format(config.dateFormat) : departure_date);
      },
    },
    {
      id: "start_time",
      label: "Start time",
      minWidth: 100,
      align: "left",
      sortable: false,
    },
    {
      id: "timezone",
      label: "Time zone",
      align: "left",
      sortable: true,
    },
    {
      id: "return_date",
      label: "End date",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: ({ return_date }) => {
        const momentData = moment(return_date, config.apiDateFormat);
        return (momentData.isValid() ? momentData.format(config.dateFormat) : return_date);
      },
    },
    {
      id: "downloads",
      label: "Downloads",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ downloads }) => <Chip label={downloads || 0} /> : null,
    },
    {
      id: "updated_at",
      label: "Last updated",
      minWidth: 180,
      align: "left",
      sortable: true,
      renderCell: ({ updated_at }) => moment(updated_at).format(config.dateTimeFormat),
    },
    {
      id: "original_created_at",
      label: "Created",
      minWidth: 180,
      align: "left",
      sortable: true,
      renderCell: ({ original_created_at }) => moment(original_created_at).format(config.dateTimeFormat),
    },
  ];

  const actionButton = {
    id: "action_button",
    label: headEditAction(openColumnSelector),
    isAction: true,
    maxWidth: 72,
    headerCellStyle: { padding: 0, width:72, height: 72 },
    renderCell: data => {
      const canUpdateTrip = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, data.vamoos_id);
      const restoreButton = canUpdateTrip && !data.is_active && (
        <TableAction
          tooltip={TABLE_ACTIONS_TITLES.restoreResource(TABLE_ACTIONS_TITLES.resourceTypes.trip)}
          onClick={() => handleTripRestore(data)}
          element={<HistoryOutlined style={{ color: "#f5313d" }} />}
          disabled={!canUpdateTrip}
          wrapStyle={{  backgroundColor: colors.withOpacity(colors.errorLight, 0.2), border: "none" }}
        />
      );

      const copyButton = canCreateVamoos ? (
        <TableAction
          tooltip={TABLE_ACTIONS_TITLES.duplicateResource(TABLE_ACTIONS_TITLES.resourceTypes.trip)}
          onClick={() => duplicateTrip(data)}
          element={<FileCopyOutlined />}
        />) : null;

      if (data.is_wiped) return null;
      return !data.is_active ? restoreButton : copyButton;
    }
  };

  const reorderedColumns = displayColumns.reduce((acc, displayColumn) => {
    const foundColumn = columns.find(column => displayColumn.key === column.id);
    if (foundColumn) {
      acc.push(foundColumn);
    }
    return acc;
  }, []);

  return [...reorderedColumns, actionButton];
};

export const StyledTableRow = styled(({ item, ...props }) => <StyledMuiTableRow {...props} />)`
  && {
    ${({ archived, theme }) =>
            archived === "true"
                    ? css`
                      background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.1)};

                      :hover {
                        background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.2)};
                      }

                      a {
                        color: ${theme.colors.errorLight};
                      }

                      .MuiChip-root {
                        border-color: ${theme.colors.errorLight};
                        color: ${theme.colors.errorLight};
                      }
                    `
                    : ""}
  }
`;