import React from "react";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { colors } from "config/theme/colors";
import { ArrowForwardIos } from "@mui/icons-material";
import { LIBRARY_ROOT_PATH } from "constants/api";

const Breadcrumbs = ({ currentPath, setCurrentPath }) => {
  const getBreadcrumbs = () => {
    const breadcrumbs = currentPath?.split("/");
    return breadcrumbs.slice(1);
  };

  const onClickBreadCrumb = breadcrumb => {
    const findIndex = getBreadcrumbs().findIndex(item => item === breadcrumb);
    const urlString = getBreadcrumbs()
      .slice(0, findIndex + 1)
      .join("/");
    localStorage.setItem("libraryPath", "/" + urlString);
    setCurrentPath("/" + urlString);
  };

  const isActiveBreadcrumb = breadcrumb => getBreadcrumbs()[getBreadcrumbs()?.length - 1] === breadcrumb;
  return (
    getBreadcrumbs()?.length > 0 && (
      <MuiBreadcrumbs separator={<ArrowForwardIos style={{fontSize: 12}} />}>
        {getBreadcrumbs().map(item => (
          <div
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: isActiveBreadcrumb(item) && item.toUpperCase() !== "LIBRARY" ? colors.brand : "#313840",
              cursor: "pointer",
              textTransform: "uppercase",
              fontFamily: "Montserrat",
              maxWidth: 150,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => onClickBreadCrumb(item)}
          >
            {item}
          </div>
        ))}
      </MuiBreadcrumbs>
    )
  );
};

export default Breadcrumbs;
