import React, { useEffect, createContext, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { logoutStart, getCurrentUserStart, setComposerData, setCurrentOperatorCode, getCurrentUserSuccess } from "store/auth/actions";
import { AUTH_STATUSES } from "store/auth/reducer";
import { getCurrentOperatorSuccess, getIconsListStart } from "store/operator/actions";
import { tryRouteChangeFinish, tryRouteChangeStart } from "store/app/actions";
import { OperatorMapper } from "mappers/OperatorMapper";

import { PANEL_SETTINGS_CONNECT, PANEL_USER_SETTINGS } from "constants/routes";
import { StorageService } from "services/StorageService";
import { TabSessionService } from "services/TabSessionService";
import { useQuery } from "@tanstack/react-query";
import { AuthService } from "services/AuthService";
import { getComposerData } from "../config/auth";

export const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

export const TOKEN_NOT_FOUND_STATUS = "TOKEN_NOT_FOUND_STATUS";

const AuthVerification = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabSessionService = new TabSessionService(new StorageService());
  const authService = new AuthService();
  const operatorMapper = new OperatorMapper();

  const [token, setToken] = useState(null);
  const { authStatus, currentOperatorCode, composerData, operators, finished, isInitialAuth, requireVerification } = useSelector(
    state => state.auth,
  );
  const redirectPath = useSelector(state => state.app.redirectPath);

  const dispatch = useDispatch();
  
  useQuery({
    queryKey: ["/operator"],
    onSuccess: data => {
      dispatch(setCurrentOperatorCode(data.code));
      dispatch(getCurrentOperatorSuccess(operatorMapper.fromDtoToDomain(data)));
    },
  });

  useQuery({
    queryKey: ["/user/me"],
    enabled: currentOperatorCode ? true : false,
    onSuccess: data => {
      const user = authService.userMapper.fromDtoToDomain(data);
      dispatch(getCurrentUserSuccess({ user, operators: user.operators, token }));
    },
  });

  const logout = (intended = false) => dispatch(logoutStart(intended));

  const getUserData = () => {
    if (token && token !== TOKEN_NOT_FOUND_STATUS && !requireVerification) {
      dispatch(getCurrentUserStart());
    }
  };

  const checkTokenExists = () => {
    tabSessionService
      .getToken()
      .then(tokenFromStorage => {
        setToken(tokenFromStorage || TOKEN_NOT_FOUND_STATUS);
      })
      .catch(() => {
        setToken(TOKEN_NOT_FOUND_STATUS);
      });
  };

  const getIconsList = () => {
    if (authStatus === AUTH_STATUSES.success && !requireVerification) {
      dispatch(getIconsListStart());
    }
  };

  const checkVamoosConnectRedirection = () => {
    const isRedirectPathNotNull = redirectPath !== null;
    const isRedirectPathDifferenThanCurrent = redirectPath !== location.pathname;

    if (redirectPath === PANEL_SETTINGS_CONNECT && isRedirectPathNotNull && isRedirectPathDifferenThanCurrent) {
      navigate(PANEL_SETTINGS_CONNECT);
    }

    if (!isRedirectPathDifferenThanCurrent && !isRedirectPathNotNull && redirectPath === PANEL_SETTINGS_CONNECT) {
      dispatch(tryRouteChangeFinish());
    }

    if (redirectPath === PANEL_USER_SETTINGS && isRedirectPathNotNull && isRedirectPathDifferenThanCurrent) {
      navigate(PANEL_USER_SETTINGS);
    }
  };

  useEffect(checkTokenExists, []);
  useEffect(getIconsList, [authStatus]);
  useEffect(getUserData, [token]);
  useEffect(checkVamoosConnectRedirection, [redirectPath]);
  useEffect(() => {
    if (composerData) return;
    const data = getComposerData();
    dispatch(setComposerData(data));
  }, [composerData]);
  // set redirect path to /user-settings when user without operators and not the initial auth
  useEffect(() => {
    if (!finished) return;
    if (operators.length === 0 && !isInitialAuth) {
      dispatch(tryRouteChangeStart(PANEL_USER_SETTINGS));
    }
  }, [finished, operators]);

  const isAuthLoading = !token || (token !== TOKEN_NOT_FOUND_STATUS && authStatus === AUTH_STATUSES.loading);

  return (
    <AuthContext.Provider value={{ logout, currentOperatorCode }}>{isAuthLoading ? <LoadingScreen /> : children}</AuthContext.Provider>
  );
};

AuthVerification.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthVerification };
