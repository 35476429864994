import React, { useEffect, useState } from "react";
import { Message } from "components/ui/Messages";
import { INDIVIDUAL_SETTINGS } from "constants/content";

import BaseModal from "../ModalBase";
import Checkbox from "../Checkbox";
import Switch from "../Switch";

const TravellersModal = ({ people, onConfirm, toggleModal, disabled, selectedPeopleIds, setSelectedPeopleIds }) => {
  const [initialPeopleIds, setInitialPeopleIds] = useState(null); // used when user cancels changes
  const [isFormTouched, setIsFormTouched] = useState(selectedPeopleIds !== null);
  const enablePersonalisation = people?.length > 1; // to enable personalisation, user must add at least two people

  useEffect(() => {
    setInitialPeopleIds(selectedPeopleIds);

    return () => {
      setIsFormTouched(false);
      setInitialPeopleIds(null);
    };
  }, []);

  const handleChange = (checked, id) => {
    setIsFormTouched(true);
    if (checked) {
      setSelectedPeopleIds([...selectedPeopleIds, id]);
    } else {
      setSelectedPeopleIds(selectedPeopleIds.filter(item => item !== id));
    }
  };

  const handleConfirm = () => {
    const updatedTravellerInternalIds = selectedPeopleIds ? [] : null;

    if (selectedPeopleIds) {
      selectedPeopleIds?.forEach(id => {
        const found = people.find(person => person.id === id);
        if (found) updatedTravellerInternalIds.push(...found.travellersData.map(data => data.internal_id));
      })
    }

    setInitialPeopleIds(null);
    onConfirm(updatedTravellerInternalIds);
  };

  const toggleCheckboxSelection = () => {
    setIsFormTouched(true);
    setSelectedPeopleIds(selectedPeopleIds === null ? [] : null);
  };

  const onCancel = () => {
    setSelectedPeopleIds(initialPeopleIds);
    setInitialPeopleIds(null);
    toggleModal()
  };

  return (
    <BaseModal
      title={INDIVIDUAL_SETTINGS.title}
      confirmTitle={enablePersonalisation ? "Apply" : "Ok"}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      hideCancel={!enablePersonalisation}
      confirmDisabled={enablePersonalisation && !isFormTouched}
    >
      {enablePersonalisation ? (
        <>
          {" "}
          {!disabled && (
            <Checkbox
              label={INDIVIDUAL_SETTINGS.checkboxLabel}
              checked={selectedPeopleIds !== null}
              name="showForSelected"
              wrapStyle={{ marginTop: 15 }}
              onChange={toggleCheckboxSelection}
            />
          )}
          {people?.map(item => (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #eaeaea",
                padding: "10px 0",
                opacity: selectedPeopleIds !== null ? 1 : 0.3,
              }}
            >
              {item.name || "\u200B"}
              <Switch
                checked={selectedPeopleIds?.includes(item.id)}
                onChange={e => handleChange(e, item.id)}
                disabled={selectedPeopleIds === null}
              />
            </div>
          ))}
        </>
      ) : (
        <div style={{ marginTop: 25 }}>
          <Message type="info" text={INDIVIDUAL_SETTINGS.enableWarningDesc} />
        </div>
      )}
    </BaseModal>
  );
};

export default TravellersModal;