import styled from "styled-components";

export const ContentArea = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;

 
  width: calc(100% - ${({ theme }) => theme.setSpacing(12)}px);
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ align }) => align || "center"};
  padding: 8px ${({ theme }) => theme.setSpacing(3)}px 8px 0;

  ${({ isDragged }) => (isDragged ? "opacity: 0.5;" : null)}
`;