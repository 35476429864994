import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Input from "components/ui/Inputs/TextInput";
import SelectInput from "components/ui/Inputs/Select";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import moment from "moment";
import { HttpClient } from "services/application/httpClient/httpClient";
import FlightSelectItem from "./FlightSelectItem";
import { Bottom, TopGrid } from "./style";
import NotFound from "./notFound";
import ManualForm from "./ManualForm";

const AddFlightForm = ({ onFlightSelect, control, handleSubmit, watch, showManualForm, setShowManualForm, departureDate }) => {
  const flightNumberRef = useRef();
  const todaysDate = moment(new Date());
  const dateDiff = todaysDate.diff(departureDate, "days");

  const airline = watch("airline");
  const flightNumber = watch("flight_number");
  const [serchableFlight, setSearchableFlight] = useState(null);
  const [flights, setFlights] = useState([]);
  const [showNotFound, setShowNotFound] = useState(false);

  const { data: airlines } = useQuery({
    queryKey: ["/flight/airlines"],
    select: data => data?.map(item => ({ label: `${item.name} (${item.iata || item.fs})`, value: item.iata || item.fs })),
  });

  const { mutate, isLoading } = useMutation(
    ({ airline, flight_number, flight_date }) => {
      return HttpClient.get(`/flight/find_legs/${airline}/${flight_number}/${moment(flight_date).format("YYYY-MM-DD")}`);
    },
    {
      onSuccess: (res, { airline, flight_number }) => {
        if (res.data.length) {
          setFlights(res.data);
        } else {
          setSearchableFlight(`${airline} ${flight_number}`);
          setFlights([]);
          setShowNotFound(true);
        }
      },
    },
  );
  const onSubmit = values => {
    setShowNotFound(false);
    setSearchableFlight(null);
    mutate(values);
  };

  const handleShowManualForm = () => {
    setShowManualForm(true);
    setSearchableFlight(null);
    setShowNotFound(false);
  };

  const handleFlightSelect = (isSelected, flight) => {
    onFlightSelect(isSelected, { ...flight, isManual: showManualForm });
  };

  return (
    <>
      <TopGrid style={{ marginTop: 15, marginBottom: 10 }} noSearch={showManualForm}>
        <SelectInput
          autoFocus
          control={control}
          options={airlines || []}
          name="airline"
          label="Airline"
          type="creatable"
          nextInputRef={flightNumberRef}
          rules={{ required: true }}
        />
        <Input
          control={control}
          name="flight_number"
          label="Flight number"
          ref={flightNumberRef}
          type="number"
          rules={{
            required: true,
            min: {
              value: 0,
              message: "Flight number must be greater than zero",
            },
          }}
        />

        <DateTimeInput
          control={control}
          name="flight_date"
          label="Flight date"
          defaultValue={dateDiff > 0 ? moment(new Date()) : departureDate || moment(new Date())}
          rules={{ required: true }}
        />
        {!showManualForm && (
          <OutlinedButton text="Search" onClick={handleSubmit(onSubmit)} isLoading={isLoading} disabled={!airline || !flightNumber} />
        )}
      </TopGrid>
      <Bottom>
        {flights && flights.map(item => <FlightSelectItem data={item} onSelect={handleFlightSelect} key={item._id} />)}
        {showNotFound && <NotFound flight={serchableFlight} onClick={handleShowManualForm} />}
        {showManualForm && <ManualForm control={control} />}
      </Bottom>
    </>
  );
};

export default AddFlightForm;
