import React, { forwardRef } from "react";
import DraggableWrap from "../Draggable";

const DndItemWrap = forwardRef(({ dragHandleProps, draggableProps, children, wrapStyle, contentStyle, disabled }, ref) => {
  return (
    <DraggableWrap
      ref={ref}
      dragHandleProps={dragHandleProps}
      draggableProps={draggableProps}
      wrapStyle={wrapStyle}
      contentStyle={contentStyle}
      disabled={disabled}
    >
      {children}
    </DraggableWrap>
  );
});

export default DndItemWrap;
