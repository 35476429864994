import React from "react";
import moment from "moment/moment";

import { EditOutlined, FileCopyOutlined, HistoryOutlined, MessageOutlined } from '@mui/icons-material';
import TableAction from "../../../../components/_new/Table/Action";
import { TABLE_ACTIONS_TITLES } from "../../../../constants/content";

import { PERMISSIONS } from "../../../../constants/permissions";
import { TextLimiter } from "../../../../components/ui/TextLimiter";
import {
  DEFAULT_CELL_MAX_CHARACTERS, MAXIMUM_USER_ID_LENGTH,
} from "../../../../constants/defaults";

import config from "../../../../config/app";
import { Chip } from "../../../../components/ui/Chips";
import { setUrl } from "../../../../utils/url";
import { PANEL_STAYS_EDIT_CONTENT_PATH, PANEL_TRIPS_CREATE_MESSAGING_PARAM } from "../../../../constants/routes";

export const fetchStays = async ({ data, staysService, setIsLoading }) => {
  const { page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery, requestParams } = data;

  setIsLoading(true);

  const res = await staysService.getStays(page, rowsPerPage, sortingOrder || "desc", sortingBy || "updated_at", showArchived, searchQuery, requestParams);

  setIsLoading(false);

  return res;
};

const headEditAction = actionFn => {
  return (
    <div style={{ display:"flex", alignItems: "center", justifyContent: "center" }}>
      <TableAction
        onClick={() => actionFn()}
        element={<EditOutlined />}
        wrapStyle={{ padding: 0, borderLeft: "none", height: 72, backgroundColor: "white" }}
      />
    </div>
  )
};

export const generateColumns = (storedStaysColumns, device, permissionsService, navigate, { duplicateStay, openColumnSelector, stayRestore }) => {
  const canCreateVamoos = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );
  const isDesktop = device === "desktop";
  const displayColumns = storedStaysColumns[device] ?? storedStaysColumns.all;

  const columns = [
    {
      id: "field1",
      label: "Property name",
      minWidth: 230,
      align: "left",
      sortable: true,
      renderCell: ({ field1 }) => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={field1} />,
    },
    {
      id: "address",
      label: "Address",
      minWidth: 230,
      align: "left",
      sortable: false,
    },
    {
      id: "operator_code",
      label: "User ID",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: ({ operator_code }) => <TextLimiter maxChar={MAXIMUM_USER_ID_LENGTH} text={operator_code} />,
    },
    {
      id: "updated_at",
      label: "Last updated",
      minWidth: 180,
      align: "left",
      sortable: true,
      renderCell: ({ updated_at }) => moment(updated_at).format(config.dateTimeFormat),
    },
    {
      id: "original_created_at",
      label: "Created",
      minWidth: 180,
      align: "left",
      sortable: true,
      renderCell: ({ original_created_at }) => moment(original_created_at).format(config.dateTimeFormat),
    },
    {
      id: "downloads",
      label: "Downloads",
      minWidth: 120,
      align: "left",
      sortable: true,
      renderCell: isDesktop ? ({ downloads }) => <Chip label={downloads || 0} /> : null,
    },
  ];

  const messagesButton = {
    id: "messages_button",
    label: "",
    isAction: true,
    maxWidth: 72,
    headerCellStyle: { padding: 0, width:72, height: 72 },
    renderCell: data => {
      if (!data?.is_active) return null;
      return (
          <TableAction
            tooltip={TABLE_ACTIONS_TITLES.openMessages}
            onClick={() => navigate(`/panel/itinerary/stay/edit/${data?.operator_code}/DEFAULT/messages`)}
            element={<MessageOutlined />}
          />
      );
    }
  };

  const actionButton = {
    id: "action_button",
    label: headEditAction(openColumnSelector),
    isAction: true,
    maxWidth: 72,
    headerCellStyle: { padding: 0, width:72, height: 72 },
    renderCell: data => {
      const canUpdateVamoosItem = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, data?.vamoos_id);
      const restoreButton = canUpdateVamoosItem && (
      <TableAction
        tooltip={TABLE_ACTIONS_TITLES.restoreResource(TABLE_ACTIONS_TITLES.resourceTypes.stay)}
        onClick={() => stayRestore(data)}
        element={<HistoryOutlined style={{ color: "#f5313d" }} />}
        disabled={!canUpdateVamoosItem}
        wrapStyle={{  backgroundColor: ({ theme }) => theme.colors.withOpacity(theme.colors.errorLight, 0.2), border: "none" }}
      />
      );

      const copyButton = canCreateVamoos ? (
        <TableAction
          tooltip={TABLE_ACTIONS_TITLES.duplicateResource(TABLE_ACTIONS_TITLES.resourceTypes.stay)}
          onClick={() => duplicateStay(data)}
          element={<FileCopyOutlined />}
        />) : null;

      if (data?.is_wiped) return null;
      return !data?.is_active ? restoreButton : copyButton;
    }
  };

  const reorderedColumns = displayColumns.reduce((acc, displayColumn) => {
    const foundColumn = columns.find(column => displayColumn.key === column.id);
    if (foundColumn) {
      acc.push(foundColumn);
    }
    return acc;
  }, []);

  return [...reorderedColumns, messagesButton, actionButton];
};