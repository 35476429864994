import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Table from "components/_new/Table";
import TravellersModal from "components/_new/TravellersModal";
import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import AddFlightModal from "./AddFlightModal";
import MobileItem from "./MobileItem";
import ViewFlightModal from "./ViewFlightModal";
import columns from "./columns";
import DeleteFlightModal from "./deleteFlightModal";

const Flights = ({ form: { control, watch }, canEdit, isWiped }) => {
  const fieldName = "flights.flights";

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteFlightModal, setShowDeleteFlightModal] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState({});
  const [isTravellersOpen, setIsTravellersOpen] = useState(false);
  const [restrictedFlightsDataByPerson, setRestrictedFlightsDataByPerson] = useState({});
  const [selectedPeopleIdsForSelectedFlight, setSelectedPeopleIdsForSelectedFlight] = useState(null);

  const { fields: flights, remove, append, update } = useFieldArray({
    control,
    name: fieldName,
    keyName: "flight_id",
  });
  const people = watch("people.travellers");
  const departureDate = watch("general.departure_date");

  const toggleShowAddModal = () => setShowAddModal(!showAddModal);
  const toggleShowDetailsModal = flight => {
    setSelectedFlight(flight);
    setShowDetailsModal(!showDetailsModal);
  };

  const toggleTravellersModal = () => {
    if (isTravellersOpen) setSelectedPeopleIdsForSelectedFlight(null);
    setIsTravellersOpen(!isTravellersOpen);
  };

  const toggleDeleteFlightModal = flight => {
    setSelectedFlight(flight || {});
    setShowDeleteFlightModal(!showDeleteFlightModal);
  };

  const handleTravellersModal = data => {
    setSelectedPeopleIdsForSelectedFlight(restrictedFlightsDataByPerson[data.id]);
    setSelectedFlight(data);
    toggleTravellersModal();
  };

  const onDelete = () => {
    remove(selectedFlight.index);
    setShowDeleteFlightModal(false);
    setSelectedFlight({});
  };

  const onTravellersChange = arr => {
    update(selectedFlight.index, { ...selectedFlight, restricted_to_traveller_internal_ids: arr });
    toggleTravellersModal();
  };

  useEffect(() => {
    if (!flights?.length || !people?.length) return;

    const result = {};
    flights?.forEach(flight => {
      let restrictions = null;

      if (flight?.restricted_to_traveller_internal_ids !== null) {
        if (flight?.restricted_to_traveller_internal_ids?.length === 0) {
          restrictions = [];
        } else {
          const selectedPeopleIdsFromSelected = people
            .filter(person =>
              person.travellersData.some(traveller => flight?.restricted_to_traveller_internal_ids?.includes(traveller.internal_id)),
            )
            .map(person => person.id);
          restrictions = selectedPeopleIdsFromSelected;
        }
      }

      result[flight.id] = restrictions;
    });
    setRestrictedFlightsDataByPerson(result);
  }, [people, selectedFlight, flights, selectedPeopleIdsForSelectedFlight]);

  return (
    <Wrapper slimTopPadding>
      {isTravellersOpen && (
        <TravellersModal
          people={people}
          onConfirm={onTravellersChange}
          toggleModal={toggleTravellersModal}
          disabled={!canEdit || isWiped}
          selectedPeopleIds={selectedPeopleIdsForSelectedFlight}
          setSelectedPeopleIds={setSelectedPeopleIdsForSelectedFlight}
        />
      )}
      {showDeleteFlightModal && <DeleteFlightModal onConfirm={onDelete} onCancel={toggleDeleteFlightModal} {...selectedFlight} />}
      {showAddModal && (
        <AddFlightModal
          onClose={toggleShowAddModal}
          append={append}
          flights={flights}
          setRestrictedFlightsDataByPerson={setRestrictedFlightsDataByPerson}
          departureDate={departureDate}
        />
      )}
      {showDetailsModal && <ViewFlightModal onClose={toggleShowDetailsModal} data={selectedFlight} />}

      <TitleRow text="Flights" isSticky disabled={!canEdit || isWiped}>
        <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={toggleShowAddModal} />
      </TitleRow>

      <div style={{ height: 20 }} />

      {flights?.length ? (
        <Table
          columns={columns({
            toggleShowDetailsModal,
            onDelete: flight => toggleDeleteFlightModal(flight),
            disabled: !canEdit || isWiped,
            handleTravellersModal,
            restrictedFlightsDataByPerson,
          })}
          renderRowMobile={
            <MobileItem
              onClick={toggleShowDetailsModal}
              onDelete={toggleDeleteFlightModal}
              disabled={!canEdit || isWiped}
              handleTravellersModal={handleTravellersModal}
              restrictedFlightsDataByPerson={restrictedFlightsDataByPerson}
            />
          }
          rows={flights}
          onRowClick={toggleShowDetailsModal}
        />
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding flights`}</NoResultsMessage>
      )}
    </Wrapper>
  );
};

export default Flights;
