import React, { useState } from "react";
import Switch from "components/_new/Switch";
import { MobileItemWrap } from "feature/panel/Itinerary/helpers";
import angleIcon from "../../../../../../../../assets/icons/angle-right.svg";
import { SecondaryText, Title, Wrap } from "./style";
import SpecificSettingsPlaceholder from "../specificSettingsPlaceholder";

const MobileRow = ({ item, onChange, onClick, isChecked, index }) => {
  const { name, country, longitude, latitude, description, is_default_on, icon, isOverriden } = item;
  const [isOpened, setIsOpened] = useState(false);

  const iconUrl = icon?.url;

  return (
    <MobileItemWrap
      onClick={() => {
        onClick(item);
        if (description) setIsOpened(!isOpened);
      }}
      style={{ opacity: isOverriden ? 0.3 : 1, display: "flex" }}
    >
      {iconUrl && (
        <div style={{ marginRight: 15, paddingTop: 2 }}>
          <img src={iconUrl} alt="icon" style={{ width: 18, height: 18 }} />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <Title>{name}</Title>
          {isOverriden ? (
            <SpecificSettingsPlaceholder style={{ marginLeft: 0, marginTop: 5 }} />
          ) : (
            <>
              <SecondaryText>{country}</SecondaryText>
              <SecondaryText>
                {latitude}, {longitude}
              </SecondaryText>
              {isOpened && <div style={{ marginTop: 15 }}>{description}</div>}
            </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch checked={isChecked ? isChecked(item) : is_default_on} onChange={() => onChange(item, index)} />
          {description && (
            <img
              src={angleIcon}
              style={{ marginRight: 15, marginLeft: 15, transform: `rotate(${isOpened ? 90 : 0}deg)` }}
              alt="angle-icon"
            />
          )}
        </div>
      </div>
    </MobileItemWrap>
  );
};

export default MobileRow;
