import * as actionTypes from "store/auth/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

export const AUTH_STATUSES = {
  loading: "loading",
  success: "success",
  fail: "fail",
};

const authInitialState = {
  errors: {},
  authStatus: AUTH_STATUSES.loading,
  actionType: "",
  inProgress: false,
  requireVerification: false,
  finished: false,
  token: null,
  user: {},
  operators: [],
  currentOperatorCode: null,
  composerData: null,
  isInitialAuth: false,
};

const ReducerActions = globalReducerActions(
  "auth",
  { ...authInitialState, authStatus: AUTH_STATUSES.fail },
  {
    [actionTypes.REGISTER_USER_WITH_OPERATOR_START](state) {
      return setState(state, {
        actionType: actionTypes.REGISTER_USER_WITH_OPERATOR_START,
        inProgress: true,
        finished: false,
      });
    },
    [actionTypes.REGISTER_USER_WITH_OPERATOR_SUCCESS](state) {
      return setState(state, {
        inProgress: false,
        finished: true,
      });
    },
    [actionTypes.REGISTER_USER_WITH_OPERATOR_FAIL](state, payload) {
      return setState(state, {
        inProgress: false,
        errors: payload,
      });
    },
    [actionTypes.REGISTER_USER_START](state) {
      return setState(state, {
        actionType: actionTypes.REGISTER_USER_START,
        inProgress: true,
        finished: false,
      });
    },
    [actionTypes.REGISTER_USER_SUCCESS](state) {
      return setState(state, {
        inProgress: false,
        finished: true,
      });
    },
    [actionTypes.REGISTER_USER_FAIL](state, payload) {
      return setState(state, {
        inProgress: false,
        errors: payload,
      });
    },
    [actionTypes.LOGIN_START](state) {
      return setState(state, {
        actionType: "login",
        inProgress: true,
        finished: false,
      });
    },

    [actionTypes.LOGIN_SUCCESS](state, payload) {
      const { user, operators, token, currentOperatorCode, require_verification } = payload;

      return setState(state, {
        inProgress: false,
        finished: true,
        requireVerification: require_verification ?? false,
        authStatus: AUTH_STATUSES.success,
        token,
        user,
        operators,
        currentOperatorCode,
      });
    },
    [actionTypes.VERIFY_SUCCESS_COMPLETE](state) {
      return setState(state, {
        inProgress: false,
        requireVerification: false,
      })
    },
    [actionTypes.LOGIN_FAIL](state, payload) {
      return setState(state, {
        authStatus: AUTH_STATUSES.fail,
        inProgress: false,
        errors: payload,
      });
    },
    [actionTypes.LOGOUT_START](state) {
      return setState(state, {
        actionType: actionTypes.LOGOUT_START,
        token: null,
        inProgress: true,
        finished: false,
      });
    },

    [actionTypes.LOGOUT_SUCCESS]() {
      return { ...authInitialState, authStatus: AUTH_STATUSES.fail };
    },

    [actionTypes.LOGOUT_FAIL](state, payload) {
      return setState(state, {
        inProgress: false,
        finished: false,
        errors: payload,
      });
    },

    [actionTypes.GET_CURRENT_USER_START](state) {
      return setState(state, {
        actionType: actionTypes.GET_CURRENT_USER_START,
        inProgress: true,
        finished: false,
      });
    },

    [actionTypes.GET_CURRENT_USER_SUCCESS](state, payload) {
      const { user, operators, token } = payload;
      return setState(state, {
        actionType: actionTypes.GET_CURRENT_USER_SUCCESS,
        authStatus: AUTH_STATUSES.success,
        inProgress: false,
        finished: true,
        user,
        operators,
        token,
      });
    },
    [actionTypes.GET_CURRENT_USER_FAIL](state, payload) {
      return setState(state, {
        authStatus: AUTH_STATUSES.fail,
        inProgress: false,
        finished: true,
        errors: payload,
      });
    },

    [actionTypes.EDIT_CURRENT_USER_SUCCESS](state, payload) {
      return setState(state, {
        actionType: actionTypes.EDIT_CURRENT_USER_SUCCESS,
        user: { ...state.user, ...payload },
      });
    },

    [actionTypes.RESET_USER_ACTION_TYPE](state) {
      return setState(state, {
        actionType: null,
      });
    },

    [actionTypes.SET_CURRENT_OPERATOR_CODE](state, payload) {
      return setState(state, {
        currentOperatorCode: payload,
      });
    },

    [actionTypes.SET_SINGLE_ERROR](state, payload) {
      return setState(state, {
        errors: payload,
      });
    },

    [actionTypes.UPDATE_OPERATORS](state, payload) {
      return setState(state, {
        operators: payload,
      });
    },

    [actionTypes.SET_COMPOSER_DATA](state, payload) {
      return setState(state, {
        composerData: payload,
      });
    },

    [actionTypes.SET_IS_INITIAL_AUTH](state, payload) {
      return setState(state, {
        isInitialAuth: payload,
      });
    },

    [actionTypes.REGISTER_OPERATOR_SUCCESS](state, payload) {
      const { operators, currentOperatorCode } = payload;

      return setState(state, {
        inProgress: false,
        finished: true,
        authStatus: AUTH_STATUSES.success,
        operators,
        currentOperatorCode,
      });
    },
  },
);

export const authReducer = (state = authInitialState, action) => reducer(state, action, ReducerActions);
