import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import { setUrlParams } from "utils/url";

import config from "config/app";

import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { deviceType } from "utils/deviceDetect";

import { getRequiredData, saveOrdering } from "../utils/saveTableSettings";

const useTableHandlers = (endpoint = "", dispatchedAction = () => {}, additionalOptions = {}) => {
  const [url, setUrl] = useState(null);
  const [requestParams, setRequestParams] = useState({});

  const [searchQuery, setQuerySearch] = useState(null);
  const [archived, setArchived] = useState(null);

  const [sortingBy, setSortingBy] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState(null);
  const [clearAllCheckboxSelections, setClearAllCheckboxSelections] = useState(false);
  const [firstPaginationPage, setFirstPaginationPage] = useState(false);

  const dispatch = useDispatch();

  const { defaultOrderBy, defaultOrder, customSearchParam, params, type } = additionalOptions;

  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const { tables, device, currentTable } = getRequiredData(additionalOptions?.params?.type);
    const result = parseInt(tables?.[device]?.[additionalOptions?.params?.type]?.resultsPerPage, 10);
    return result || config.tables.defaultNumberOfRows;
  });

  const getCurrentOrdering = () => {
    const device = deviceType();
    const tables = JSON.parse(localStorage.getItem("v_tables"));
    return tables?.[device]?.[additionalOptions?.params?.type]?.ordering.order;
  };

  const handleChangeOrder = ({ column, order }, tableId) => {
    if (column !== (sortingBy || defaultOrderBy)) {
      setSortingBy(column);

      setSortingOrder(getCurrentOrdering() || "asc");
    } else if (order !== (sortingOrder || defaultOrder)) setSortingOrder(order);

    saveOrdering({ column, order }, tableId);
  };

  const refactoredHandleChangeOrder = ({ column, order }, tableId) => {
    const newOrderBy = order ? column : (tableId !== "notifications" ? "updated_at" : "id");
    const newOrder = order || "desc";

    setSortingBy(newOrderBy);
    setSortingOrder(newOrder);
    saveOrdering({ column: newOrderBy, order: newOrder }, tableId);
  };

  const handleChangeRowsPerPage = rowsCount => {
    setRowsPerPage(rowsCount);
    setPage(1);
  };

  const handleChangePage = pageNumber => {
    setPage(pageNumber + 1);
  };

  const handleChangeQuery = query => {
    if (!query) setPage(1);
    setQuerySearch(query);
  };

  const debouncedQuery = useRef(
    debounce(query => {
      handleChangeQuery(query);
    }, DEFAULT_DEBOUNCE_DELAY),
  );

  const handleSearch = (query, firstPage) => {
    if (firstPage) {
      setPage(1);
    }
    debouncedQuery.current(query);
  };
  const handleChangeArchived = value => {
    setArchived(value);
  };

  const handleChangeParams = (newParams, isFilterItem) => {
    setFirstPaginationPage(!!isFilterItem);
    setClearAllCheckboxSelections(!!isFilterItem);
    setQueryParams(newParams);
  };

  const query = () => {
    let cleanup = true;
    if (cleanup && url && url === setUrlParams(endpoint, requestParams) && dispatchedAction) {
      const itinType = additionalOptions?.params?.type;
      dispatch(dispatchedAction({ url, searchMode: !!searchQuery, itinType }));
    }
    return () => {
      cleanup = false;
    };
  };

  const prepareUrl = () => {
    const urlWithParams = setUrlParams(endpoint, requestParams);
    setUrl(urlWithParams);
    setFirstPaginationPage(false);
  };

  const prepareRequestParamsObject = () => {
    if (firstPaginationPage) setPage(1);

    setRequestParams({
      count: rowsPerPage,
      page: firstPaginationPage ? 1 : page,
      order_by: (sortingBy || defaultOrderBy)?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
      order: sortingOrder || defaultOrder,
      archived,
      [customSearchParam]: searchQuery,
      ...(queryParams || params),
    });
  };

  useEffect(prepareRequestParamsObject, [rowsPerPage, page, sortingOrder, sortingBy, searchQuery, archived, endpoint, queryParams]);
  useEffect(prepareUrl, [requestParams]);
  useEffect(query, [url]);
  return {
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeOrder,
    handleChangeQuery,
    handleChangeArchived,
    handleChangeParams,
    handleSearch,
    forceQuery: query,
    forceParamsUpdate: prepareRequestParamsObject,
    url,
    searchQuery,
    page,
    rowsPerPage,
    sortingOrder,
    sortingBy,
    clearAllCheckboxSelections,
    setClearAllCheckboxSelections,
    refactoredHandleChangeOrder,
  };
};

export { useTableHandlers };
