import React from "react";
import { Controller } from "react-hook-form";
import Input from "components/ui/Inputs/TextInput";
import RadioButtons from "components/_new/Radio";
import { TextEditor } from "components/ui/Forms/TextEditor";
import { Menu, MenuItem } from "@mui/material";
import moment from "moment";
import config from "config/app";
import { AddAction, DeleteAction, DownloadAction, EditAction, RefreshAction } from "components/ui/Tables/TableRowActions";
import { TABLE_ACTIONS_TITLES } from "constants/content";
import { Message } from "components/ui/Messages";
import Switch from "components/_new/Switch";
import MapWidget from "feature/panel/_shared/MapWidget";
import { viewSettings } from "feature/panel/_shared/MapWidget/settings";
import { PickerButton, SizePicker } from "feature/panel/Stays/_shared/Publisher/styledComponents";
import { ResponsiveHideFrom } from "feature/panel/Itinerary/responsive";
import PDFStatus from "./PDFStatus";

const poi_list_types = [
  { key: "type", label: "Type" },
  { key: "distance", label: "Distance" },
];

export const GreyBlock = ({ children }) => (
  <div style={{ padding: "15px 25px", borderRadius: 8, backgroundColor: "rgba(232, 232, 233, 0.3)", marginTop: 15 }}>{children}</div>
);

export const radio = [
  {
    label: "Full-height images (default)",
    value: "adjusted",
  },
  {
    label: "Fixed size images (square)",
    value: "fixed",
  },
];

export const radioPublisher = [
  {
    label: "Reduced-size cover (default)",
    value: "reduced",
  },
  {
    label: "Full-size cover",
    value: "fixed",
  },
];

export const checkboxes = ({ storyboard, control, front_page_notes, last_page_notes }) => [
  {
    name: "sections.toc",
    label: "Table of Contents",
  },
  {
    name: "sections.storyboard",
    label: "Storyboard",
    additional: storyboard && (
      <GreyBlock>
        <RadioButtons buttons={radio} control={control} name="sections.storyboard.image_size" />
      </GreyBlock>
    ),
  },
  {
    name: "sections.map",
    label: "Locations map",
  },
  {
    name: "sections.notes_front",
    label: "Front page notes",

    additional: front_page_notes && (
      <GreyBlock>
        <Input
          control={control}
          name="sections.notes_front.headline"
          label="Headline"
          style={{ marginBottom: 15 }}
          labelStyle={{ backgroundColor: "rgb(248 248 248)" }}
        />
        <Controller
          control={control}
          name="sections.notes_front.content"
          render={({ field: { onChange, value } }) => <TextEditor onChange={onChange} value={value} />}
        />
      </GreyBlock>
    ),
  },
  {
    name: "sections.notes_end",
    label: "Last page notes",
    additional: last_page_notes && (
      <GreyBlock>
        <Input
          control={control}
          name="sections.notes_end.headline"
          label="Headline"
          style={{ marginBottom: 15 }}
          labelStyle={{ backgroundColor: "rgb(248 248 248)" }}
        />
        <Controller
          control={control}
          name="sections.notes_end.content"
          render={({ field: { onChange, value } }) => <TextEditor onChange={onChange} value={value} />}
        />
      </GreyBlock>
    ),
  },
];

const renderMap = (mapName, label, { control, setValue, map }) => (
  <>
    <div style={{ display: "flex", alignItems: "center", gap: 15, margin: "15px 0" }}>
      <Input control={control} name={`sections.${mapName}.headline`} label={label} />
      <Switch control={control} name={`sections.${mapName}.show_pois`} label="Show POIs" />
    </div>
    <MapWidget
      onZoomChange={zoom => setValue("sections.map.options.zoom", zoom)}
      onCenterChange={(latitude, longitude) => {
        setValue(`sections.${mapName}.options.latitude`, latitude);
        setValue(`sections.${mapName}.options.longitude`, longitude);
      }}
      hideSearch
      // center={map?.options?.latitude ? { lat: map?.options?.latitude, lng: map?.options?.longitude } : {}}
      settings={
        map?.options
          ? {
              center: {
                lat: map?.options?.latitude || viewSettings.center.lat,
                lng: map?.options?.longitude || viewSettings.center.lng,
              },
              zoom: map?.options?.zoom || viewSettings.zoom,
            }
          : {}
      }
    />
  </>
);

export const checkboxesPublisher = ({ cover, directory, control, setValue, map1, map2, map3, poi_list, last_page_notes, location }) => [
  {
    name: "sections.cover",
    label: "Cover page",
    additional: cover && (
      <GreyBlock>
        <RadioButtons buttons={radioPublisher} control={control} name="sections.cover.imagesize" />
        <br />
        <Controller
          control={control}
          name="sections.cover.notes"
          render={({ field: { onChange, value } }) => <TextEditor onChange={onChange} value={value} />}
        />
      </GreyBlock>
    ),
  },
  {
    name: "sections.toc",
    label: "Table of contents",
  },
  {
    name: "sections.directory",
    label: "Directory",
    additional: directory && <Message type="info" text="You can configure the directory in the next step" />,
    shouldShow: ({ directories }) => directories?.length > 0,
  },
  {
    name: "sections.map1",
    label: "Map 1",
    additional: map1 && renderMap("map1", "Map 1 title", { control, setValue, map: map1 }),
  },
  {
    name: "sections.map2",
    label: "Map 2",
    additional: map2 && renderMap("map2", "Map 2 title", { control, setValue, map: map2 }),
  },
  {
    name: "sections.map3",
    label: "Map 3",
    additional: map3 && renderMap("map3", "Map 3 title", { control, setValue, map: map3 }),
  },
  {
    name: "sections.poi_list",
    label: (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        POI list
        <div style={{ fontSize: 12 }}>
          <ResponsiveHideFrom size="md">Order POI by: </ResponsiveHideFrom>
          <SizePicker disabled={!poi_list} disableRipple>
            {poi_list_types.map(item => (
              <PickerButton
                size={item.key}
                style={{ fontSize: 12 }}
                selected={poi_list?.order_by === item.key}
                onClick={() => {
                  setValue("sections.poi_list.order_by", item.key);
                  setValue("sections.poi_list.location", { latitude: location.latitude, longitude: location.longitude });
                }}
              >
                {item.label}
              </PickerButton>
            ))}
          </SizePicker>
        </div>
      </div>
    ),
  },
  {
    name: "sections.notes_end",
    label: "Last page notes",
    additional: last_page_notes && (
      <GreyBlock>
        <Input
          control={control}
          name="sections.notes_end.headline"
          label="Headline"
          style={{ marginBottom: 15 }}
          labelStyle={{ backgroundColor: "rgb(248 248 248)" }}
        />
        <Controller
          control={control}
          name="sections.notes_end.content"
          render={({ field: { onChange, value } }) => <TextEditor onChange={onChange} value={value} />}
        />
      </GreyBlock>
    ),
  },
];

export const columns = ({
  handleMenuOpen,
  handleMenuClose,
  openMenu,
  onEdit,
  onRefresh,
  handleDownload,
  onAddToDocuments,
  toggleDeleteModal,
  disabled,
}) => [
  {
    id: "name",
    label: "File name",
    align: "left",
  },
  {
    id: "date_time",
    label: "Date / time",
    align: "left",
    width: 72,
    renderCell: data => moment(data.updated_at).format(config.dateTimeFormat),
  },
  {
    id: "actions",
    align: "right",
    isAction: true,
    renderCell: data =>
      !disabled && (
        <div style={{ display: "flex" }}>
          {["completed"].find(item => item === data.job.status) ? (
            <>
              <RefreshAction onClick={() => onRefresh(data)} />
              <>
                <Menu
                  id="downloadMenu"
                  anchorEl={openMenu?.target}
                  open={openMenu?.target?.id === "download-button"}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleDownload(openMenu?.data, "web")}>Download web version</MenuItem>
                  <MenuItem onClick={() => handleDownload(openMenu?.data, "print")}>Download print version</MenuItem>
                </Menu>
                <DownloadAction clickAction={e => handleMenuOpen(e, data)} id="download-button" tooltip="Download file" />
              </>
              <>
                <Menu id="addMenu" anchorEl={openMenu?.target} open={openMenu?.target?.id === "add-button"} onClose={handleMenuClose}>
                  <MenuItem onClick={() => onAddToDocuments(openMenu?.data, "travel")}>Add to Travel documents</MenuItem>
                  <MenuItem onClick={() => onAddToDocuments(openMenu?.data, "destination")}>Add to Destination documents</MenuItem>
                </Menu>
                <AddAction clickAction={e => handleMenuOpen(e, data)} id="add-button" tooltip={TABLE_ACTIONS_TITLES.addToDocuments} />
              </>
              <EditAction onClick={() => onEdit(data)} />
              <DeleteAction onClick={() => toggleDeleteModal(data)} />
            </>
          ) : (
            <PDFStatus {...data} />
          )}
        </div>
      ),
  },
];

export const prepareDataToSubmit = (data, modalType) => {
  return {
    ...data,
    sections: Object.entries(data.sections)
      .filter(item => item[1])
      .map(item => {
        const type = item[0];

        if (type === "directory")
          item[1].nodes = item[1].nodes
            .filter(item => item.checked)
            .map(({ image_size, page_break_before, section, show_image }) => ({
              image_size,
              page_break_before,
              section,
              show_image,
            }));

        if (type === "map1" || type === "map2" || type === "map3") {
          item[1].type = "map";
        }

        if (type === "map" && modalType === "publisher") {
          if (!item[1].options?.zoom) item[1].options.zoom = 3;
          if (!item[1].options?.latitude) item[1].options.latitude = viewSettings.center.lat;
          if (!item[1].options?.longitude) item[1].options.longitude = viewSettings.center.lng;
        }

        return { type, ...(item[1] || {}) };
      }),
  };
};

export const prepareDataToUse = data => {
  const sections = {};
  let mapCount = 0;

  data.settings.sections.forEach(item => {
    if (item.type === "map") {
      mapCount += 1;
      sections[`map${mapCount === 1 ? "" : mapCount}`] = {
        ...item,
      };
    } else {
      sections[item.type] = {
        ...item,
      };
    }
  });

  return {
    name: data.name,
    show_dates: data.settings.show_dates,
    sections,
    id: data.id,
  };
};
