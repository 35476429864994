import React, { cloneElement, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import DndItemWrap from "./dndItem";
import { EditorContext } from "../../../feature/panel/Itinerary";

const Drag = ({ itemKey, draggableId, wrapStyle, contentStyle, disabled, index, children }) => {
  const { baseModalOpen } = useContext(EditorContext) ?? false;

  return (
    <Draggable key={itemKey} draggableId={draggableId} index={index} isDragDisabled={baseModalOpen}>
      {provided => (
        <DndItemWrap
          ref={provided.innerRef}
          dragHandleProps={provided.dragHandleProps}
          draggableProps={provided.draggableProps}
          wrapStyle={wrapStyle}
          contentStyle={contentStyle}
          disabled={disabled}
        >
          {children}
        </DndItemWrap>
      )}
    </Draggable>
  );
};

export default Drag;
