import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "@mui/material/CircularProgress";
import { Loader, Wrap } from "./style";

const FolderPill = ({ name, isLoading, onClick }) => {
  return (
    <Wrap onClick={onClick}>
      <FolderIcon />
      <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{name}</div>
      {isLoading && (
        <Loader>
          <CircularProgress />
        </Loader>
      )}
    </Wrap>
  );
};

export default FolderPill;
