import React, { useEffect, useState } from "react";
import DnD from "components/_new/DnD";
import BaseModal from "components/_new/ModalBase";
import Item from "./item";

export const SecondaryItem = ({ item, index, getValues, name }) => <div>{getValues(`${name}.${index}`).headline}</div>;

const ReorderModal = ({ items, onClose, replace, getValues, name }) => {
  const [reordered, setReordered] = useState(null);
  const onDragEnd = list =>
    setReordered(
      list.map((item, index) => ({
        ...item,
        items: item.items.map(secondayItem => ({ ...secondayItem, created_at: item.created_at })),
        meta: { day_number: index + 1 },
      })),
    );

  // try access input values with getValues
  const onConfirm = () => {
    const result = [];
    reordered.forEach((item, index) => {
      item.items.forEach(({ index: itemIndex, created_at }, currItemIndex) => {
        const currentItem = getValues(`${name}.${itemIndex}`);
        const length = result.length || 0;
        result.push({
          ...currentItem,
          position: currItemIndex + length + (currItemIndex === 0 ? 1 : 0),
          meta: { ...currentItem.meta, day_number: index + 1 },
          created_at,
        });
      });
    });

    replace(result);
    onClose();
  };

  const getGroupedDays = arr => {
    const result = [];

    arr.forEach((item, itemIndex) => {
      const index = result.findIndex(currItem => currItem.meta.day_number === item.meta.day_number);
      if (index !== -1) {
        result[index].items.push({ ...item, index: itemIndex });
      } else {
        // console.log(item);
        result.push({
          meta: { day_number: item.meta.day_number },
          created_at: item.created_at,
          items: [{ ...item, index: itemIndex }],
          item_id: item.item_id,
        });
      }
    });
    return result;
  };

  useEffect(() => {
    return () => setReordered(null);
  }, []);

  return (
    <BaseModal
      title="Reorder"
      onConfirm={onConfirm}
      onCancel={onClose}
      topLineStyle={{ marginBottom: 15 }}
      contentStyle={{ overflowY: "auto" }}
    >
      <DnD
        list={reordered || getGroupedDays(items || [])}
        element={<Item />}
        secondaryElement={<SecondaryItem getValues={getValues} name={name} />}
        onDragEnd={onDragEnd}
        idVar="item_id"
      />
    </BaseModal>
  );
};

export default ReorderModal;
