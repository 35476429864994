import { DeleteOutline, DeleteOutlineOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AttachButton from "components/_new/Buttons/AttachButton";
import Switch from "components/_new/Switch";
import TableAction from "components/_new/Table/Action";
import Input from "components/ui/Inputs/TextInput";
import { colors } from "config/theme/colors";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { UrlRefreshService } from "services/application/UrlRefreshService";
import AttachmentIcon from "@mui/icons-material/Attachment";

import { getFilePreviewUrl, openPreviewInNewTab } from "utils/library";
import { NestedLocationMarker } from "../../../../../Trips/_shared/Locations/LocationsTab/LocationsTabListItem";
import { ButtonsWrap, Dropdown, FileActionWrap, IconWrap, ItemGrid, NumberS, ToggleButton } from "./style";

const getFileExtension = url => {
  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  const lastDotIndex = pathname.lastIndexOf(".");
  return pathname.substring(lastDotIndex + 1);
};

export const onPreview = async file => {
  const url = await UrlRefreshService.refresh(getFilePreviewUrl(file));
  openPreviewInNewTab(url);
};

export const renderAddFileMini = ({
  name,
  control,
  handleDeleteFile,
  disabled,
  watchItem,
  mediaTypes = DOCUMENTS_FILE_TYPES,
  hideUrl,
  buttonStyle = {},
  multiUpload,
  modalTypes = ["library", "upload", "webpage"],
  shouldButtonExpand,
  useNormalButton,
  onCustomConfirm,
}) => {
  let fileName = watchItem?.file?.file_name || watchItem?.file?.name;
  // const file =
  //   watchItem?.file?.file_name || watchItem?.file?.name || watchItem?.file?.s3url || watchItem?.file?.web_url || watchItem?.file?.https_url;
  const hasUrl = watchItem?.file?.s3url || watchItem?.file?.web_url || watchItem?.file?.https_url || watchItem?.https_url;
  let fileExtension =
    watchItem?.file?.mime_type?.split("/")[1] || watchItem?.file?.type?.split("/")[1] || (hasUrl && getFileExtension(hasUrl));

  let domain = "";
  if (!fileName && watchItem?.file) {
    const url = new URL(watchItem?.file?.s3url || watchItem?.file?.web_url || watchItem?.file?.https_url);
    const path = url.pathname;
    fileName = path.split("/").pop();
    fileExtension = decodeURIComponent(fileName);
  }
  if (!fileExtension) {
    const fileExtensionUrl = watchItem?.file?.s3url || watchItem?.file?.web_url || watchItem?.file?.https_url;
    const parts = fileExtensionUrl?.split(".") ?? [];
    domain = parts.length ? parts[parts.length - 1] : "N/A";
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <AttachButton
            allowedFileTypes={mediaTypes}
            onConfirm={onCustomConfirm ? files => onCustomConfirm(files, onChange) : file => onChange(file)}
            onDelete={handleDeleteFile}
            text={
              error && !useNormalButton ? (
                <div>
                  Add <br />{" "}
                  <div
                    style={{
                      fontSize: 10,
                      letterSpacing: 0,
                      whiteSpace: "normal",
                      position: "absolute",
                      left: 0,
                      bottom: 5,
                      textTransform: "none",
                      lineHeight: 1,
                      fontWeight: 600,
                      color: colors.error,
                      width: "100%",
                    }}
                  >
                    Attachment is required
                  </div>
                </div>
              ) : (
                "Add"
              )
            }
            file={value}
            disabled={disabled}
            types={modalTypes}
            startIcon={<AttachmentIcon style={{ color: error ? colors.error : colors.brand }} />}
            style={{
              ...(!useNormalButton
                ? {
                    width: shouldButtonExpand ? 161 : 80,
                    height: 80,
                    borderRadius: 0,
                    fontSize: 12,
                    borderTop: 0,
                    borderBottom: 0,
                    borderRight: 0,
                    borderColor: colors.grey10,
                    backgroundColor: error ? colors.withOpacity(colors.error, 0.3) : "rgba(232, 232, 233, 0.3)",
                    color: error ? colors.error : colors.brand,
                  }
                : {}),
              ...buttonStyle,
            }}
            chipWrapStyle={{
              padding: "0 5px",
              display: "flex",
              alignItems: "center",
              ...(!useNormalButton ? { backgroundColor: "rgba(232, 232, 233, 0.3)", borderLeft: `1px solid ${colors.grey10}` } : {}),
            }}
            chipStyle={{ width: 150 }}
            iconOnlyMobile
            mobileFullScreen
            hideDescription
            hideUrl={hideUrl}
            multiUpload={multiUpload}
          />
        </>
      )}
    />
  );
};

const Item = ({
  item,
  index,
  control,
  watch,
  onDelete,
  name,
  locations,
  setValue,
  onModalOpen,
  disabled,
  update,
  hasFile,
  autoFocus = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const watchItem = watch(`${name}.${index}`);

  const handleDeleteFile = () => setValue(`${name}.${index}.file`, null, { shouldDirty: true });
  const handleDeleteItem = () => onDelete(index);
  const toggleOpen = () => setIsOpen(!isOpen);
  const handleModalOpen = () => onModalOpen(item.nested?.vamoos_id || watchItem?.vamoos_id);

  const isNested = item.nested || watchItem?.vamoos_id;
  const nestedColor = colors.withOpacity(colors.brand, 0.3);
  const labelNestedColor = colors.withOpacity(colors.brand, 0.01);
  const labelStyle = { backgroundColor: isNested ? labelNestedColor : "#fff" };
  const isInputDisabled = isNested || disabled;

  const onOptionClick = item => update(index, { ...item, coordinates: `${item.latitude}, ${item.longitude}` });
  return (
    <ItemGrid hasFile={item.file} style={{ backgroundColor: isNested && nestedColor }} isNested={isNested}>
      <div>
        <NumberS>{index + 1}</NumberS>
        {isNested && <NestedLocationMarker />}
      </div>
      <Input
        control={control}
        name={`${name}.${index}.name`}
        label="Name"
        labelStyle={labelStyle}
        rules={{ required: true }}
        options={locations}
        handleOptionClick={onOptionClick}
        renderOption={item => (
          <div style={{ backgroundColor: item.vamoos_id ? colors.withOpacity(colors.brand, 0.3) : "#fff", padding: "7px 15px" }}>
            {item.name}
          </div>
        )}
        style={{ opacity: isInputDisabled ? 0.8 : 1 }}
        disabled={isInputDisabled}
        autoFocus={autoFocus && (!watchItem.name || watchItem.on_maps)}
      />

      <Dropdown isOpen={isOpen}>
        <Input
          control={control}
          name={`${name}.${index}.${watchItem?.nested?.vamoos_id ? "nested.operator_code" : "location"}`}
          label={watchItem?.nested?.vamoos_id ? "User ID" : "Location"}
          labelStyle={labelStyle}
          rules={{ required: true }}
          style={{ opacity: isInputDisabled ? 0.8 : 1 }}
          disabled={isInputDisabled}
        />
        <Input
          control={control}
          name={`${name}.${index}.coordinates`}
          label="Coordinates"
          labelStyle={labelStyle}
          rules={{ required: true }}
          customOnChange={newValue => {
            // TODO @OM - This doesn't seem right but it's a workaround
            const split = newValue.trim().split(",");
            if (split.length > 1) {
              const [lat, lon] = split;

              if (lat.trim().length && lon.trim().length) {
                setValue(`${name}.${index}.latitude`, parseFloat(lat));
                setValue(`${name}.${index}.longitude`, parseFloat(lon));
              }
            }
          }}
          // defaultValue={item.coordinates}
          style={{ opacity: isInputDisabled ? 0.8 : 1 }}
          disabled={isInputDisabled}
        />
        <ButtonsWrap>
          {/* <CheckboxesWrap>
            <Checkbox control={control} label="Weather" name={`${name}.${index}.on_weather`} disabled={disabled} />
            <Checkbox control={control} label="Maps" name={`${name}.${index}.on_maps`} disabled={disabled} />
          </CheckboxesWrap> */}
          <TableAction
            element={
              <div style={{ fontSize: 12, paddingTop: 5, textAlign: "center" }}>
                <Switch control={control} name={`${name}.${index}.on_weather`} disabled={disabled} />
                Weather
              </div>
            }
            mobileStyle={{ width: "100%", borderLeft: 0 }}
          />
          <TableAction
            element={
              <div style={{ fontSize: 12, paddingTop: 5, textAlign: "center" }}>
                <Switch control={control} name={`${name}.${index}.on_maps`} disabled={disabled} />
                Maps
              </div>
            }
            mobileStyle={{ width: "100%" }}
          />
          {isNested ? (
            <TableAction
              element={
                <div style={{ fontSize: 12, display: "flex", alignItems: "center", color: colors.brand, fontWeight: 600 }}>
                  <EditOutlinedIcon style={{ color: colors.brand, fontSize: 16, marginRight: 5 }} /> EDIT
                </div>
              }
              mobileStyle={{ width: "100%" }}
              wrapStyle={{ width: hasFile ? 161 : 81 }}
              disabled={disabled}
              onClick={handleModalOpen}
            />
          ) : (
            renderAddFileMini({
              control,
              name: `${name}.${index}.file`,
              handleDeleteFile,
              onPreview,
              watchItem,
              disabled,
              shouldButtonExpand: hasFile,
            })
          )}
          <ResponsiveHideFrom size="md">
            <TableAction element={<DeleteOutline />} onClick={handleDeleteItem} disabled={disabled} />
          </ResponsiveHideFrom>
        </ButtonsWrap>
      </Dropdown>

      <ToggleButton onClick={toggleOpen}>
        {isOpen ? "Hide" : "Show"} more {isOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}
      </ToggleButton>
      <ResponsiveShowFrom size="md">
        {!disabled && (
          <IconWrap onClick={handleDeleteItem}>
            <DeleteOutlineOutlined sx={{ color: nestedColor }} />
          </IconWrap>
        )}
      </ResponsiveShowFrom>
    </ItemGrid>
  );
};

export default Item;
