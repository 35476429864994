import PropTypes from "prop-types";
import React from "react";

import {
  DoneAllOutlined,
  EditOutlined,
  FilterAltOutlined,
  DeleteOutlined,
  FilterListOutlined,
  RemoveDoneOutlined,
} from "@mui/icons-material";

import { ButtonsContainer } from "../style";

import Badge from "../../../../../components/_new/Badge";
import OutlinedButton from "../../../../../components/_new/Buttons/OutlinedButton";
import BulkActions from "../BulkActions";

const MobileItemHeader = ({
  setOpenFilterModal,
  handleToggleShowArchived,
  setOpenColumnSelectorModal,
  showArchived,
  filterCount,
  selectedItems,
  setSelectedItems,
  url,
  forceQuery,
  totalMatches,
  list,
  checked,
  setChecked,
  type,
  setClearAllCheckboxSelections,
}) => {
  const toggleSelectAll = () => {
    const checkedCount = checked?.length;
    const allChecked = checkedCount === list.length;

    if (allChecked) setChecked([]);
    else setChecked(list.map(({ vamoos_id }) => vamoos_id));
  };

  return (
    <>
      <ButtonsContainer>
        <Badge badgeContent={filterCount}>
          <OutlinedButton
            iconOnlyMobile
            startIcon={<FilterAltOutlined />}
            onClick={() => {
              setOpenFilterModal(true);
            }}
            type="grey"
          />
        </Badge>
        <BulkActions
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          url={url}
          forceQuery={forceQuery}
          totalMatches={totalMatches}
          type={type}
          setClearAllCheckboxSelections={setClearAllCheckboxSelections}
        />
        <OutlinedButton
          iconOnlyMobile
          startIcon={selectedItems.allItemsSelected ? <RemoveDoneOutlined /> : <DoneAllOutlined />}
          onClick={toggleSelectAll}
          type="grey"
          disabled={!selectedItems.vamoosIds?.length && !selectedItems.allItemsSelected}
        />
        <OutlinedButton
          iconOnlyMobile
          startIcon={showArchived ? <FilterListOutlined /> : <DeleteOutlined />}
          onClick={() => {
            handleToggleShowArchived();
          }}
          type="grey"
        />
        <OutlinedButton
          iconOnlyMobile
          startIcon={<EditOutlined />}
          onClick={() => {
            setOpenColumnSelectorModal(true);
          }}
          type="grey"
        />
      </ButtonsContainer>
    </>
  );
};

MobileItemHeader.propTypes = {
  setOpenFilterModal: PropTypes.func.isRequired,
  handleToggleShowArchived: PropTypes.func.isRequired,
  setOpenColumnSelectorModal: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  filterCount: PropTypes.number.isRequired,
  selectedItems: PropTypes.shape().isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  forceQuery: PropTypes.func.isRequired,
  totalMatches: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checked: PropTypes.arrayOf(PropTypes.number).isRequired,
  setChecked: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setClearAllCheckboxSelections: PropTypes.func.isRequired,
};

export default MobileItemHeader;
