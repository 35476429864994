import { DriveFileMoveOutlined, EventAvailableOutlined, EventBusyOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@tanstack/react-query";
import AttachButton from "components/_new/Buttons/AttachButton";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import IconReorder from "components/ui/Icons/IconReorder";
import SelectInput from "components/ui/Inputs/Select";
import { NoResultsMessage } from "components/ui/Messages";
import { STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE, STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES } from "constants/storyboard";
import { StoryboardComponent } from "domain/StoryboardComponent";
import { StoryboardFile } from "domain/StoryboardFile";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { prepareLocationFromStay } from "feature/panel/Itinerary/helpers";
import { Wrapper } from "feature/panel/Itinerary/style";
import { setDayNumber, setPositionNumber } from "feature/panel/Trips/_shared/Storyboard/days";
import { transformStoryBoardDisplayableValue } from "feature/panel/Trips/_shared/helpers";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { HttpClient } from "services/application/httpClient/httpClient";
import uuidv4 from "uuid";
import { EditorContext } from "feature/panel/Itinerary";

import config from "../../../../../../config/app";
import { useEntries } from "../../../../Trips/_shared/Storyboard/Entries/useEntries";
import StoryboardItem from "./Item";
import ReorderModal from "./ReorderModal";
import { ActionButtonsGrid } from "./style";

const Storyboard = ({
  form: {
    control,
    setValue,
    watch,
    getValues,
    trigger,
    clearErrors,
    formState: { errors },
  },
  isWiped,
  canEdit,
  isDirty,
}) => {
  const lastItemRef = useRef();
  const formFlight = useForm();

  const { addFlightEntry } = useEntries({
    entries: watch("storyboard.details"),
    flights: watch("flights.flights"),
    updateEntriesList: entries => setValue("storyboard.details", entries),
    updateFlights: updateFlights => setValue("flights.flights", updateFlights),
    startDate: moment(watch("general").departure_date),
  });

  const [fileUrl, setFileUrl] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [showManualFlightForm, setShowManualFlightForm] = useState(false);
  const [isLoadingFlight, setIsLoadingFlight] = useState(false);
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [changedDayItemId, setChangedDayItemId] = useState(null);

  const { data: imageFromLibrary } = useQuery({
    queryKey: ["storyboardImage"],
    queryFn: () => {
      return HttpClient.get(fileUrl);
    },
    enabled: Boolean(fileUrl),
  });
  const { data: operator } = useQuery({ queryKey: ["operator"] });

  const name = "storyboard.details";
  const meta = watch("general.meta");
  const startAt = watch("general.departure_date");
  const locations = watch("locations.locations");
  const showDays = meta ? Boolean(watch("general.meta.show_days")) : true;
  const iconsDetails = watch("general.meta.show_story_board_daily");
  const iconsSummary = watch("general.meta.show_story_board_summary");
  const { fields: items, remove, append, replace, update } = useFieldArray({
    control,
    name,
    keyName: "item_id",
  });
  const options = [
    {
      label: `Account default (${transformStoryBoardDisplayableValue(
        operator?.meta?.show_story_board_summary,
        operator?.meta?.show_story_board_daily,
      )})`,
      value: STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE,
    },
    ...STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES,
  ];

  const getIconsValue = () => {
    if (iconsSummary && !iconsDetails) return "Summary";
    if (iconsDetails && !iconsSummary) return "Details";
    if (iconsSummary === undefined && iconsDetails === undefined) return STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE;
    if (iconsSummary && iconsDetails) return "Summary + Details";
  };
  const toggleShowDay = () => setValue("general.meta.show_days", !showDays);

  const onAdd = (data, index = 0) => {
    const lastElement = items[items.length - 1] || null;
    const lastElementDayNumber = lastElement ? lastElement.meta.day_number : 0;

    const newItem = {
      id: uuidv4(),
      isNew: true,
      position: lastElement?.position + 1 || 1,
      meta: {
        day_number: lastElementDayNumber + 1 + index,
        restricted_to_traveller_internal_ids: null,
      },
      headline: "",
      content: "",
      location: null,

      image: null,
      created_at: moment(startAt).add(lastElementDayNumber, "days"),
      ...(data ? { ...data } : {}),
      documents:
        data?.documents?.map(({ file_id, file_name, https_url, library_node_id, s3_url, alias_for_id, ...rest }) => ({
          file: {
            file_id,
            file_name,
            https_url,
            library_node_id,
            s3_url,
            alias_for_id,
          },
          ...rest,
        })) || [],
    };

    formFlight.reset();
    setShowManualFlightForm(false);
    append(newItem);
    setIsItemAdded(true);
  };

  const isLastItem = (list, index) => {
    const length = list?.length;

    return index === length - 1;
  };

  const onToggleModal = value => {
    setIsModalOpen(value);
    formFlight.reset();
    setShowManualFlightForm(false);
  };

  const onToggleReorderModal = () => setShowReorderModal(!showReorderModal);

  const onAddFromLibrary = async file => {
    if (Array.isArray(file)) {
      const promises = [];
      file.forEach(item => {
        promises.push(HttpClient.get(item?.https_url));
      });
      Promise.all(promises).then(res => {
        res.forEach(({ data }, index) => onAdd({ ...data, headline: data.title }, index));
      });
    } else {
      const { data } = await HttpClient.get(file?.https_url);
      onAdd({ ...data, headline: data.title });
    }
  };

  const onStaySelect = stay => {
    const newLocation = prepareLocationFromStay(stay);
    const existingLocation = locations.find(item => item.coordinates === newLocation.coordinates);
    if (!existingLocation) setValue("locations.locations", [...locations, newLocation]);
    onAdd({
      headline: stay.name,
      image: {
        file_url: stay.background,
        https_url: stay.background,
        name: stay.background
          .split("?")[0]
          .split("/")
          .pop(),
      },
      meta: { day_number: items.length + 1 },
      location: existingLocation?.id || newLocation.id,
    });
  };

  const handleFlights = async flightData => {
    setIsLoadingFlight(true);
    await addFlightEntry({
      fs: flightData.airlineCode,
      number: flightData.flightNumber,
      date: flightData.noDateFormat ? flightData.flightDate : flightData.flightDate.format(config.apiDateFormat),
      selectedFlights: flightData.selectedFlights,
    });
    setIsLoadingFlight(false);
  };

  const flightProps = {
    showManualForm: showManualFlightForm,
    setShowManualForm: setShowManualFlightForm,
    control: formFlight.control,
    handleSubmit: formFlight.handleSubmit,
    watch: formFlight.watch,
    isLoading: isLoadingFlight,
    handleFlights,
  };

  useEffectDependenciesOnly(() => {
    if (imageFromLibrary) {
      const imageFromLibraryModified = StoryboardComponent(imageFromLibrary.data);
      const oldItems = items.map(entry => ({ ...entry, isNew: false }));
      const newItem = {
        ...imageFromLibraryModified,
        isNew: true,
        position: setPositionNumber(oldItems),
        meta: {
          day_number: setDayNumber(oldItems),
        },
      };
      setValue("storyboard", { ...(items || []), [newItem.id]: newItem });
      setFileUrl(null);
    }
  }, [imageFromLibrary]);

  useEffectDependenciesOnly(() => {
    if (!isItemAdded) return;

    lastItemRef?.current?.scrollIntoView({ behavior: "smooth" });
    setIsItemAdded(false);
  }, [items?.length]);

  useEffect(() => {
    if (iconsSummary && !iconsDetails) setValue("general.icons_to_show", "Summary");
    if (iconsDetails && !iconsSummary) setValue("general.icons_to_show", "Details");
    if (iconsSummary === undefined && iconsDetails === undefined)
      setValue("general.icons_to_show", STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE);
    if (iconsSummary && iconsDetails) setValue("general.icons_to_show", "Summary + Details");
  }, [iconsDetails, iconsSummary]);

  // used this solution because couldn’t figure out how to determine when the replace method was complete after changing the day
  useEffect(() => {
    if (!changedDayItemId) return;

    setTimeout(() => {
      const changedDayItem = document.querySelector(`#item-${changedDayItemId}`);
      changedDayItem?.scrollIntoView({ behavior: "smooth", block: "center" });
      setChangedDayItemId(null);
    }, 0);
  }, [changedDayItemId]);

  const sortedItems = useMemo(() => {
    return watch(name).sort((a, b) => a.position - b.position);
  }, [watch(name)]);

  return (
    <Wrapper slimTopPadding>
      <div style={{ height: 20 }} />

      {showReorderModal && (
        <ReorderModal
          control={control}
          items={items}
          onConfirm={onToggleReorderModal}
          onClose={onToggleReorderModal}
          replace={replace}
          watch={watch}
          name={name}
          getValues={getValues}
          update={update}
        />
      )}

      <ItineraryTitle style={{ paddingLeft: 0 }}>Homescreen icons to show</ItineraryTitle>
      <SelectInput
        options={options}
        isClearable={false}
        defaultValue={options[0].value}
        // value={getIconsValue()}
        disabled={!canEdit || isWiped}
        control={control}
        name="general.icons_to_show"
      />

      <div style={{ height: 40 }} />

      <TitleRow text="Storyboard" isSticky={!isModalOpen} noFade disabled={!canEdit}>
        <ActionButtonsGrid>
          <OutlinedButton
            iconOnlyMobile
            startIcon={<IconReorder />}
            disabled={!items?.length}
            onClick={onToggleReorderModal}
            tooltip="Reorder"
            type="grey"
          />
          <OutlinedButton
            iconOnlyMobile
            startIcon={showDays ? <EventAvailableOutlined /> : <EventBusyOutlined />}
            onClick={toggleShowDay}
            type="grey"
            tooltip={showDays ? "Hide dates" : "Show dates"}
          />
          <AttachButton
            startIcon={<DriveFileMoveOutlined />}
            allowedFileTypes={[StoryboardFile.FILE_TYPE]}
            type="grey"
            types={["library", "stays", "flights"]}
            onConfirm={onAddFromLibrary}
            onStaySelect={onStaySelect}
            handleOpen={onToggleModal}
            tooltip="Add to storyboard"
            mobileFullScreen
            iconOnlyMobile
            hideDescription
            hideUrl
            flightProps={flightProps}
            multiUpload
          />
          <OutlinedButton startIcon={<AddIcon />} iconOnlyMobile onClick={() => onAdd()} text="Add" />
        </ActionButtonsGrid>
      </TitleRow>

      <div style={{ height: 20 }} />

      {items?.length ? (
        sortedItems.map((item, index) => (
          <>
            <StoryboardItem
              control={control}
              watchName={name}
              item={item}
              index={index}
              itemId={item.id}
              showDays={showDays}
              setValue={setValue}
              ref={isLastItem(items, index) && isItemAdded ? lastItemRef : null}
              key={item.item_id}
              locations={locations}
              remove={remove}
              watch={watch}
              disabled={!canEdit}
              replace={replace}
              getValues={getValues}
              isDirty={isDirty}
              changedDayItemId={changedDayItemId}
              setChangedDayItemId={setChangedDayItemId}
              trigger={trigger}
              storyboardErrors={errors.storyboard}
              errors={errors}
              clearErrors={clearErrors}
            />
          </>
        ))
      ) : (
        <NoResultsMessage style={{ height: "auto" }}>{`Please click "ADD" to start adding storyboard days`}</NoResultsMessage>
      )}
    </Wrapper>
  );
};

export default Storyboard;
