import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import BaseModal from "components/_new/ModalBase";
import Table from "components/_new/Table";
import { Message, NoResultsMessage } from "components/ui/Messages";
import { CREATION_NOTIFICATIONS } from "constants/content";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { TableWrap } from "feature/panel/Itinerary/helpers";
import { Wrapper } from "feature/panel/Itinerary/style";
import { useTableHandlers } from "hooks/useTableHandlers";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setNotification } from "store/app/actions";
import uuidv4 from "uuid";
import { handleDownload } from "../../../../_shared/CreationsAndPublishers/handlers";
import MobileItem from "./MobileItem";
import CreationModal from "./creationModal";
import { columns, prepareDataToUse } from "./helpers";
import SaveModal from "./saveModal";
import PublisherModal from "./publisherModal";

const Creations = ({
  form: {
    watch,
    setValue,
    formState: { isDirty },
    handleSubmit,
  },
  onSubmit,
  canEdit,
  isWiped,
  isPublisher,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { reference_code, operator_code } = useParams();
  const [createModal, setCreateModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedCreation, setSelectedCreation] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const field1 = watch("general.field1");
  const field3 = watch("general.field3");
  const documents = watch("documents.documents");
  const directories = watch("directories.directory");
  const location = watch("locations.locations[0]");
  const tableHandles = useTableHandlers();

  const { data: { data: { items, total_matches = 0 } } = { data: {} } } = useQuery({
    queryKey: ["creations", tableHandles],
    queryFn: ({ queryKey }) =>
      HttpClient.get(`/creation/${operator_code}/${reference_code}?count=${queryKey[1].rowsPerPage}&page=${queryKey[1].page}`),
    refetchOnMount: true,
  });
  const { mutate: deleteCreation, isLoading } = useMutation(
    () => {
      return HttpClient.delete(`/creation/${operator_code}/${reference_code}/${selectedCreation.id}`);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["creations"] });
        setSelectedCreation(null);
        setDeleteModal(false);
        dispatch(
          setNotification({
            type: "success",
            message: "Creation has been successfully deleted",
          }),
        );
      },
    },
  );

  const { mutate } = useMutation(
    body => {
      return HttpClient.post(`/creation/${operator_code}/${reference_code}`, body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["creations"] });
        setCreateModal(false);
      },
      onError: () => {
        dispatch(
          setNotification({ type: "error", message: `Error occurred while creating ${isPublisher ? "Publisher document" : "Creation"}` }),
        );
      },
    },
  );

  const toggleMenu = ({ currentTarget }, data) => setOpenMenu(openMenu ? null : { target: currentTarget, data });
  const toggleCreateModal = () => {
    setCreateModal(!createModal);
    setIsEditing(false);
  };

  const toggleSaveModal = () => setShowSaveModal(!showSaveModal);

  const onSaveConfirm = () => handleSubmit(onSubmit);

  const onEdit = data => {
    setIsEditing(true);
    setCreateModal(true);
    setSelectedCreation(prepareDataToUse(data));
  };

  const onRefresh = ({ id, name, settings: { show_dates, sections } }) => {
    if (isDirty) {
      toggleSaveModal();
    } else {
      mutate({ id, name, show_dates, sections });
    }
  };

  const onAddToDocuments = (item, documentType) => {
    const { id } = item.outputs.web.file;

    setValue(
      `documents.documents.${documentType}`,
      [
        ...documents[documentType],
        {
          id: uuidv4(),
          name: item.name,
          file_id: id,
          file: item.outputs.web.file,
        },
      ],
      { shouldDirty: true },
    );

    setOpenMenu(null);
    dispatch(
      setNotification({
        type: "success",
        message: CREATION_NOTIFICATIONS[documentType](item.name),
      }),
    );
  };

  const toggleDeleteModal = data => {
    setSelectedCreation(data);
    setDeleteModal(!deleteModal);
  };

  const actions = {
    handleMenuOpen: toggleMenu,
    handleMenuClose: toggleMenu,
    openMenu,
    onEdit,
    onRefresh,
    handleDownload,
    onAddToDocuments,
    toggleDeleteModal,
  };

  useEffect(() => {
    if (!createModal) setSelectedCreation(null);
  }, [createModal]);

  return (
    <Wrapper slimTopPadding>
      {showSaveModal && <SaveModal onCancel={toggleSaveModal} onConfirm={onSaveConfirm} />}
      {deleteModal && (
        <BaseModal
          title={`Are you sure you want to delete document ${selectedCreation?.name}?`}
          onConfirm={deleteCreation}
          onCancel={toggleDeleteModal}
          isLoading={isLoading}
        />
      )}
      {createModal &&
        (isPublisher ? (
          <PublisherModal
            onClose={toggleCreateModal}
            field1={field1}
            field3={field3}
            data={selectedCreation}
            isDirty={isDirty}
            onSave={handleSubmit(onSubmit)}
            isEditing={isEditing}
            directories={directories}
            watch={watch}
            setValue={setValue}
            location={location}
          />
        ) : (
          <CreationModal
            onClose={toggleCreateModal}
            field1={field1}
            field3={field3}
            data={selectedCreation}
            isDirty={isDirty}
            onSave={handleSubmit(onSubmit)}
            isEditing={isEditing}
          />
        ))}

      <TitleRow text={`${isPublisher ? "Publisher documents" : "Creations"}`} isSticky disabled={!canEdit || isWiped}>
        <OutlinedButton text={isPublisher ? "Add" : "Add"} startIcon={<AddIcon />} onClick={toggleCreateModal} disabled={!reference_code} />
      </TitleRow>

      <div style={{ height: 20 }} />
      {reference_code ? (
        items?.length ? (
          <TableWrap>
            <Table
              columns={columns({ ...actions, disabled: !canEdit || isWiped })}
              rows={items}
              totalMatches={total_matches}
              renderRowMobile={<MobileItem {...actions} disabled={!canEdit || isWiped} />}
              {...tableHandles}
            />
          </TableWrap>
        ) : (
          <NoResultsMessage style={{ height: 50 }}>
            {`Please click "ADD" to start adding ${isPublisher ? "documents" : "creations"}`}
          </NoResultsMessage>
        )
      ) : (
        <Message type="warning" text={`Creations section is only available for already existing ${isPublisher ? "stays" : "trips"}`} />
      )}
    </Wrapper>
  );
};

export default Creations;
