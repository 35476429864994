import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.disabled || p.hideActions ? "5fr 1fr" : "1fr  minmax(240px, auto)")};
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    align-items: flex-start;
    grid-template-columns: ${p => (p.type === "stay" ? "1fr" : "1fr")};
    /* padding-right:15px; */
    padding-left:0;
    /* padding: ${({ hasFile }) => (hasFile ? "15px 0" : "0")}; */
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  svg {
    color: #bdbdbd;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: ${colors.redLight};
    width: 32px;
    height: 32px;
    border-radius: 100%;
    svg {
      color: #eb5757;
    }
  }
`;

export const FileTitleWrap = styled.div`
  display: flex;
  align-items: ${p => (p.hasError ? "flex-start" : "center")};

  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${p => p.type === "trip" && "15px 15px 0 15px"};
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: minmax(80px, auto) 80px 80px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: minmax(80px, auto) 80px;
    gap: ${p => (p.hasFile ? 15 : 0)}px;
    button {
      width: 100% !important;

      border-top: 1px solid ${p => (p.type === "trip" ? colors.grey10 : colors.brand)} !important;
    }
  }
`;
