import Table from "components/_new/Table";
import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import React, { useRef, useState } from "react";
import MobileRow from "./MobileItem";
import columns from "./columns";
import { CustomTableRow } from "./customRow";

const LocationPois = ({
  allPois = [],
  overridenPois,
  setValue,
  name,
  totalMatches,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  disabled,
}) => {
  const [clickedRow, setClickedRow] = useState(null);
  const ref = useRef();

  const onChange = row => {
    if (overridenPois.find(item => item.id === row.id)) {
      setValue(
        name,
        overridenPois.filter(item => row.id !== item.id),
        { shouldDirty: true },
      );
    } else {
      setValue(name, [...overridenPois, { ...row, is_on: row.hasOwnProperty("is_on") ? Boolean(!row.is_on) : !row.is_default_on }], { shouldDirty: true });
    }
  };

  const onRowClick = row => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    setClickedRow(row.id);
  };

  useEffectDependenciesOnly(() => {
    setTimeout(() => {
      setClickedRow(null);
    }, 2000);
  }, [clickedRow]);

  return (
    <div>
      {allPois.length ? (
        <>
          <TitleRow ref={ref} offsetTabs isSticky text="Trip specific settings" />

          <div style={{ height: 20 }} />

          {overridenPois?.length ? (
            <Table
              rows={overridenPois}
              columns={columns({ onChange, disabled })}
              renderRow={<CustomTableRow columns={columns({ onChange, isOverriden: true })} clickedRow={clickedRow} />}
              renderRowMobile={<MobileRow onClick={onRowClick} onChange={onChange} clickedRow={clickedRow} />}
            />
          ) : (
            <NoResultsMessage style={{ height: "auto" }}>You have not overridden any POIs</NoResultsMessage>
          )}
        </>
      ) : null}

      {allPois.length ? (
        <>
          <TitleRow offsetTabs isSticky text="Shared POI settings" />

          <div style={{ height: 20 }} />

          <Table
            rows={allPois.map(item => ({ ...item, isOverriden: Boolean(overridenPois.find(newItem => newItem.id === item.id)) }))}
            columns={columns({ onChange, disabled })}
            renderRow={<CustomTableRow onClick={onRowClick} columns={columns({ onChange })} />}
            renderRowMobile={<MobileRow onClick={onRowClick} onChange={onChange} />}
            totalMatches={totalMatches}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          <div style={{ height: 20 }} />
          <NoResultsMessage style={{ height: "auto" }}>You have no relevant POIs</NoResultsMessage>
        </>
      )}
    </div>
  );
};

export default LocationPois;
