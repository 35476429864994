import { useState } from "react";
import uuidv4 from "uuid";
import config from "config/app";
import moment from "moment";
import momentTimezone from "moment-timezone";

import { deepClone } from "utils/array";
import { generateUniqueId } from "utils";
import { FLIGHT_LABELS } from "constants/content";
import { FLIGHTS_LEGS_DETAILS } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { getFlightsMapUrl, setUrl } from "utils/url";

import { getFlightEntryDayNumber, setDayNumber, setPositionNumber } from "../days";
import { saveExternalImageToS3 } from "../../helpers";

export const useEntries = ({
  updateEntriesList,
  updateLocations,
  updateNotifications,
  updateFlights,
  entries,
  showDates,
  flights,
  locations,
  notifications,
  setDatesVisibility,
  startDate,
}) => {
  const [errors, setErrors] = useState([]);
  const [isNewEntryAdded, setIsNewEntryAdded] = useState(false);

  const addEntry = () => {
    const oldEntries = entries.map(entry => ({ ...entry, isNew: false }));
    const newEntry = {
      id: uuidv4(),
      restricted_to_traveller_internal_ids: null,
      headline: "",
      documents: [],
      content: "",
      isNew: true,
      position: setPositionNumber(oldEntries),
      meta: {
        day_number: setDayNumber(oldEntries),
      },
    };
    setIsNewEntryAdded(true);
    updateEntriesList([...oldEntries, newEntry]);
  };

  const addEntryFromFile = uploadedEntry => {
    const oldEntries = entries.map(entry => ({ ...entry, isNew: false }));
    const newEntry = {
      ...uploadedEntry,
      restricted_to_traveller_internal_ids: null,
      isNew: true,
      position: setPositionNumber(oldEntries),
      meta: {
        day_number: setDayNumber(oldEntries),
      },
    };
    setIsNewEntryAdded(true);
    updateEntriesList([...oldEntries, newEntry]);
  };

  const prepareLocationFromStay = currentStay => {
    return {
      country: "",
      country_iso: currentStay.countryIso,
      name: currentStay.name,
      location: "",
      coordinates: `${currentStay.latitude}, ${currentStay.longitude}`,
      latitude: currentStay.latitude,
      longitude: currentStay.longitude,
      on_weather: true,
      on_maps: true,
      isNewNestedLocation: true,
      nested: {
        vamoos_id: currentStay.vamoos_id || currentStay.vamoosId,
        type: "stay",
        operator_code: currentStay.operator_code || currentStay.operatorCode,
        reference_code: currentStay.reference_code || currentStay.referenceCode,
      },
    };
  };

  const prepareStoryboardStayEntry = (currentStay, location = null) => {
    let content = "";
    if (currentStay.shortDescription) {
      content += currentStay.shortDescription;
    }
    if (currentStay.longDescription) {
      content += `\n${currentStay.longDescription}`;
    }

    const entry = {
      id: uuidv4(),
      documents: [],
      headline: FLIGHT_LABELS.entry.flightHeadline(currentStay.name),
      content,
      position: setPositionNumber(entries),
      meta: {
        day_number: setDayNumber(entries),
        type: "stay",
      },
    };

    if (currentStay?.background) {
      entry.image = currentStay.background;
    }

    if (location) {
      entry.location = location;
    }

    return entry;
  };

  const addStayEntry = overridedStay => {
    const oldEntries = entries.map(entry => ({ ...entry, isNew: false }));
    const id = generateUniqueId();
    const location = {
      ...prepareLocationFromStay(overridedStay),
      internal_id: id,
      id,
      isNewLocation: true,
    };
    const newEntry = {
      ...prepareStoryboardStayEntry(overridedStay, location),
      isNew: true,
    };
    setIsNewEntryAdded(true);
    updateLocations([...locations, location]);
    updateEntriesList([...oldEntries, newEntry]);
  };

  const handleAddFlightToStoryboard = async flight => {
    const uploadedFile = await saveExternalImageToS3(getFlightsMapUrl(flight.departure_airport.fs_code, flight.arrival_airport.fs_code));
    const departureTime = momentTimezone(flight?.live_departure_at_utc ?? flight?.departure_at_utc)
      .tz(flight.departure_airport.timezone)
      .format(config.dateTimeFormat);
    const arrivalTime = momentTimezone(flight?.live_arrival_at_utc ?? flight?.arrival_at_utc)
      .tz(flight.arrival_airport.timezone)
      .format(config.dateTimeFormat);
    const currentEntry = {
      id: uuidv4(),
      restricted_to_traveller_internal_ids: null,
      documents: [],
      headline: FLIGHT_LABELS.entry.headline(flight?.departure_airport?.city, flight?.arrival_airport?.city),
      content: FLIGHT_LABELS.entry.content(
        flight?.departure_airport?.name,
        flight?.departure_terminal,
        departureTime,
        flight?.arrival_airport?.name,
        arrivalTime,
      ),
      meta: {
        flight_id: flight.id,
        departure_date: flight?.departure_date_local ?? flight?.live_depature_date_local ?? flight?.live_departure_at_utc ?? flight?.departure_at_utc,
        arrival_date: flight?.live_arrival_at_utc ?? flight?.arrival_at_utc,
      },
    };
    if (uploadedFile) {
      currentEntry.image = {
        ...uploadedFile,
        preview_url: getFlightsMapUrl(flight.departure_airport.fs_code, flight.arrival_airport.fs_code),
      };
    }

    return currentEntry;
  };

  const addFlightEntry = async selectedFlight => {
    const { fs, number, date, selectedFlights } = selectedFlight;
    const updatedFlightsList = ([...flights]);
    const entriesPromisses = selectedFlights.map(async flightItem => {
      const flightUrl = setUrl(FLIGHTS_LEGS_DETAILS, {
        fs,
        number,
        date,
        from: flightItem.departure_airport.fs_code || flightItem.departure_airport,
        to: flightItem.arrival_airport.fs_code || flightItem.arrival_airport,
      });
      const { data } = await HttpClient.get(flightUrl);
      const [flightDetails] = data;
      flightDetails.restricted_to_traveller_internal_ids = null; // add initial property restricted_to_traveller_internal_ids to the flight
      const isFlightAlreadyExists = flights.some(flight => flight.id === flightDetails.id);

      if (!isFlightAlreadyExists) {
        updatedFlightsList.push(flightDetails);
      }

      return handleAddFlightToStoryboard(flightDetails);
    });

    await Promise.all(entriesPromisses).then(newEntries => {
      if (newEntries.length) {
        const newEntriesList = entries.length ? entries.map(entry => ({ ...entry, isNew: false })) : [];
        newEntries
          .map(entry => ({ ...entry, isNew: false }))
          .forEach((newEntryItem, index) => {
            const dayNumber = getFlightEntryDayNumber(newEntryItem?.meta?.departure_date, startDate);
            const newEntry = {
              ...newEntryItem,
              position: setPositionNumber(newEntriesList),
              isNew: newEntries.length - 1 === index,
              meta: {
                ...newEntryItem.meta,
                day_number: dayNumber > 0 ? dayNumber + 1 : 1,
              },
            };

            newEntriesList.push(newEntry);
          });

        const sortedEntries = newEntriesList
          .sort((a, b) => a.meta.day_number - b.meta.day_number)
          .map((entry, index) => ({ ...entry, position: index + 1, meta: { ...entry.meta } }));

        updateEntriesList([...sortedEntries]);
        updateFlights(updatedFlightsList);
        setIsNewEntryAdded(true);
      }
    });
  };

  const updateEntry = item => {
    const newEntriesList = deepClone(entries)
      .map(entry => {
        if (entry.id === item.id) {
          if (item.image === null) {
            const { image, ...rest } = item;
            return { ...rest };
          }
          return {
            ...item,
            changed: true,
            meta: {
              ...item.meta,
              hide_day_info: item.headline?.charAt(0) === "#" ? true : false,
            },
          };
        }
        const hashAdded = entry.headline?.charAt(0) === "#";
        return {
          ...entry,
          headline: entry.meta.hide_day_info && !hashAdded && !entry.changed ? "#" + entry.headline : entry.headline,
          hashAdded: entry.meta.hide_day_info || entry.headline?.charAt(0) === "#",
        };
      })
      .sort((a, b) => {
        if (showDates) {
          if (a.meta.day_number === null || b.meta.day_number === null) {
            return 0;
          }
          return Number(a.meta.day_number) - Number(b.meta.day_number);
        }
        if (a.position === null || b.position === null) {
          return 0;
        }
        return Number(a.position) - Number(b.position);
      })
      .map((entry, index) => {
        return { ...entry, position: index + 1 };
      });

    updateEntriesList(newEntriesList);
  };

  const changeDayNumber = (item, { target }) => {
    const resolvePosition = value => {
      if (!value) return null;
      return value > 0 ? value : 1;
    };
    const { value } = target;
    const dayNumber = Math.floor(Number(value));

    if (value.length < 5) {
      const newItem = {
        ...item,
        meta: {
          ...item.meta,
          day_number: resolvePosition(dayNumber),
        },
      };
      updateEntry(newItem);
    }
  };

  const handleRemovedLocationInNotifications = id => {
    return notifications.map(notification => {
      if (notification?.location?.id === id || notification.location_internal_id === id) {
        return {
          ...notification,
          location: undefined,
          location_internal_id: undefined,
        };
      }
      return notification;
    });
  };

  const removeFromLocations = entry => {
    const newNotifications = handleRemovedLocationInNotifications(entry.location.id);

    updateLocations([...locations.filter(location => location.id !== entry.location.id)]);
    updateNotifications([...newNotifications]);
  };

  const removeFlightEntryFromStoryboard = entry => {
    const flightIndex = flights.findIndex(flight => flight.id === entry.meta.flight_id);
    const newFlightList = flights.filter((flight, index) => !(flight.id === entry.meta.flight_id && index === flightIndex));

    updateFlights([...newFlightList]);
  };

  const removeEntry = (id, cascade = false) => {
    const entryToRemove = entries.find(entry => entry.id === id);
    const newEntriesList = entries.filter(entry => entry.id !== id);

    if (cascade && entryToRemove.meta.type === "stay") {
      removeFromLocations(entryToRemove);
    }

    if (cascade && entryToRemove?.meta?.flight_id) {
      removeFlightEntryFromStoryboard(entryToRemove);
    }

    updateEntriesList(newEntriesList);
  };

  const toggleDayNumbers = () => {
    if (!showDates) {
      updateEntriesList(
        entries.map((entry, index) => {
          return {
            ...entry,
            meta: {
              ...entry.meta,
              day_number: entry.meta.day_number || index + 1,
            },
          };
        }),
      );
    }
    setDatesVisibility(!showDates);
  };

  const getIfErrorExist = index => {
    return errors.find(error => error.index === index);
  };

  const handleErrors = (index, message) => {
    if (message) {
      const newError = getIfErrorExist(index) || { index, message };
      setErrors(prevErrors => {
        let errorArray = prevErrors;
        if (prevErrors.filter(e => e.index === index).length === 0) {
          errorArray = [...prevErrors, newError];
        }
        return errorArray;
      });
    } else {
      setErrors(errors.filter(error => error.index !== index));
    }
  };

  const clearNewEntryAdded = () => {
    setIsNewEntryAdded(false);
  };

  return {
    addEntry,
    addEntryFromFile,
    addStayEntry,
    addFlightEntry,
    updateEntry,
    changeDayNumber,
    errors,
    handleErrors,
    getIfErrorExist,
    removeEntry,
    toggleDayNumbers,
    isNewEntryAdded,
    clearNewEntryAdded,
  };
};
