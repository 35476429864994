import React from "react";
import { colors } from "config/theme/colors";
import { generateSenderTypeString } from "utils/generateSenderTypeString";
import moment from "moment";
import appConfig from "config/app";
import { Content, ContentWrap, Wrap } from "./style";

const MessageItem = ({ isMy, message, type, name, data, sent_at }) => {
  return (
    <Wrap isMy={isMy}>
      <ContentWrap>
        <span style={{ fontSize: 12, color: colors.grey30 }}>
          {data.sender_name || generateSenderTypeString(type, data.sender_name)} • {moment(sent_at).format(appConfig.dateTimeFormat)}
        </span>
        <Content isMy={isMy}>{message}</Content>
      </ContentWrap>
    </Wrap>
  );
};

export default MessageItem;
