import { takeEvery, put, select } from "redux-saga/effects";
import * as actionTypes from "store/app/actionTypes";

import {
  changeOperatorSuccess,
  changeOperatorFail,
  getApiKeysSuccess,
  getApiKeysFail,
  deleteApiKeySuccess,
  deleteApiKeyFail,
  setNotification,
  deleteUserFromOperatorFail,
  deleteUserFromOperatorSuccess,
} from "store/app/actions";
import { setCurrentOperatorCode, getCurrentUserStart } from "store/auth/actions";

import { API_KEYS_URL } from "constants/api";

import notifications from "constants/notifications";

import { StorageService } from "services/StorageService";
import { HttpClient } from "services/application/httpClient/httpClient";
import { TabSessionService } from "services/TabSessionService";
import { OperatorRepository } from "repositories/OperatorRepository";
import { OperatorMapper } from "mappers/OperatorMapper";
import { OperatorService } from "services/OperatorService";
import { queryClient } from "components/App";

function* getKeys() {
  try {
    const response = yield HttpClient.get(API_KEYS_URL);
    const { items, total_matches } = response.data;
    yield put(getApiKeysSuccess({ items, count: total_matches }));
  } catch (e) {
    yield put(getApiKeysFail(e));
  }
}

function* createKey() {
  try {
    const result = yield HttpClient.post(API_KEYS_URL);
    const { apiKeys, apiKeysCount } = yield select(state => state.app);
    const newList = [...apiKeys, result.data];
    yield put(getApiKeysSuccess({ items: newList, apiKeysCount: apiKeysCount + 1 }));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("apiKey").create.success,
      }),
    );
  } catch (e) {
    yield put(getApiKeysFail(e));
    yield put(
      setNotification({
        type: "error",
        message: notifications.resource("apiKey").create.fail,
      }),
    );
  }
}

function* deleteKey({ payload }) {
  const { id } = payload;
  try {
    yield HttpClient.delete(`${API_KEYS_URL}/${id}`);
    const { apiKeys, apiKeysCount } = yield select(state => state.app);
    const newList = apiKeys.filter(key => key.id !== id);
    yield put(deleteApiKeySuccess({ items: newList, apiKeysCount: apiKeysCount - 1 }));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("apiKey").delete.success,
      }),
    );
  } catch (e) {
    yield put(deleteApiKeyFail(e));
    yield put(
      setNotification({
        type: "error",
        message: notifications.resource("apiKey").delete.fail,
      }),
    );
  }
}

function* changeOperator({ payload }) {
  const tabSessionStorage = new TabSessionService(new StorageService());
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());

  const { code, isSelectedOperatorActive } = payload;

  try {
    queryClient.invalidateQueries({queryKey: ['/operator']});
    const response = yield operatorService.getCurrentOperator(code);

    if (response) {
      queryClient.clear();
      yield tabSessionStorage.setLocalOperatorCode(code);

      if (!isSelectedOperatorActive) {
        yield put(getCurrentUserStart());
      }
      yield put(changeOperatorSuccess());
      yield put(setCurrentOperatorCode(code));

      window.location.reload();
    }
  } catch (error) {
    if (error?.response?.status === 402 || error?.response?.status === 403) {
      yield tabSessionStorage.setLocalOperatorCode(code);
      yield put(setCurrentOperatorCode(code));
    }
    yield put(changeOperatorFail(error));
    window.location.reload();
  }
}

function* deleteUserFromOperator({ payload }) {
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());

  const { code, userId } = payload;

  try {
    const response = yield operatorService.suspendUser({
      userId,
      code,
    });

    if (response) {
      yield put(getCurrentUserStart());
      yield put(deleteUserFromOperatorSuccess());

      yield put(
        setNotification({
          type: "success",
          message: notifications.resource("operator").delete.success,
        }),
      );
    }
  } catch (e) {
    yield put(deleteUserFromOperatorFail(e));
  }
}

export function* appSaga() {
  yield takeEvery(actionTypes.GET_API_KEYS_START, getKeys);
  yield takeEvery(actionTypes.CREATE_API_KEY_START, createKey);
  yield takeEvery(actionTypes.DELETE_API_KEY_START, deleteKey);
  yield takeEvery(actionTypes.CHANGE_OPERATOR_START, changeOperator);
  yield takeEvery(actionTypes.DELETE_USER_FROM_OPERATOR_START, deleteUserFromOperator);
}
